import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { IonInfiniteScroll, ModalController } from '@ionic/angular';

import { ApiService } from '@services/api.service';

import { IAsset, IScannedAsset } from '@models/types';

@Component({
    selector: 'app-asset-select-modal',
    templateUrl: './asset-select-modal.page.html',
    styleUrls: ['./asset-select-modal.page.scss'],
})
export class AssetSelectModalPage implements OnInit {

    @ViewChild(IonInfiniteScroll, { static: false }) infiniteScroll: IonInfiniteScroll;

    private assetFormConfigId: string = '';
    public isDataLoaded = false;
    public isSearching = false;
    public assetsFormGroup = new FormGroup({});

    public selectedAssets: IScannedAsset[] = [];
    public assets: (IAsset & { selected: boolean })[];

    public searchQuery: string;
    public multi: boolean;
    private currentlySelectedAssets: string[];

    private itemsPerPage = 10;
    private page = 0;

    constructor(
        private apiService: ApiService,
        private modalController: ModalController
    ) { }

    async ngOnInit() {
        await this.getAssets();
        this.isDataLoaded = true;
        setTimeout(() => this.shouldLoadMoreData());
    }

    public selectAsset() {
        for (const asset of this.assets) {
            const selectedAssetIndex = this.selectedAssets.findIndex(p => p.asset_id === asset.id);
            if (this.assetsFormGroup.value[asset.id] === true) {
                if (selectedAssetIndex === -1) {
                    const assetIdentifier = asset.sections[0].questions[0];
                    const scannedAsset: IScannedAsset = {
                        asset_id: asset.id,
                        asset_identifier: assetIdentifier.answer,
                        label: assetIdentifier.label,
                        value: assetIdentifier.label + ': ' + assetIdentifier.answer,
                        sections: [{
                            questions: asset.sections[0].questions.filter(question => question.showInList && question.answer)
                        }]
                    };
                    this.selectedAssets.push(scannedAsset);
                }
            } else if (this.assetsFormGroup.value[asset.id] === false) {
                if (this.selectedAssets.length > 0) {
                    if (selectedAssetIndex > -1) {
                        this.selectedAssets.splice(selectedAssetIndex, 1);
                    }
                }
            }
        }

        if (!this.multi) {
            this.assetsFormGroup.enable();
            if (this.selectedAssets.length === 1) {
                this.assetsFormGroup.disable();
                for (const asset of this.assets) {
                    const formControl = this.assetsFormGroup.controls[asset.id] as FormControl;
                    if (formControl.value === true) {
                        formControl.enable();
                        break;
                    }
                }
            }
        }
    }

    public addAssets() {
        this.modalController.dismiss(this.selectedAssets);
    }

    private async getAssets(emptyArray?: boolean) {
        try {
            const offset = this.page * this.itemsPerPage;
            const data = await this.apiService.getAssetsByAssetType(this.assetFormConfigId, 'active', this.itemsPerPage, offset, this.searchQuery) as (IAsset & { selected: boolean })[];
            const assets = this.updateSelectedAssets(data);
            if (!this.assets || emptyArray) {
                this.assets = [];
            }
            this.initialiseAssetsFormGroup(assets);
            this.assets.push(...assets);
            this.isDataLoaded = true;
        } catch (error) {
            console.log('AssetSelectModalPage: getAssets(): error=', error);
            this.assets = [];
        }
    }

    private initialiseAssetsFormGroup(assets: (IAsset & { selected: boolean })[]) {
        const isControlDisabled = (!this.multi && this.selectedAssets.length === 1) ? true : false;
        for (const asset of assets) {
            this.assetsFormGroup.addControl(asset.id, new FormControl(isControlDisabled ? { value: false, disabled: true } : asset.selected ? { value: true, disabled: true } : false));
        }
    }

    private updateSelectedAssets(assets: (IAsset & { selected: boolean })[]) {
        for (const asset of assets) {
            if (this.currentlySelectedAssets.length > 0) {
                const currentlySelectedAsset = this.currentlySelectedAssets.find((id) => id === asset.id);
                if (currentlySelectedAsset) {
                    asset.selected = true;
                }
            }
        }
        return assets;
    }

    private shouldLoadMoreData() {
        if (this.infiniteScroll) {
            const max = (this.page + 1) * this.itemsPerPage;
            if (this.assets.length < max) {
                this.infiniteScroll.disabled = true;
            }
        }
    }

    public searchbarChange(e: HTMLIonSearchbarElement) {
        setTimeout(async () => {
            this.isSearching = true;
            const query: string = e.value;
            this.searchQuery = query.trim();
            e.blur();
            this.page = 0;
            await this.getAssets(true);
            if (this.infiniteScroll?.disabled === true) {
                this.infiniteScroll.disabled = false;
            }
            setTimeout(() => this.shouldLoadMoreData());
            this.isSearching = false;
        });
    }

    public clearSearch(e: HTMLIonSearchbarElement) {
        setTimeout(async () => {
            this.isSearching = true;
            e.blur();
            this.searchQuery = null;
            this.page = 0;
            await this.getAssets(true);
            if (this.infiniteScroll.disabled === true) {
                this.infiniteScroll.disabled = false;
            }
            setTimeout(() => this.shouldLoadMoreData());
            this.isSearching = false;
        });
    }

    public async loadMoreData(event: any) {
        this.page++;
        await this.getAssets();
        event.target.complete();
        this.shouldLoadMoreData();
    }

    public async closeModal() {
        await this.modalController.dismiss();
    }
}
