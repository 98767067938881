import { Injectable } from '@angular/core';

import { environment } from '@environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ConfigService {

    public environment: string = environment.environment;
    public restApiUrl: string = environment.restApiUrl;
    public dashboardUrl: string = environment.dashboardUrl;
    public sessionId: string;
    public fallbackTemplateId = 'template::sample::v1';
    public fallbackFormConfigId = 'form_config::sample::v1';
    public fallbackSessionId = 'abcdef1234567890';

    constructor() { }

}
