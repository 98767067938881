import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-modal-routing-base',
    template: `<ion-nav [root]="rootPage" [rootParams]="data"></ion-nav>`
})
export class ModalRoutingBasePage implements OnInit {

    @Input() rootPage: any;
    @Input() data: any;

    constructor() { }

    ngOnInit() {
    }

}
