import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { FormlyModule } from '@ngx-formly/core';

import { AssetSelectModalPageModule } from './asset-select-modal/asset-select-modal.module';

import { TaAssetSelectComponent } from './ta-asset-select.type';
import { SingleAssetSelectComponent } from './single-asset-select/single-asset-select';

import { TaFormlyFieldWrapperModule } from '@ta-formly/ta-field-wrapper/ta-field-wrapper.module';
import { MultiAssetSelectComponent } from './multi-asset-select/multi-asset-select';

@NgModule({
    declarations: [
        TaAssetSelectComponent,
        SingleAssetSelectComponent,
        MultiAssetSelectComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        IonicModule,
        AssetSelectModalPageModule,
        TaFormlyFieldWrapperModule,
        FormlyModule.forChild({
            types: [
                {
                    name: 'ta-asset-select',
                    component: TaAssetSelectComponent,
                    wrappers: ['ta-field-wrapper']
                }
            ]
        })
    ],
    providers: [],
})
export class TaFormlyAssetSelectModule { }
