import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms/';

import { FieldType } from '@ngx-formly/core';

import { AuditService } from '@services/audit.service';
import { ConfigService } from '@services/config.service';

import { IAsset, IScannedAsset, IUserContact } from '@models/types';

import { get, set } from 'lodash-es';

import * as dayjs from 'dayjs';
import * as customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

const TAG = 'TaUserSelectComponent';

/**
 * Formly-compatible user select component
 */
@Component({
    selector: 'app-ta-user-select',
    templateUrl: 'ta-user-select.html',
    styleUrls: ['./ta-user-select.scss'],
})
export class TaUserSelectComponent extends FieldType implements OnInit {

    public users: (IScannedAsset & { contact: Partial<IUserContact> })[] = [];

    public dayjs = dayjs;
    public updatedKey: string;

    constructor(
        private auditService: AuditService,
        private changeDetectorRef: ChangeDetectorRef,
        private configService: ConfigService,
    ) {
        super();
    }

    ngOnInit() {
        const METHOD = 'ngOnInit';

        this.updatedKey = this.key as string;
        if (this.field.parent?.key && this.field.parent?.parent.key) {
            this.updatedKey = this.field.parent?.parent.key + '[' + this.field.parent?.key + '].' + this.key;
        }

        // Get existing users tag value if it exists
        const users = get(this.model, this.key, []);
        if (users) {
            this.users = users;
        }
    }

    public async saveUsers(assets: IScannedAsset[]) {
        set(this.model, this.key, assets);
        const formControl = this.formControl as FormControl;
        /* If a users array has been given, set the formControl value to the array
          else, set the formControl value to null  */

        if (assets) {
            formControl.setValue(assets);
            if (this.props.showInList) {
                const assetsValues = assets.map(asset => asset.value);
                const value = { key: this.updatedKey, label: this.props.label, answer: assetsValues, type: this.field.type as string };
                this.auditService.addShowInListValue(value);
            }

            this.auditService.setLocationAndTimestamp(this.updatedKey);
        } else {
            formControl.reset(null);

            if (this.props.showInList) {
                this.auditService.removeShowInListValue(this.updatedKey as string);
            }

            this.auditService.unsetLocationAndTimestamp(this.updatedKey);
        }

        this.changeDetectorRef.detectChanges();
    }
}
