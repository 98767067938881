import { NgModule } from '@angular/core';

import { TaFormlyAssetSelectModule } from './ta-asset-select/ta-asset-select.module';
import { TaFormlyAssetScanModule } from './ta-asset-scan/ta-asset-scan.module';
import { TaFormlyBarcodeModule } from './ta-barcode/ta-barcode.module';
import { TaFormlyCameraModule } from './ta-camera/ta-camera.module';
import { TaFormlyCheckboxModule } from './ta-checkbox/ta-checkbox.module';
import { TaFormlyDateModule } from './ta-date/ta-date.module';
import { TaFormlyDatetimepickerModule } from './ta-datetimepicker/ta-datetimepicker.module';
import { TaFormlyESignatureModule } from './ta-e-signature/ta-e-signature.module';
import { TaFormlyFieldWrapperModule } from './ta-field-wrapper/ta-field-wrapper.module';
import { TaFormlyFuelGaugeModule } from './ta-fuel-gauge/ta-fuel-gauge.module';
import { TaFormlyHiddenModule } from './ta-hidden/ta-hidden.module';
import { TaFormlyInputModule } from './ta-input/ta-input.module';
import { TaFormlyInstructionModule } from './ta-instruction/ta-instruction.module';
import { TaFormlyLinkModule } from './ta-link/ta-link.module';
import { TaFormlyOilLevelGaugeModule } from './ta-oil-level-gauge/ta-oil-level-gauge.module';
import { TaFormlyPassFailNaModule } from './ta-pass-fail-na/ta-pass-fail-na.module';
import { TaFormlyPassFailModule } from './ta-pass-fail/ta-pass-fail.module';
import { TaFormlyProjectSelectModule } from './ta-project-select/ta-project-select.module';
import { TaFormlyRadioModule } from './ta-radio/ta-radio.module';
import { TaFormlyRangeModule } from './ta-range/ta-range.module';
// import { TaFormlyRepeatQuestionModule } from './ta-repeat-question/ta-repeat-question.module';
import { TaFormlyRfidModule } from './ta-rfid/ta-rfid.module';
import { TaFormlySignatureModule } from './ta-signature/ta-signature.module';
import { TaFormlyStepperModule } from './ta-stepper/ta-stepper.module';
import { TaFormlyTextareaModule } from './ta-textarea/ta-textarea.module';
import { TaFormlyTimeModule } from './ta-time/ta-time.module';
import { TaFormlyUserSelectModule } from './ta-user-select/ta-user-select.module';
import { TaFormlyToggleModule } from './ta-toggle/ta-toggle.module';

@NgModule({
    imports: [
        TaFormlyAssetScanModule,
        TaFormlyBarcodeModule,
        TaFormlyCameraModule,
        TaFormlyCheckboxModule,
        TaFormlyDateModule,
        TaFormlyDatetimepickerModule,
        TaFormlyESignatureModule,
        TaFormlyFieldWrapperModule,
        TaFormlyFuelGaugeModule,
        TaFormlyHiddenModule,
        TaFormlyInstructionModule,
        TaFormlyOilLevelGaugeModule,
        TaFormlyInputModule,
        TaFormlyLinkModule,
        TaFormlyPassFailModule,
        TaFormlyPassFailNaModule,
        TaFormlyRadioModule,
        TaFormlyRangeModule,
        // TaFormlyRepeatQuestionModule,
        TaFormlyRfidModule,
        TaFormlySignatureModule,
        TaFormlyStepperModule,
        TaFormlyTextareaModule,
        TaFormlyTimeModule,
        TaFormlyAssetSelectModule,
        TaFormlyUserSelectModule,
        TaFormlyProjectSelectModule,
        TaFormlyToggleModule
    ],
    exports: [TaFormlyFieldWrapperModule],
})
export class TaFormlyModule { }
