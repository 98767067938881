import { ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms/';

import { AlertController, ToastController } from '@ionic/angular';

import { FieldType } from '@ngx-formly/core';

import { AuditService } from '@services/audit.service';

import { get, set } from 'lodash-es';

/**
 * Formly-compatible barcode component to allow scanning of barcodes
 */
@Component({
    selector: 'app-ta-barcode',
    templateUrl: 'ta-barcode.html',
    styleUrls: ['ta-barcode.scss']
})
export class TaBarcodeComponent extends FieldType implements OnInit {

    public barcode = '';
    private updatedKey: string;

    constructor(
        private alertController: AlertController,
        private auditService: AuditService,
        private changeDetectorRef: ChangeDetectorRef,
        private toastController: ToastController
    ) {
        super();
    }

    @HostListener('valueChanged')
    public valueChanged() {
        this.auditService.setLocationAndTimestamp(this.updatedKey);
    }

    ngOnInit() {
        this.updatedKey = this.key as string;
        if (this.field.parent?.key && this.field.parent?.parent.key) {
            this.updatedKey = this.field.parent?.parent.key + '[' + this.field.parent?.key + '].' + this.key;
        }

        const barcode = get(this.model, this.key, '');
        if (barcode) {
            this.barcode = barcode;
        }
    }

    public async scanBarcode() {
        const prompt = await this.alertController.create({
            header: 'Scan Barcode',
            inputs: [
                {
                    name: 'barcode',
                    placeholder: 'Enter barcode'
                },
            ],
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel'
                },
                {
                    text: 'Submit',
                    handler: async (data) => {
                        if (data && data.barcode) {
                            this.barcode = data.barcode;
                            this.saveBarcode(data.barcode);
                        } else {
                            const toast = await this.toastController.create({ header: 'Value cannot be empty', color: 'danger', position: 'top', duration: 2000, })
                            toast.present();
                            return false;
                        }
                    }
                }
            ],
            backdropDismiss: false
        });
        await prompt.present();
    }

    public async openDeleteModal() {
        const deleteBarcodeModal = await this.alertController.create({
            header: 'Delete Scanned Barcode',
            message: 'Do you want to delete this scanned barcode?',
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel'
                },
                {
                    text: 'Delete',
                    handler: () => {
                        this.deleteBarcode();
                    }
                }
            ],
            backdropDismiss: false
        });
        await deleteBarcodeModal.present();
    }

    public deleteBarcode() {
        this.barcode = '';
        this.saveBarcode();
    }

    private saveBarcode(barcode?: string) {
        set(this.model, this.key, this.barcode);
        const formControl = this.formControl as FormControl;
        /* If a barcode object has been given, set the formControl value to the barcode
          else, set the formControl value to null  */
        if (barcode) {
            formControl.setValue(this.barcode);
            this.auditService.setLocationAndTimestamp(this.updatedKey);
        } else {
            formControl.reset(null);
            this.auditService.unsetLocationAndTimestamp(this.updatedKey);
        }

        // TODO - Ionic bug
        //https://stackoverflow.com/questions/61237069/ionic-5-i-need-to-detect-changes-to-an-array-after-passing-from-a-child-page-t
        this.changeDetectorRef.detectChanges();
    }

}
