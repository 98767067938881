import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { FormlyModule } from '@ngx-formly/core';

import { TaFormlyAttributesDirective } from '../ta-formly.attributes';
import { QuestionAdditionalFieldsComponent } from './question-additional-fields/question-additional-fields';
import { QuestionGuidanceComponent } from './question-guidance/question-guidance';
import { TaFieldWrapperComponent } from './ta-field-wrapper';

@NgModule({
    declarations: [
        TaFieldWrapperComponent,
        TaFormlyAttributesDirective,
        QuestionGuidanceComponent,
        QuestionAdditionalFieldsComponent
    ],
    exports: [TaFormlyAttributesDirective],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        IonicModule,
        FormlyModule.forChild({
            wrappers: [
                {
                    name: 'ta-field-wrapper',
                    component: TaFieldWrapperComponent,
                }
            ]
        })
    ],
    providers: []

})
export class TaFormlyFieldWrapperModule { }
