<ion-header>
    <ion-toolbar>
        <ion-buttons slot="start" *ngIf="canGoBack || assetInformationModalService.pageType === 'page'">
            <ion-back-button></ion-back-button>
        </ion-buttons>
        <ion-title>{{assetInformationModalService.asset?.title}}</ion-title>
        <ion-label class="ion-margin-right-2" slot="end" *ngIf="assetInformationModalService.pageType === 'modal'"
            (click)="closeModal()">Close
        </ion-label>
    </ion-toolbar>
</ion-header>

<ion-content>

    <ion-item-group>
        <ng-container *ngIf="assetInformationModalService.asset">
            <ng-container
                *ngIf="assetInformationModalService.latestAssetFormConfigId && (assetInformationModalService.latestAssetFormConfigId !== assetInformationModalService.asset.asset_form_config_id)">
                <ion-item lines="full" color="danger">
                    <ion-icon class="ion-label-font-lg" slot="start" name="information-circle-outline">
                    </ion-icon>
                    <ion-label class="ion-text-wrap ion-label-font-sm ion-font-weight-medium">
                        Asset needs to be updated
                    </ion-label>
                </ion-item>
            </ng-container>

            <ion-list [class.ion-padding-bottom-4]="assetInformationModalService.pageType === 'modal'"
                class="allow-copy-text">
                <ng-container *ngFor="let section of assetInformationModalService.asset.sections">
                    <ng-container *ngFor="let question of section.questions; let i = index;">
                        <ng-container
                            *ngIf="i === 0 || ((i === 3 && !question.label.toLowerCase().startsWith('uhf')) || i > 3)">
                            <ng-container *ngIf="question.showInList">
                                <ion-item [lines]="(i + 1) === section.questions.length ? 'none' : 'inset'">
                                    <ion-label color="dark">
                                        <ion-label class="ion-label-font-md ion-margin-vertical-0 ion-text-wrap">
                                            <ion-icon class="question-label-icon ion-margin-right-1" slot="start"
                                                name="ellipse" [color]="question.answer ? 'secondary' : 'danger'">
                                            </ion-icon>
                                            {{question.label}}
                                        </ion-label>
                                        <ng-container *ngIf="i === 0">
                                            <ion-label class="ion-text-wrap ion-label-font-sm">
                                                {{question.answer}}
                                            </ion-label>
                                        </ng-container>
                                        <ng-container *ngIf="i !== 0 && question.answer">
                                            <ng-container *ngIf="question.type === 'ta-checkbox'; else showRadio">
                                                <ng-container *ngFor="let option of question.options">
                                                    <ng-container *ngIf="question.answer[option.key]">
                                                        <ion-label
                                                            class="ion-text-wrap ion-label-font-sm ion-margin-top-1">
                                                            <ion-icon name="checkbox-outline"></ion-icon>
                                                            {{ option.value }}
                                                        </ion-label>
                                                    </ng-container>
                                                </ng-container>
                                            </ng-container>
                                            <ng-template #showRadio>
                                                <ng-container *ngIf="question.type === 'ta-radio'; else showUrl">
                                                    <ng-container *ngFor="let option of question.options">
                                                        <ng-container *ngIf="question.answer === option.key">
                                                            <ion-label
                                                                class="ion-text-wrap ion-label-font-sm ion-margin-top-1">
                                                                {{ option.value }}
                                                            </ion-label>
                                                        </ng-container>
                                                    </ng-container>
                                                </ng-container>
                                            </ng-template>
                                            <ng-template #showUrl>
                                                <ng-container *ngIf="question.type === 'ta-uri'; else showDate">
                                                    <ion-button class="ion-label-font-xs" expand="block" slot="end"
                                                        (click)="openUri(question.answer)">View
                                                    </ion-button>
                                                </ng-container>
                                            </ng-template>
                                            <ng-template #showDate>
                                                <ng-container *ngIf="question.type === 'ta-date'; else showOthers">
                                                    <ion-label class="ion-text-wrap ion-label-font-sm">
                                                        {{question.answer |date:'dd/MM/yyyy'}}
                                                    </ion-label>
                                                </ng-container>
                                            </ng-template>
                                            <ng-template #showOthers>
                                                <ion-label class="ion-text-wrap ion-label-font-sm"
                                                    [color]="question.label.toLowerCase() === 'review date' && assetInformationModalService.asset.sections[0].questions[4].answer && dayjs(assetInformationModalService.asset.sections[0].questions[4].answer).isSameOrBefore(dayjs(),'day') ? 'danger' : 'dark'">
                                                    {{question.answer}}
                                                </ion-label>
                                            </ng-template>
                                        </ng-container>
                                    </ion-label>
                                    <ion-icon class="ion-label-font-lg ion-no-margin" slot="end" name="flag"
                                        color="danger"
                                        *ngIf="question.label.toLowerCase() === 'review date' && assetInformationModalService.asset.sections[0].questions[4].answer && dayjs(assetInformationModalService.asset.sections[0].questions[4].answer).isSameOrBefore(dayjs(),'day')">
                                    </ion-icon>
                                </ion-item>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </ion-list>
        </ng-container>
    </ion-item-group>

</ion-content>