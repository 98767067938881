<ion-card-content class="ion-padding-horizontal-2 ion-padding-vertical-0">
    <ion-grid *ngIf="thumbnails && thumbnails.length > 0">
        <ion-row>
            <ion-col class="thumbnail-wrapper" size="3" *ngFor="let thumbnail of thumbnails; let i = index;">
                <img [src]="thumbnail.base64" (click)="presentImageActionSheet(thumbnails,i)">
            </ion-col>
        </ion-row>
    </ion-grid>
    <ion-grid>
        <ion-row>
            <ion-col>
                <ion-button tabIndex="1" class="ion-label-font-sm" color="primary" expand="block"
                    (click)="takePicture()" [disabled]="maxPhotos > 0 && thumbnails.length >= maxPhotos">
                    Take Picture
                    <ion-icon slot="end" name="camera-outline"></ion-icon>
                </ion-button>
            </ion-col>
            <ion-col *ngIf="to.attachPhotos">
                <ion-button tabIndex="1" class="ion-label-font-sm" color="primary" expand="block"
                    (click)="attachPhotos()" [disabled]="maxPhotos > 0 && thumbnails.length >= maxPhotos">
                    Attach Photos
                    <ion-icon slot="end" name="images-outline"></ion-icon>
                </ion-button>
            </ion-col>
        </ion-row>
    </ion-grid>

</ion-card-content>