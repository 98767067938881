import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';


import { IonicModule } from '@ionic/angular';

import { FormlyModule } from '@ngx-formly/core';

import { ProjectSelectModalPageModule } from './project-select-modal/project-select-modal.module';

import { TaProjectSelectComponent } from './ta-project-select.type';
import { SingleProjectSelectComponent } from './single-project-select/single-project-select';

import { TaFormlyFieldWrapperModule } from '@ta-formly/ta-field-wrapper/ta-field-wrapper.module';
import { MultiProjectSelectComponent } from './multi-project-select/multi-project-select';

@NgModule({
    declarations: [
        TaProjectSelectComponent,
        SingleProjectSelectComponent,
        MultiProjectSelectComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        IonicModule,
        ProjectSelectModalPageModule,
        TaFormlyFieldWrapperModule,
        FormlyModule.forChild({
            types: [
                {
                    name: 'ta-project-select',
                    component: TaProjectSelectComponent,
                    wrappers: ['ta-field-wrapper']
                }
            ]
        })
    ],
    providers: [],
})
export class TaFormlyProjectSelectModule { }
