<ion-item lines='none'>
    <ion-button tabIndex="1" class="ion-margin-right-0" slot="end" color="secondary" fill="clear" (click)="clear()"
        [disabled]="disableClear">
        Clear
        <ion-icon slot="end" name="close-circle-outline" class="ion-label-font-xlg"></ion-icon>
    </ion-button>
</ion-item>

<ion-item lines="none">
    <ion-label class="ion-label-font-sm ion-text-center ion-no-margin">
        {{ formControl.value ? formControl.value : '...' }}
    </ion-label>
</ion-item>

<ion-item lines="none">
    <ion-range [min]="props.min" [max]="props.max"
        [ngStyle]="{'--knob-background': formControl.value === null || formControl.value === undefined ? '#e2e2e2' : 'var(--ion-color-primary)'}"
        [formControl]="$any(formControl)" [debounce]="debounceTime" [taFormlyAttributes]="field" pin="true">
        <ion-label class="ion-label-font-xs" slot="start" color="medium">{{props.min}}</ion-label>
        <ion-label class="ion-label-font-xs" slot="end" color="medium">{{props.max}}</ion-label>
    </ion-range>
</ion-item>