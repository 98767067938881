import { Component, OnInit } from '@angular/core';

import { ITaFormlyFieldConfig } from '@models/types';

import { FieldType } from '@ngx-formly/core';

const TAG = 'TaToggleComponent';

@Component({
    selector: 'app-ta-toggle',
    templateUrl: 'ta-toggle.html',
    styleUrls: ['ta-toggle.scss']
})
export class TaToggleComponent extends FieldType implements OnInit {

    field: ITaFormlyFieldConfig;

    constructor() {
        super();
    }

    ngOnInit(): void {

    }

}
