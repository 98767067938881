import { ChangeDetectionStrategy, Component, HostListener, OnInit } from '@angular/core';

import { FieldType } from '@ngx-formly/core';

import { AuditService } from '@services/audit.service';
import { ConfigService } from '@services/config.service';

@Component({
    selector: 'app-ta-input',
    templateUrl: 'ta-input.html',
    styleUrls: ['ta-input.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaInputComponent extends FieldType implements OnInit {

    private updatedKey: string;
    private fullInputString: string = '';

    public disableClear = false;
    public invalidNumber = false;
    public invalidDecimal = false;

    constructor(
        private auditService: AuditService,
        private configService: ConfigService
    ) {
        super();
    }

    @HostListener('ionBlur')
    public ionBlur() {
        if (this.formControl.value || !isNaN(parseFloat(this.formControl.value))) {
            if (this.props.showInList) {
                const value = { key: this.updatedKey, label: this.props.label, answer: this.formControl.value, type: this.field.type as string };
                this.auditService.addShowInListValue(value);
            }
        } else {
            if (this.props.showInList) {
                this.auditService.removeShowInListValue(this.updatedKey as string);
            }
        }
    }

    public ionInput(event: any) {
        if (event.detail.event.inputType === 'deleteContentBackward') {
            if (this.fullInputString.length > 0) {
                this.fullInputString = this.fullInputString.substring(0, this.fullInputString.length - 1);
            }
        } else if (event.detail.event.inputType === 'insertText') {
            if (event.detail.event.data === "-") {
                this.fullInputString = this.fullInputString + event.detail.event.data;
            } else {
                this.fullInputString = this.fullInputString + event.detail.event.data;
            }
        }

        if (this.props.inputmode === 'numeric') {
            if (this.fullInputString.lastIndexOf('.') !== -1) {
                this.invalidNumber = true;
            } else if (this.fullInputString.lastIndexOf('-') !== -1 && this.fullInputString.lastIndexOf('-') !== 0) {
                this.invalidNumber = true;
            } else {
                this.invalidNumber = false;
            }
        } else if (this.props.inputmode === 'decimal') {
            if (this.fullInputString.lastIndexOf('-') !== -1 && this.fullInputString.lastIndexOf('-') !== 0) {
                this.invalidDecimal = true;
            } else {
                this.invalidDecimal = false;
            }
        }
    }

    ngOnInit() {
        this.updatedKey = this.key as string;
        if (this.field.parent?.key && this.field.parent?.parent.key) {
            this.updatedKey = this.field.parent?.parent.key + '[' + this.field.parent?.key + '].' + this.key;
        }
        if (this.formControl.value) {
            this.fullInputString = this.formControl.value + '';
        }
    }

    public clear() {
        // The formControl.reset triggers an ionChange event with a null formControl.value
        this.formControl.reset(null);
        if (this.model.audit_type === 'Register Item Checklist') {
            if (this.updatedKey === 'data.section_0.question_1.findings.value') {
                this.model.register_item_id = null;
            }
        }
    }

    public integerValuesOnly(event: any) {
        /*
         * Note: On Android the '.' and '-' characters do not register as a keypress events
         */
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode < 48 || charCode > 57) {
            event.preventDefault();
        }
    }

    public decimalValuesOnly(event: any) {
        /*
         * Note: On Android the '.' and '-' characters do not register as a keypress events
         */
        const charCode = (event.which) ? event.which : event.keyCode;
        if ((charCode < 48 || charCode > 57) && !(charCode === 46 || charCode === 108 || charCode === 110)) {
            event.preventDefault();
        } else if (charCode === 46 || charCode === 108 || charCode === 110) {
            if (this.fullInputString.length === 0 || this.fullInputString.indexOf('.') !== -1) {
                event.preventDefault();
            }
        }
    }

}
