import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { AngularSignaturePadModule } from '@almothafar/angular-signature-pad';

import { SignatureModalPage } from './signature-modal';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        AngularSignaturePadModule
    ],
    declarations: [SignatureModalPage]
})
export class SignatureModalPageModule { }
