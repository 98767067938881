import { Component, Input, ViewChild } from '@angular/core';

import { IonContent, ModalController } from '@ionic/angular';

import { SignaturePadComponent } from '@almothafar/angular-signature-pad';

@Component({
    selector: 'app-signature-modal',
    templateUrl: 'signature-modal.html',
    styleUrls: ['signature-modal.scss']
})
export class SignatureModalPage {

    @Input() public type: 'view' | 'edit';
    @Input() public currentSignature: string;

    @ViewChild('SignaturePad') signaturePadComponent: SignaturePadComponent;
    @ViewChild(IonContent, { static: false }) content: IonContent;

    public signature = '';

    public signaturePadOptions = {
        minWidth: 0.5,
        maxWidth: 1.5,
        canvasWidth: 300,
        canvasHeight: 150
    };

    constructor(
        public modalController: ModalController,
    ) { }

    ionViewWillEnter() {
        this.resizeCanvas();
    }

    public clearSignature() {
        this.signaturePadComponent.clear();
    }

    public saveSignature() {
        let signature: string | null = null;
        /* Only return signature if the signaturePadComponent is not empty.
          This prevents the blank white canvas being returned as the signature.
        */
        if (!this.signaturePadComponent.isEmpty()) {
            signature = this.signaturePadComponent.toDataURL();
        }
        this.modalController.dismiss(signature, 'save');

    }

    public closeModal() {
        this.modalController.dismiss();
    }

    private resizeCanvas() {
        setTimeout(async () => {
            if (this.type === 'view') {
                this.signaturePadComponent.fromDataURL(this.currentSignature);
                this.signaturePadComponent.off();
            } else {
                const contentDimensions = await this.content.getScrollElement();
                // console.log('contentDimensions.clientWidth - 12=', contentDimensions.clientWidth - 12)
                // console.log('(contentDimensions.clientWidth - 12) * 0.5', (contentDimensions.clientWidth - 12) * 0.5)
                this.signaturePadComponent.set('penColor', '#000');
                this.signaturePadComponent.set('backgroundColor', '#FFF');
                this.signaturePadComponent.set('canvasWidth', contentDimensions.clientWidth - 12);
                this.signaturePadComponent.set('canvasHeight', (contentDimensions.clientWidth - 12) * 0.5);
                this.signaturePadComponent.clear();
            }
        });
    }

}
