import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ListSkeletonPage } from './list-skeleton.page';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
    ],
    declarations: [ListSkeletonPage],
    exports: [ListSkeletonPage]
})
export class ListSkeletonPageModule { }
