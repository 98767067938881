import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { FormlyModule } from '@ngx-formly/core';

import { TaFormlyFieldWrapperModule } from '@ta-formly/ta-field-wrapper/ta-field-wrapper.module';
import { MultiAssetScanComponent } from './multi-asset-scan/multi-asset-scan';
import { SingleAssetScanComponent } from './single-asset-scan/single-asset-scan';
import { TaAssetScanComponent } from './ta-asset-scan.type';

@NgModule({
    declarations: [
        TaAssetScanComponent,
        MultiAssetScanComponent,
        SingleAssetScanComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        IonicModule,
        TaFormlyFieldWrapperModule,
        FormlyModule.forChild({
            types: [
                {
                    name: 'ta-asset-scan',
                    component: TaAssetScanComponent,
                    wrappers: ['ta-field-wrapper']
                }
            ]
        })
    ],
    providers: [],
})
export class TaFormlyAssetScanModule { }
