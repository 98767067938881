import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';

import { IonTextarea, ModalController } from '@ionic/angular';

@Component({
    selector: 'app-page-comments-modal',
    templateUrl: 'comments-modal.html',
    styleUrls: ['comments-modal.scss']
})
export class CommentsModalPage implements OnInit, AfterViewInit {

    @Input() public comments: string;

    @ViewChild(IonTextarea) private textarea: IonTextarea;

    constructor(
        public modalController: ModalController
    ) { }

    ngOnInit() { }

    ngAfterViewInit() {
        setTimeout(async () => {
            await this.textarea.setFocus();
        }, 600);
    }

    public async saveComments() {
        this.modalController.dismiss(this.comments, 'update');
    }

    public clearComments() {
        this.comments = '';
    }

    public async closeModal() {
        this.modalController.dismiss();
    }

}
