import { Component, HostListener, OnInit } from '@angular/core';

import { Platform } from '@ionic/angular';

import { FieldType } from '@ngx-formly/core';
import { AuditService } from '@services/audit.service';

@Component({
    selector: 'app-ta-range',
    templateUrl: 'ta-range.html',
    styleUrls: ['ta-range.scss']
})
export class TaRangeComponent extends FieldType implements OnInit {

    public debounceTime = 10;
    public disableClear = false;
    private updatedKey: string;

    constructor(
        private platform: Platform,
        private auditService: AuditService
    ) {
        super();
    }

    @HostListener('ionBlur')
    public ionBlur() {
        if (!isNaN(parseFloat(this.formControl.value))) {
            if (this.props.showInList) {
                const value = { key: this.updatedKey as string, label: this.props.label, answer: this.formControl.value, type: this.field.type as string };
                this.auditService.addShowInListValue(value);
            }
        } else {
            if (this.props.showInList) {
                this.auditService.removeShowInListValue(this.updatedKey as string);
            }
        }
    }

    @HostListener('ionChange')
    public ionChange() {
        this.rangeChanges();

    }

    ngOnInit() {
        this.updatedKey = this.key as string;
        if (this.field.parent?.key && this.field.parent?.parent.key) {
            this.updatedKey = this.field.parent?.parent.key + '[' + this.field.parent?.key + '].' + this.key;
        }

        if (this.platform.is('ios')) {
            this.debounceTime = 30;
        }
    }

    public clear() {
        this.formControl.reset(null);
    }

    private rangeChanges() {
        if (!isNaN(parseFloat(this.formControl.value))) {
            this.disableClear = false;
            this.auditService.setLocationAndTimestamp(this.updatedKey);
        } else {
            this.disableClear = true;
            this.auditService.unsetLocationAndTimestamp(this.updatedKey);
        }
    }

}
