import { ChangeDetectionStrategy, Component, HostListener, OnInit } from '@angular/core';
import { ToastController } from '@ionic/angular';

import { FieldType } from '@ngx-formly/core';

import { ITaFormlyOptions } from '@models/types';
import { AuditService } from '@services/audit.service';

import { set, unset } from 'lodash-es';

@Component({
    selector: 'app-ta-radio',
    templateUrl: './ta-radio.html',
    styleUrls: ['./ta-radio.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class TaRadioComponent extends FieldType implements OnInit {

    public defaultOptions = {
        templateOptions: { options: [] },
    };
    private failPath: string;
    private updatedKey: string;

    constructor(
        private auditService: AuditService,
        public toastController: ToastController,
    ) {
        super();
    }

    @HostListener('ionChange')
    public async ionChange() {
        await this.checkOptionChanges();
    }

    async ngOnInit() {
        // Get the failPath of the field by replacing the ".value" field's key with ".failed"
        this.updatedKey = this.key as string;
        if (this.field.parent?.key && this.field.parent?.parent.key) {
            this.updatedKey = this.field.parent?.parent.key + '[' + this.field.parent?.key + '].' + this.key;
        }

        this.failPath = (this.key as string).substring(0, (this.key as string).lastIndexOf('.')) + '.failed';

        for (const option of (this.props.options as ITaFormlyOptions[])) {
            if (option.disabled) {
                this.formControl.get(option.key).disable();
            }
        }
    }

    public clear() {
        // The formControl.reset triggers an ionChange event with a null formControl.value
        this.formControl.reset(null);
    }

    private async checkOptionChanges() {
        if (this.formControl.value) {
            const options = this.props.options as ITaFormlyOptions[];
            let answer: string;
            for (const option of options) {
                if (option.key === this.formControl.value) {
                    answer = option.value;
                    if (option.failedResponse) {
                        set(this.model, this.failPath, option.severity);
                    } else {
                        set(this.model, this.failPath, null);
                    }
                }
            }

            if (this.props.showInList) {
                const value = { key: this.updatedKey as string, label: this.props.label, answer, type: this.field.type as string };
                this.auditService.addShowInListValue(value);
            }

            this.auditService.setLocationAndTimestamp(this.updatedKey);

        } else {

            unset(this.model, this.failPath);

            if (this.props.showInList) {
                this.auditService.removeShowInListValue(this.updatedKey as string);
            }
            this.auditService.unsetLocationAndTimestamp(this.updatedKey);

        }
    }

}
