import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatStepperModule } from '@angular/material/stepper';

import { IonicModule } from '@ionic/angular';

import { FormlyModule } from '@ngx-formly/core';

import { TaStepperComponent } from './ta-stepper.type';

@NgModule({
    declarations: [TaStepperComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatStepperModule,
        IonicModule,
        FormlyModule.forChild({
            types: [
                {
                    name: 'ta-stepper',
                    component: TaStepperComponent,
                    wrappers: []
                }
            ]
        })
    ]
})
export class TaFormlyStepperModule { }
