import { Directive, HostListener, Input } from '@angular/core';
import { ɵFormlyAttributes, FormlyFieldConfig } from '@ngx-formly/core';

const TAG = 'TaFormlyAttributesDirective';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[taFormlyAttributes]'
})
export class TaFormlyAttributesDirective extends ɵFormlyAttributes {
    @Input('taFormlyAttributes') field: FormlyFieldConfig;

    @HostListener('ionBlur')
    public ionBlur() {
        const METHOD = 'ionBlur';
        console.log(TAG + METHOD);
    }

    @HostListener('ionChange')
    public ionChange() {
        const METHOD = 'ionChange';
        console.log(TAG + METHOD);
    }

    @HostListener('ionFocus')
    public ionFocus() {
        const METHOD = 'ionFocus';
        console.log(TAG + METHOD);
    }

    @HostListener('ionInput')
    public ionInput() {
        const METHOD = 'ionInput';
        console.log(TAG + METHOD);
    }

    focusElement(element, value: boolean) {
        if (!element.setFocus || !value) {
            return;
        }

        if (!element.getInputElement()) {
            setTimeout(() => element.setFocus(), 300);
        } else {
            element.setFocus();
        }
    }

}
