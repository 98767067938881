import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { AlertController, IonItemSliding, ModalController, ToastController } from '@ionic/angular';

import { AssetInformationModalPage } from '@modals/asset-information-modal/asset-information-modal.page';
import { UserSelectModalPage } from '../user-select-modal/user-select-modal.page';
import { ModalRoutingBasePage } from '@components/modal-routing-base/modal-routing-base.page';

import { ApiService } from '@services/api.service';
import { AssetInformationModalService } from '@modals/asset-information-modal/asset-information-modal.service';

import { IAsset, IScannedAsset, ITaFormlyTemplateOptions, IUserContact } from '@models/types';

@Component({
    selector: 'app-multi-user-select',
    templateUrl: './multi-user-select.html',
    styleUrls: ['./multi-user-select.scss'],
})
export class MultiUserSelectComponent implements OnInit {

    @Input() scannedAssets: (IScannedAsset & { contact: Partial<IUserContact> })[] = [];
    @Input() props: ITaFormlyTemplateOptions;
    @Input() key: string | number | string[];
    @Output() usersEvent: EventEmitter<IScannedAsset[]> = new EventEmitter();

    public assetLabel = '';
    private title = '';

    public isNFCEnabled = true;
    public isNFCSupported = true;
    public assetFormConfigSet = true;

    private latestAssetFormConfigId: string;
    private identifierType: 'rfid_tag' | 'qr_code' | 'uhf_rfid_tag';

    constructor(
        private alertController: AlertController,
        private apiService: ApiService,
        private assetInformationModalService: AssetInformationModalService,
        private changeDetectorRef: ChangeDetectorRef,
        private modalController: ModalController,
        private toastController: ToastController,
    ) { }

    async ngOnInit() {
        await this.getUserAssetFormConfig();
    }

    private async getUserAssetFormConfig() {
        const userFormConfig = (await this.apiService.getUserFormConfig())[0];
        this.latestAssetFormConfigId = userFormConfig.id;
        this.assetLabel = userFormConfig.sections[0]?.questions[0]?.fields[0]?.templateOptions?.label;
        this.title = userFormConfig.title;
        this.changeDetectorRef.detectChanges();
    }

    public async presentUsersSelectModal() {
        const userSelectModal = await this.modalController.create({
            component: UserSelectModalPage,
            componentProps: {
                multi: this.props.multi,
                currentlySelectedUsers: this.scannedAssets.map(selectedUser => selectedUser.asset_id),
                userFormConfigId: this.latestAssetFormConfigId
            },
            canDismiss: true
        });
        await userSelectModal.present();

        const { data } = await userSelectModal.onWillDismiss();
        if (data) {
            this.scannedAssets.push(...data);
            this.saveUsers();
        }
    }

    public async scanQrCode(inputValue?: string) {
        this.identifierType = 'qr_code';
        const prompt = await this.alertController.create({
            header: this.title,
            inputs: [
                {
                    name: 'qr_code',
                    placeholder: 'Enter QR Code',
                    value: inputValue || ''
                },
            ],
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel'
                },
                {
                    text: 'Submit',
                    handler: async (data) => {
                        if (data && data.qr_code) {
                            this.processIdentifier(data.qr_code);
                        } else {
                            const toast = await this.toastController.create({ header: 'Value cannot be empty', color: 'danger', position: 'top', duration: 2000, })
                            toast.present();
                            return false;
                        }
                    }
                }
            ],
            backdropDismiss: false
        });
        await prompt.present();
    }

    public async startScanRfidTag(inputValue?: string) {
        this.identifierType = 'rfid_tag';
        const prompt = await this.alertController.create({
            header: this.title,
            inputs: [
                {
                    name: 'rfid_tag',
                    placeholder: 'Enter RFID Tag',
                    value: inputValue || ''
                },
            ],
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel'
                },
                {
                    text: 'Submit',
                    handler: async (data) => {
                        if (data && data.rfid_tag) {
                            this.processIdentifier(data.rfid_tag);
                        } else {
                            const toast = await this.toastController.create({ header: 'Value cannot be empty', color: 'danger', position: 'top', duration: 2000, })
                            toast.present();
                            return false;
                        }
                    }
                }
            ],
            backdropDismiss: false
        });
        await prompt.present();
    }

    public async presentUhfRfidInputTextPrompt(inputValue?: string) {
        this.identifierType = 'uhf_rfid_tag';
        const prompt = await this.alertController.create({
            header: 'Scan UHF RFID Tag',
            inputs: [
                {
                    name: 'uhf_rfid_tag',
                    placeholder: 'UHF RFID Tag',
                    value: inputValue || ''
                },
            ],
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel'
                },
                {
                    text: 'Submit',
                    handler: async (data) => {
                        if (data && data.uhf_rfid_tag) {
                            this.processIdentifier(data.uhf_rfid_tag);
                        } else {
                            const toast = await this.toastController.create({ header: 'Value cannot be empty', color: 'danger', position: 'top', duration: 2000, })
                            toast.present();
                            return false;
                        }
                    }
                }
            ],
            backdropDismiss: false
        });
        await prompt.present();
    }

    private async processIdentifier(identifierValue: string) {
        const assets: (IAsset & { contact: Partial<IUserContact> })[] = await this.apiService.getAssetsByIdentifier(this.identifierType, identifierValue, this.latestAssetFormConfigId);
        if (assets.length === 0) {
            this.presentNoAssetFoundAlert();
        } else {
            if (assets.length === 1) {
                const asset = assets[0];
                const { id, asset_form_config_id, identifiers: { asset_identifier } } = asset;
                const toAssetFormConfigComparisonId = this.props.asset_form_config_id?.split('::v')[0];
                const assetFormConfigComparisonId = asset_form_config_id.split('::v')[0];

                if (this.scannedAssets.find(a => a.asset_identifier.toLowerCase().trim() === asset_identifier.toLowerCase().trim())) {
                    await this.presentAlreadyScannedAlert();
                } else if (this.props?.asset_form_config_id && toAssetFormConfigComparisonId !== assetFormConfigComparisonId) {
                    this.presentWrongAssetTypeAlert();
                } else {
                    await this.confirmAsset(id, asset);
                }
            } else {
                this.presentMultiAssetInformationModal(assets);
            }
        }
    }

    private async confirmAsset(userAssetId: string, userAsset: (IAsset & { contact: Partial<IUserContact> })) {
        const assetIdentifier: any = userAsset.sections[0].questions[0];
        const scannedUser: (IScannedAsset & { contact: Partial<IUserContact> }) = {
            asset_id: userAssetId,
            asset_identifier: assetIdentifier.answer,
            contact: {
                first_name: userAsset.contact.first_name,
                last_name: userAsset.contact.last_name,
            },
            label: assetIdentifier.label,
            value: assetIdentifier.label + ': ' + assetIdentifier.answer,
            sections: [{
                questions: userAsset.sections[0].questions.filter(question => question.showInList && question.answer)
            }]
        };
        this.scannedAssets.unshift(scannedUser);
        this.saveUsers();
    }

    public async presentMultiAssetInformationModal(assets: (IAsset & { contact: Partial<IUserContact> })[]) {
        const multiAssetInformationModal = await this.modalController.create({
            component: ModalRoutingBasePage,
            componentProps: {
                rootPage: AssetInformationModalPage,
                data: {
                    assets,
                    multi: false,
                    type: 'view',
                    isQuestion: true
                },
            },
            canDismiss: true
        });
        await multiAssetInformationModal.present();

        const response = await multiAssetInformationModal.onDidDismiss();
        if (response.data) {
            const assetId = response.data;
            if (this.scannedAssets.find(a => a.asset_id.toLowerCase().trim() === assetId)) {
                await this.presentAlreadyScannedAlert();
            } else {
                const asset = assets.find(a => a.id === assetId);
                this.confirmAsset(assetId, asset);
            }
        }
    }

    public async presentAssetInformationModal(assetId: string, itemSliding?: IonItemSliding) {
        const asset = await this.apiService.getAsset(assetId);
        const assetInformationModal = await this.modalController.create({
            component: AssetInformationModalPage,
            componentProps: {
                assetId,
                asset,
                multi: this.props.multi,
                type: 'view'
            },
            canDismiss: true,
        });

        this.assetInformationModalService.pageType = 'modal';

        if (itemSliding) {
            itemSliding.close();
        }
        await assetInformationModal.present();
    }

    private async presentAlreadyScannedAlert() {
        const assetAlreadyScannedAlert = await this.alertController.create({
            header: 'User already added',
            message: 'This user has already been added. Please try with another user.',
            buttons: ['Ok']
        });
        await assetAlreadyScannedAlert.present();
    }

    private async presentNoAssetFoundAlert() {
        let message = '';
        if (this.identifierType === 'rfid_tag') {
            message = 'This RFID tag has not been recognised.\nPlease try scanning again.';
        } else if (this.identifierType === 'qr_code') {
            message = 'This QR Code has not been recognised.\nPlease try scanning again.';
        } else if (this.identifierType === 'uhf_rfid_tag') {
            message = 'This UHF RFID Tag has not been recognised.\nPlease try again.';
        }

        const noAssetFoundAlert = await this.alertController.create({
            header: 'Cannot find user',
            message,
            buttons: ['Ok']
        });
        await noAssetFoundAlert.present();
    }

    private async presentWrongAssetTypeAlert() {
        let message = '';
        if (this.identifierType === 'rfid_tag' || this.identifierType === 'qr_code') {
            message = 'You have scanned the wrong type of asset.\nPlease try scanning another asset of type ' + this.title + '.';
        } else if (this.identifierType === 'uhf_rfid_tag') {
            message = 'You have entered a ' + this.assetLabel + ' for the wrong type of asset.\nPlease try entering the ' + this.assetLabel + ' of another asset of type ' + this.title + '.';
        }

        const wrongAssetTypeAlert = await this.alertController.create({
            header: 'Cannot find user',
            message,
            buttons: ['Ok']
        });
        await wrongAssetTypeAlert.present();
    }

    public async presentDeleteAlert(currentUserIndex: number, itemSliding?: IonItemSliding) {
        const currentUser = this.scannedAssets[currentUserIndex];
        const deleteUserAlert = await this.alertController.create({
            header: currentUser.value,
            message: 'Do you want to remove this user?',
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel'
                },
                {
                    text: 'Delete',
                    handler: () => {
                        this.scannedAssets.splice(currentUserIndex, 1);
                        if (itemSliding) {
                            itemSliding.close();
                        }
                        this.saveUsers();
                    }
                }
            ],
            backdropDismiss: false
        });
        await deleteUserAlert.present();
    }


    private saveUsers() {
        if (this.scannedAssets?.length > 0) {
            this.usersEvent.emit(this.scannedAssets);
        } else {
            this.usersEvent.emit(null);
        }

    }
}
