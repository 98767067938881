import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { FormlyModule } from '@ngx-formly/core';

import { TaFormlyFieldWrapperModule } from '@ta-formly/ta-field-wrapper/ta-field-wrapper.module';
import { TaHiddenComponent } from './ta-hidden.type';

@NgModule({
    declarations: [TaHiddenComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        IonicModule,
        TaFormlyFieldWrapperModule,
        FormlyModule.forChild({
            types: [
                {
                    name: 'ta-hidden',
                    component: TaHiddenComponent,
                    wrappers: []
                }
            ]
        })
    ]
})
export class TaFormlyHiddenModule { }
