import { Component, OnDestroy, OnInit, Optional } from '@angular/core';

import { IonNav, ModalController } from '@ionic/angular';

import { AssetInformationModalService } from './asset-information-modal.service';

import { IAsset } from '@models/types';

@Component({
    selector: 'app-page-asset-information-modal',
    templateUrl: 'asset-information-modal.page.html',
    styleUrls: ['asset-information-modal.page.scss'],
})
export class AssetInformationModalPage implements OnInit, OnDestroy {

    // Multi assets with same identifier
    public assets?: IAsset[];

    // Single asset
    public asset?: IAsset;
    public assetId: string;
    public latestAssetFormConfigId: string;

    // Asset selection
    public selectedAssets: string[];
    public title?: string;
    public identifierLabel?: string;

    public multi: boolean;
    public type: 'selection' | 'view';
    public isQuestion = false;

    public multipleAssetsWithSameIdentifier: boolean;

    public isDataLoaded = false;

    constructor(
        private assetInformationModalService: AssetInformationModalService,
        private modalController: ModalController,
        @Optional() private nav: IonNav
    ) { }

    ngOnInit() { }

    ionViewWillEnter() {
        if (this.nav?.rootParams) {
            // Multi assets with same identifier
            this.multipleAssetsWithSameIdentifier = true;
        } else {
            // Single asset
            this.multipleAssetsWithSameIdentifier = false;
        }

        this.assetInformationModalService.assets = this.assets as IAsset[];
        this.assetInformationModalService.asset = this.asset as IAsset;
        this.assetInformationModalService.assetId = this.assetId;
        this.assetInformationModalService.latestAssetFormConfigId = this.latestAssetFormConfigId;
        this.assetInformationModalService.selectedAssets = this.selectedAssets;
        this.assetInformationModalService.title = this.title as string;
        this.assetInformationModalService.identifierLabel = this.identifierLabel as string;
        this.assetInformationModalService.multi = this.multi;
        this.assetInformationModalService.type = this.type;
        this.assetInformationModalService.isQuestion = this.isQuestion;

        this.isDataLoaded = true;
    }

    public async closeModal() {
        await this.modalController.dismiss();
    }

    ngOnDestroy() {
        this.assetInformationModalService.reset();
    }
}
