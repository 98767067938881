<ion-header>
  <ion-toolbar>
    <ion-title>Signature</ion-title>
    <ion-label class="ion-margin-right-2" slot="end" (click)="closeModal()">Close</ion-label>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-text-center" scrollY="false">
  <ion-grid>
    <ion-row>
      <ion-col class="ion-no-padding signature-pad-container">
        <signature-pad #SignaturePad [options]="signaturePadOptions"></signature-pad>
      </ion-col>
      <ng-container *ngIf="type === 'edit'">
        <ion-col class="ion-margin-top-3 ion-margin-bottom-top-1 ion-text-left" size="12">
          <ion-label class="ion-label-font-sm" color="medium">
            Draw your signature in the box above.
          </ion-label>
        </ion-col>
        <ion-col class="ion-margin-bottom-1 ion-text-left" size="12">
          <ion-label class="ion-label-font-sm" color="medium">
            Once you have signed, click <ion-text color="primary">Save</ion-text>.
          </ion-label>
        </ion-col>
        <ion-col class="ion-text-left" size="12">
          <ion-label class="ion-label-font-sm" color="medium">
            If you wish to retry your signature, click <ion-text color="danger">Clear</ion-text> and draw
            your
            signature again.
          </ion-label>
        </ion-col>
      </ng-container>
    </ion-row>
  </ion-grid>
</ion-content>

<ion-footer *ngIf="type === 'edit'">
  <ion-toolbar class="ion-no-padding">
    <ion-grid>
      <ion-row>
        <ion-col>
          <ion-button color="danger" expand="block" (click)="clearSignature()">
            Clear
          </ion-button>
        </ion-col>
        <ion-col>
          <ion-button color="primary" expand="block" (click)="saveSignature()">
            Save
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-toolbar>
</ion-footer>
