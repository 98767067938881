<ion-item lines='none'>
    <ion-button tabIndex="1" class="ion-margin-right-0" slot="end" color="secondary" fill="clear" (click)="clear()"
        [disabled]="disableClear">
        Clear
        <ion-icon slot="end" name="close-circle-outline" class="ion-label-font-xlg"></ion-icon>
    </ion-button>
</ion-item>

<ion-item lines="none">
    <ng-container *ngIf="props.inputmode === 'numeric'; else decimalType">
        <ion-input tabIndex="2" autoFocus="false" class="ion-label-font-sm" type="number" inputmode="numeric"
            [formControl]="$any(formControl)" [taFormlyAttributes]="field" placeholder="Input number here ..."
            pattern="^\d+$" (keypress)="integerValuesOnly($event)" (ionInput)="ionInput($event)">
        </ion-input>
    </ng-container>

    <ng-template #decimalType>
        <ng-container *ngIf="props.inputmode === 'decimal'; else textType">
            <ion-input tabIndex="2" autoFocus="false" class="ion-label-font-sm" type="number" inputmode="decimal"
                [formControl]="$any(formControl)" [taFormlyAttributes]="field" placeholder="Input number here ..."
                pattern="^\d+(\.\d+)?$" (keypress)="decimalValuesOnly($event)" (ionInput)="ionInput($event)">
            </ion-input>
        </ng-container>
    </ng-template>

    <ng-template #textType>
        <ion-input tabIndex="2" autoFocus="false" class="ion-label-font-sm" autocapitalize="true" autocomplete="true"
            autocorrect="true" spellcheck="true" type="text" [formControl]="$any(formControl)"
            [taFormlyAttributes]="field" [placeholder]="props.placeholder || 'Input answer here ...'"
            (ionInput)="ionInput($event)">
        </ion-input>
    </ng-template>
</ion-item>
<ion-item lines="none">
    <ion-label class="ion-label-font-xs ion-margin-top-0" color="danger" *ngIf="invalidNumber">
        Invalid whole number
    </ion-label>
    <ion-label class="ion-label-font-xs ion-margin-top-0" color="danger" *ngIf="invalidDecimal">
        Invalid decimal number
    </ion-label>
</ion-item>