import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { FormlyModule } from '@ngx-formly/core';
import { FormlySelectModule } from '@ngx-formly/core/select';

import { TaFormlyFieldWrapperModule } from '@ta-formly/ta-field-wrapper/ta-field-wrapper.module';
import { TaPassFailNaComponent } from './ta-pass-fail-na.type';

@NgModule({
    declarations: [TaPassFailNaComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        IonicModule,

        TaFormlyFieldWrapperModule,
        FormlySelectModule,
        FormlyModule.forChild({
            types: [
                {
                    name: 'ta-pass-fail-na',
                    component: TaPassFailNaComponent,
                    wrappers: ['ta-field-wrapper']
                }
            ]
        })
    ]
})
export class TaFormlyPassFailNaModule { }
