import { Component, OnInit } from '@angular/core';

import { FieldWrapper } from '@ngx-formly/core';

import { ITaFormlyFieldConfig } from '@models/types';

const TAG = 'TaFieldWrapperComponent';

@Component({
    selector: 'app-ta-field-wrapper',
    templateUrl: 'ta-field-wrapper.html',
    styleUrls: ['ta-field-wrapper.scss']
})
export class TaFieldWrapperComponent extends FieldWrapper implements OnInit {

    field: ITaFormlyFieldConfig;

    ngOnInit() {
    }

}
