import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { FormlyModule } from '@ngx-formly/core';

import { TaFormlyFieldWrapperModule } from '@ta-formly/ta-field-wrapper/ta-field-wrapper.module';
import { TaBarcodeComponent } from './ta-barcode.type';

@NgModule({
    declarations: [TaBarcodeComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        IonicModule,
        TaFormlyFieldWrapperModule,
        FormlyModule.forChild({
            types: [
                {
                    name: 'ta-barcode',
                    component: TaBarcodeComponent,
                    wrappers: ['ta-field-wrapper']
                }
            ]
        })
    ],
    providers: [
    ]
})
export class TaFormlyBarcodeModule { }
