<ion-header>
    <ion-toolbar>
        <ion-title>Select Asset Type</ion-title>
        <ion-label class="ion-margin-right-2" slot="end" *ngIf="assetInformationModalService.type === 'view'"
            (click)="closeModal()">Close
        </ion-label>
    </ion-toolbar>
</ion-header>

<ion-content>
    <ng-container *ngIf="!isDataLoaded">
        <app-list-skeleton></app-list-skeleton>
    </ng-container>

    <ng-container *ngIf="isDataLoaded">
        <ion-item-group>
            <ion-list class="ion-padding-bottom-5">
                <ion-item lines="inset" *ngFor="let asset of assetInformationModalService.assets; let i = index;"
                    (click)="selectAsset(asset.id)" detail>
                    <ion-label>
                        <ion-label class="ion-label-font-md ion-text-wrap ion-font-weight-medium">
                            {{asset.title}}
                        </ion-label>
                        <ion-label class="ion-text-wrap ion-label-font-xs" color="medium">
                            V{{asset.version || 1}}
                        </ion-label>
                    </ion-label>
                    <ion-icon class="ion-label-font-lg ion-no-margin" slot="end" name="flag" color="danger"
                        *ngIf="asset.review_date && dayjs(asset.review_date).isSameOrBefore(dayjs(),'day')">
                    </ion-icon>
                    <ion-icon class="ion-label-font-lg ion-no-margin ion-margin-left-2" slot="end"
                        name="information-circle-outline" color="danger"
                        *ngIf="latestAssetFormConfigIds[i] && latestAssetFormConfigIds[i] !== asset.asset_form_config_id">
                    </ion-icon>
                </ion-item>
            </ion-list>
        </ion-item-group>
    </ng-container>
</ion-content>