import { Component, Input, OnInit, Optional } from '@angular/core';

import { PrepareAssetQuestionsForList } from '@library/Utils';

import { IAsset, IUserContact } from '@models/types';

import { cloneDeep } from 'lodash-es';

@Component({
    selector: 'app-asset',
    templateUrl: './asset.page.html',
    styleUrls: ['./asset.page.scss'],
})
export class AssetPage implements OnInit {

    @Input() public asset: (IAsset & { contact?: Partial<IUserContact> });
    @Input() public latestAssetFormConfigId: string;

    constructor() { }

    ngOnInit() {
        const asset = cloneDeep(this.asset);
        PrepareAssetQuestionsForList(asset);
        this.asset = asset;
    }
}
