import { Pipe, PipeTransform } from '@angular/core';

import { IAsset } from '@models/types';

@Pipe({
    name: 'projectSelect'
})
export class ProjectSelectPipe implements PipeTransform {

    transform(array: IAsset[], queryString: string): IAsset[] {
        if (array) {
            if (queryString && !queryString.match(/^\s+$/)) {
                const queryItems = queryString.toLowerCase().trim().split(/\s+/);

                return array.filter((row) => {

                    let matchFound = true;

                    const rowSearchItems = [];

                    if (row.identifiers) {
                        if (row.identifiers.asset_identifier) {
                            rowSearchItems.push(row.identifiers.asset_identifier.toLowerCase());
                        }
                    }

                    for (const queryItem of queryItems) {

                        let exactMatchRequired = false;
                        if (queryItem.startsWith('=')) {
                            exactMatchRequired = true;
                        }

                        let queryItemFound = false;
                        for (const rowSearchItem of rowSearchItems) {
                            if (exactMatchRequired) {
                                const newQueryItem = queryItem.substring(1);

                                if (rowSearchItem === newQueryItem) {
                                    queryItemFound = true;
                                }
                            } else {
                                if (rowSearchItem.indexOf(queryItem) !== -1) {
                                    queryItemFound = true;
                                }
                            }
                            if (queryItemFound) {
                                break;
                            }
                        }

                        if (!queryItemFound) {
                            matchFound = false;
                        }

                    }

                    return matchFound;
                });

            }
        }
        return array;
    }

}
