import { Injectable } from '@angular/core';

import { IAsset } from '@models/types';

@Injectable()
export class AssetInformationModalService {

    // Multi assets with same identifier
    private _assets: IAsset[];

    // Single asset
    private _asset: IAsset;
    private _assetId: string;
    private _latestAssetFormConfigId: string;

    // Asset selection
    private _selectedAssets: string[];
    private _title: string;
    private _identifierLabel: string;

    private _multi: boolean;
    private _pageType: 'page' | 'modal';
    private _type: 'selection' | 'view';
    private _isQuestion: boolean;

    constructor() { }

    public get assets() {
        return this._assets;
    }

    public set assets(value: IAsset[]) {
        this._assets = value;
    }

    public get asset() {
        return this._asset;
    }

    public set asset(value: IAsset) {
        this._asset = value;
    }

    public get assetId() {
        return this._assetId;
    }

    public set assetId(value: string) {
        this._assetId = value;
    }

    public get latestAssetFormConfigId() {
        return this._latestAssetFormConfigId;
    }

    public set latestAssetFormConfigId(value: string) {
        this._latestAssetFormConfigId = value;
    }

    public get selectedAssets() {
        return this._selectedAssets;
    }

    public set selectedAssets(value: string[]) {
        this._selectedAssets = value;
    }

    public get title() {
        return this._title;
    }

    public set title(value: string) {
        this._title = value;
    }

    public get identifierLabel() {
        return this._identifierLabel;
    }

    public set identifierLabel(value: string) {
        this._identifierLabel = value;
    }

    public get multi() {
        return this._multi;
    }

    public set multi(value: boolean) {
        this._multi = value;
    }

    public get pageType() {
        return this._pageType;
    }

    public set pageType(value: 'page' | 'modal') {
        this._pageType = value;
    }

    public get type() {
        return this._type;
    }

    public set type(value: 'selection' | 'view') {
        this._type = value;
    }

    public get isQuestion() {
        return this._isQuestion;
    }

    public set isQuestion(value: boolean) {
        this._isQuestion = value;
    }

    public reset() {
        this.assets = null;
        this.asset = null;
        this.assetId = null;
        this.latestAssetFormConfigId = null;
        this.selectedAssets = null;
        this.title = null;
        this.identifierLabel = null;
        this.multi = null;
        this.type = null;
        this.isQuestion = null;
    }

}
