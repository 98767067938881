<ion-item lines="none" *ngIf="scannedAsset">
    <ion-label>
        <ion-label class="ion-label-font-sm ion-text-wrap ion-margin-bottom-1">{{ scannedAsset.value }}</ion-label>
        <ng-container *ngIf="scannedAsset.sections && scannedAsset.sections.length > 0">
            <ng-container *ngFor="let question of scannedAsset.sections[0].questions">
                <ng-container *ngIf="question.type === 'ta-checkbox'; else showRadio">
                    <ion-label class="ion-text-wrap ion-label-font-xs">
                        <ion-icon class="question-label-icon ion-margin-right-1" slot="start" name="ellipse"
                            color="secondary">
                        </ion-icon>
                        {{ question.label }}
                    </ion-label>
                    <ng-container *ngFor="let option of question.options">
                        <ng-container *ngIf="question.answer[option.key]">
                            <ion-label class="ion-text-wrap ion-label-font-xs ion-margin-left-3">
                                - {{ option.value }}
                            </ion-label>
                        </ng-container>
                    </ng-container>
                </ng-container>
                <ng-template #showRadio>
                    <ng-container *ngIf="question.type === 'ta-radio'; else showDate">
                        <ng-container *ngFor="let option of question.options">
                            <ng-container *ngIf="question.answer === option.key">
                                <ion-label class="ion-text-wrap ion-label-font-xs">
                                    <ion-icon class="question-label-icon ion-margin-right-1" slot="start" name="ellipse"
                                        color="secondary">
                                    </ion-icon>
                                    {{ question.label + ' - ' + option.value }}
                                </ion-label>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </ng-template>
                <ng-template #showDate>
                    <ng-container *ngIf="question.type === 'ta-date'; else showOthers">
                        <ion-label class="ion-text-wrap ion-label-font-xs">
                            <ion-icon class="question-label-icon ion-margin-right-1" slot="start" name="ellipse"
                                color="secondary">
                            </ion-icon>
                            {{question.label + ' - ' + (question.answer | date:'dd/MM/yyyy')}}
                        </ion-label>
                    </ng-container>
                </ng-template>
                <ng-template #showOthers>
                    <ion-label class="ion-text-wrap ion-label-font-xs">
                        <ion-icon class="question-label-icon ion-margin-right-1" slot="start" name="ellipse"
                            color="secondary">
                        </ion-icon>
                        {{question.label + ' - ' + question.answer}}
                    </ion-label>
                </ng-template>
            </ng-container>
        </ng-container>
    </ion-label>
</ion-item>

<ion-grid>
    <ion-row>
        <ng-container *ngIf="!scannedAsset">
            <ion-col size-sm="6" offset-sm="3" size-xs="12">
                <ion-button tabIndex="1" class="ion-text-wrap ion-margin-bottom-2 ion-label-font-sm" color="primary"
                    expand="block" *ngIf="props.inputEnabled" (click)="presentInputTextPrompt()"
                    [disabled]="!assetFormConfigSet">
                    Input {{assetLabel}}
                    <ion-icon slot="end" name="create-outline"></ion-icon>
                </ion-button>
                <ion-button tabIndex="1" class="ion-text-wrap ion-margin-bottom-2 ion-label-font-sm" color="primary"
                    expand="block" *ngIf="props.qrCodeEnabled" (click)="scanQrCode()" [disabled]="!assetFormConfigSet">
                    Scan QR Code
                    <ion-icon slot="end" name="qr-code-outline"></ion-icon>
                </ion-button>
                <ng-container *ngIf="props.rfidEnabled">
                    <ng-container *ngIf="isNFCSupported">
                        <ng-container *ngIf="isNFCEnabled">
                            <ion-button tabIndex="1" class="ion-text-wrap ion-margin-bottom-2 ion-label-font-sm"
                                color="primary" expand="block" (click)="startScanRfidTag()"
                                [disabled]="!assetFormConfigSet">
                                Scan RFID Tag
                                <ion-icon slot="end" name="barcode-outline"></ion-icon>
                            </ion-button>
                        </ng-container>
                        <ng-container *ngIf="!isNFCEnabled">
                            <ion-item lines="none">
                                <ion-label class="ion-margin-bottom-2 ion-label-font-sm ion-text-wrap">
                                    NFC is currently disabled on your device, please turn NFC on in your device
                                    settings.
                                </ion-label>
                            </ion-item>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="!isNFCSupported">
                        <ion-item lines="none">
                            <ion-label class="ion-margin-bottom-2 ion-label-font-sm ion-text-wrap">
                                NFC is not supported on this device, please use an Android device and/or Apple device
                                with iOS 7+ to be able to scan RFID tags.
                            </ion-label>
                        </ion-item>
                    </ng-container>
                </ng-container>

                <ion-button tabIndex="1" class="ion-text-wrap ion-margin-bottom-2 ion-label-font-sm" color="primary"
                    expand="block" *ngIf="props.uhfRfidEnabled" (click)="presentUhfRfidInputTextPrompt()"
                    [disabled]="!assetFormConfigSet">
                    Scan UHF RFID Tag
                    <ion-icon slot="end" name="create-outline"></ion-icon>
                </ion-button>
            </ion-col>
        </ng-container>
        <ng-container *ngIf="scannedAsset">
            <ion-col size-sm="3" offset-sm="3" size-xs="6">
                <ion-button tabIndex="1" class="ion-label-font-sm" color="primary" expand="block"
                    (click)="presentAssetInformationModal(scannedAsset.asset_id)">
                    View
                    <ion-icon slot="end" name="information-circle-outline"></ion-icon>
                </ion-button>
            </ion-col>
            <ion-col size-sm="3" size-xs="6">
                <ion-button tabIndex="1" class="ion-label-font-sm" color="danger" expand="block"
                    (click)="presentDeleteAlert(scannedAsset.value)">
                    Delete
                    <ion-icon slot="end" name="trash-outline"></ion-icon>
                </ion-button>
            </ion-col>
        </ng-container>
    </ion-row>
</ion-grid>