import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { FormlyModule } from '@ngx-formly/core';

import { TaFormlyFieldWrapperModule } from '@ta-formly/ta-field-wrapper/ta-field-wrapper.module';
import { TaDateComponent } from './ta-date.type';

@NgModule({
    declarations: [TaDateComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        IonicModule,
        TaFormlyFieldWrapperModule,
        FormlyModule.forChild({
            types: [
                {
                    name: 'ta-date',
                    component: TaDateComponent,
                    wrappers: ['ta-field-wrapper']
                }
            ]
        })
    ]
})
export class TaFormlyDateModule { }
