import { Injectable } from '@angular/core';

import { FormlyService } from './formly.service';
import { LocationService } from './location.service';
import { IAction, IAsset, IAudit, IAuditSectionQuestionStatus, IAuditSectionStatus, IFormConfig, IFormConfigField, IFormConfigSection, IScannedAsset } from '@models/types';

import { cloneDeep, get, set, unset } from 'lodash-es';
import { v4 } from 'uuid';

import * as dayjs from 'dayjs';

const TAG = 'AuditService';

@Injectable()
export class AuditService {

    constructor(
        private formlyService: FormlyService,
        private locationService: LocationService,
    ) { }

    public async initialiseAudit(formConfig: IFormConfig) {
        const METHOD = 'initialiseAudit';
        try {
            this.formlyService.reset();
            const refactoredFormConfig = this.refactorFormConfig(formConfig);
            this.formlyService.setFormConfig(refactoredFormConfig);
            const audit = await this.createAudit(refactoredFormConfig);
            this.formlyService.setModel(audit);
            this.updateModel();
        } catch (error) {
            console.error(TAG, METHOD, 'error=', error);
            throw error;
        }
    }

    public generateRepeatSectionItem(section: IFormConfigSection, key?: string) {
        const newSectionItem = cloneDeep(section);
        delete newSectionItem.repeat_sections;
        delete newSectionItem.repeat;

        if (key) {
            newSectionItem._id = key;
        } else {
            let sectionItemNumber = 0;
            if (section.repeat_sections.length > 0) {
                const lastSectionItem = section.repeat_sections[section.repeat_sections.length - 1];
                let lastSectionNumber: number = parseInt(lastSectionItem._id.substring(lastSectionItem._id.lastIndexOf('_') + 1), 10);
                sectionItemNumber = (lastSectionNumber = +lastSectionNumber + 1);
            }
            newSectionItem._id = 'section_' + sectionItemNumber;
        }
        newSectionItem.parent_section = section._id;

        for (const question of newSectionItem.questions) {
            for (const field of question.fields) {
                if (field.hideExpression) {
                    const hideExpression = (field.hideExpression).toString();
                    const changedHideExpression = hideExpression.replace(new RegExp(section._id, 'g'), section._id + '.repeat_sections.' + newSectionItem._id);
                    field.hideExpression = changedHideExpression as string;
                }
                const originalKey = field.key;
                field.key = field.key.replace(new RegExp(section._id, 'g'), section._id + '.repeat_sections.' + newSectionItem._id);

                const path = field.key.substring(0, field.key.lastIndexOf('.')) + '.value';

                if (field.props.options) {
                    /*
                     * THIS IS A MUST OR ELSE IT WON'T WORK
                     * Create options defaults for checkbox question types, this enables the checkbox to
                     * be a subquestion of radio and pass-fail
                     */
                    if (field.type === 'ta-checkbox') {
                        let currentValue = get(this.formlyService.getModel(), field.key);
                        if (!currentValue) {
                            currentValue = get(this.formlyService.getModel(), originalKey);
                        }
                        set(this.formlyService.getModel(), path, currentValue);
                    }

                    if (field.type === 'ta-radio' || field.type === 'ta-pass-fail' || field.type === 'ta-pass-fail-na') {
                        const currentValue = get(this.formlyService.getModel(), field.key);
                        set(this.formlyService.getModel(), path, currentValue);
                    }
                }
            }
        }

        // Only attach validators when adding a new section item
        if (!key) {
            this.formlyService.setQuestionValidators(newSectionItem);
        }
        return newSectionItem;
    }

    public updateAuditSectionStatus(section: IFormConfigSection, sectionIndex: number, isRepeatSection: boolean) {
        if (isRepeatSection) {
            this.formlyService.getStatus().sections[sectionIndex] = this.getRepeatAuditSectionItemStatuses(section);
        } else {
            this.formlyService.getStatus().sections[sectionIndex] = this.getAuditSectionStatus(section);
        }
        this.formlyService.getStatus().form_valid = this.calculateAuditFormValidity();
    }

    public getRepeatAuditSectionItemStatuses(parentSection: IFormConfigSection): IAuditSectionStatus {
        const repeatSectionStatuses: IAuditSectionStatus[] = [];
        for (const repeatSection of parentSection.repeat_sections) {
            const sectionStatus = this.getAuditSectionStatus(repeatSection);
            repeatSectionStatuses.push(sectionStatus);
        }

        let numberOfSectionItems = 0;
        let numberOfSectionItemRequired = 0;
        let numberOfSectionItemsAnswered = 0;
        let sectionValid = true;

        for (const repeatSectionStatus of repeatSectionStatuses) {
            if (repeatSectionStatus.valid) {
                numberOfSectionItemsAnswered++;
            } else {
                numberOfSectionItemRequired++;
                if (sectionValid) {
                    sectionValid = false;
                }
            }
            numberOfSectionItems++;
        }

        return {
            number_of_section_items: numberOfSectionItems,
            number_of_section_items_required: numberOfSectionItemRequired,
            number_of_section_items_answered: numberOfSectionItemsAnswered,
            repeat_sections: repeatSectionStatuses,
            display_text: this.getRepeatSectionDisplaytext(numberOfSectionItems, numberOfSectionItemRequired),
            valid: sectionValid
        };
    }

    public getRepeatSectionQuestionStatuses(section: IFormConfigSection): Array<IAuditSectionQuestionStatus[]> {
        const repeatSectionQuestionStatuses: Array<IAuditSectionQuestionStatus[]> = [];
        const repeatSections = section.repeat_sections;
        for (const repeatSection of repeatSections) {
            repeatSectionQuestionStatuses.push(this.getRepeatSectionQuestionStatus(repeatSection));
        }
        return repeatSectionQuestionStatuses;
    }

    public getRepeatSectionQuestionStatus(section: IFormConfigSection): IAuditSectionQuestionStatus[] {
        const repeatSectionQuestionStatus: IAuditSectionQuestionStatus[] = [];
        for (const question of section.questions) {
            for (const field of question.fields) {
                if (field.props.mobileItemList) {
                    const value = get(this.formlyService.getModel(), field.key, null);
                    if (value || ((field.type === 'ta-number' || field.type === 'ta-decimal' || field.type === 'ta-fuel-gauge' || field.type === 'ta-oil-level-gauge' || field.type === 'ta-range') && typeof value === 'number')) {
                        if (field.type === 'ta-radio' || field.type === 'ta-pass-fail' || field.type === 'ta-pass-fail-na') {
                            const options = field.props.options;
                            repeatSectionQuestionStatus.push({
                                label: field.props.label, answer: options.find((o: any) => o.key === value).value
                            });
                        } else if (field.type === 'ta-checkbox') {
                            const options: any[] = field.props.options;
                            const answers: string[] = [];
                            for (const option of options) {
                                if (value[option.key]) {
                                    answers.push(option.value);
                                }
                            }
                            repeatSectionQuestionStatus.push({
                                label: field.props.label, answer: answers.join(', ')
                            });
                        } else if (field.type === 'ta-asset-scan' || field.type === 'ta-project-select' || field.type === 'ta-user-select') {
                            const assets: IScannedAsset[] = value;
                            const answer = assets.map(asset => asset.value).join(', ');
                            repeatSectionQuestionStatus.push({ label: field.props.label, answer });
                        } else if (field.type === 'ta-datetimepicker') {
                            let value = '';
                            if (field.props.type === 'date') {
                                value = dayjs(value).format('dd/mm/yyyy');
                            } else if (field.props.type === 'time') {
                                value = dayjs(value).format('HH:mm');
                            }
                            repeatSectionQuestionStatus.push({ label: field.props.label, answer: value });
                        } else if (field.type === 'ta-date') {
                            const dateValue = dayjs(value).format('dd/mm/yyyy');
                            repeatSectionQuestionStatus.push({ label: field.props.label, answer: dateValue });
                        } else if (field.type === 'ta-time') {
                            const timeValue = dayjs(value).format('HH:mm');
                            repeatSectionQuestionStatus.push({ label: field.props.label, answer: timeValue });
                        } else {
                            repeatSectionQuestionStatus.push({ label: field.props.label, answer: value });
                        }
                    }
                }
            }
        }
        return repeatSectionQuestionStatus;
    }

    public addShowInListValue(value: { key: string; label: string; answer: string | string[]; type: string; }) {
        if (!(this.formlyService.getModel() as IAudit).show_in_list) {
            (this.formlyService.getModel() as IAudit).show_in_list = [];
        }
        const index = (this.formlyService.getModel() as IAudit).show_in_list.findIndex(v => v.key === value.key);
        if (index > -1) {
            // Update existing value
            (this.formlyService.getModel() as IAudit).show_in_list[index] = value;
        } else {
            // Add value
            (this.formlyService.getModel() as IAudit).show_in_list.push(value);
        }

        // Sort array by keys
        (this.formlyService.getModel() as IAudit).show_in_list.sort((a, b) => {
            const keyLength = a.key.length - b.key.length;
            const keyOrder = a.key.split('.value')[0].localeCompare(b.key.split('.value')[0]);

            return keyOrder || keyLength;
        });
    }

    public removeShowInListValue(key: string) {
        const index = (this.formlyService.getModel() as IAudit).show_in_list.findIndex(v => v.key === key);
        if (index > -1) {
            (this.formlyService.getModel() as IAudit).show_in_list.splice(index, 1);
        }
    }

    public setLocationAndTimestamp(key: string | number | string[]) {
        const path = (key as string).substring(0, (key as string).lastIndexOf('.'));
        const timeStamp = dayjs().toISOString();
        set(this.formlyService.getModel(), path + '.timestamp', timeStamp);
        set(this.formlyService.getModel(), 'lifecycle.last_updated_timestamp', timeStamp);
        const position = this.locationService.getPosition();
        set(this.formlyService.getModel(), path + '.location.latitude', position.latitude);
        set(this.formlyService.getModel(), path + '.location.longitude', position.longitude);
    }

    public unsetLocationAndTimestamp(key: string | number | string[]) {
        const path = (key as string).substring(0, (key as string).lastIndexOf('.'));
        unset(this.formlyService.getModel(), path + '.timestamp');
        unset(this.formlyService.getModel(), path + '.location');
    }

    private updateModel() {
        const sections = this.formlyService.getFormConfig().sections;
        let sectionIndex = 0;

        for (const section of sections) {
            this.checkFormConfigForRepeatSections(section);
            this.updateAuditSectionStatus(section, sectionIndex, section.repeat);
            this.formlyService.attachValidators(section);
            sectionIndex++;
        }
    }

    public setAnswersOnPrePopulateQuestions(assetScanQuestionKey: string, scannedAsset: IAsset) {
        const METHOD = 'setAnswersOnPrePopulateQuestions';
        const scannedAssetQuestions = scannedAsset.sections[0].questions;

        const setSectionAnswers = (section: IFormConfigSection) => {
            for (const question of section.questions) {
                for (const field of question.fields) {
                    if (field.props.prepopulate?.prepopulateFromAsset) {
                        const matchingAssetQuestion = scannedAsset.sections[0].questions.find(q => q.uuid === field.props.prepopulate.assetQuestionUuid);
                        if (matchingAssetQuestion && matchingAssetQuestion.answer) {
                            const key = field.key;

                            const setModelAnswer = (answer: any) => {
                                set(this.formlyService.getModel(), key, answer);
                                if (field.formControl) {
                                    field.formControl.setValue(answer);
                                }
                            };

                            if (field.type === 'ta-radio' && matchingAssetQuestion.type === 'ta-radio') {
                                const assetAnswerOption = matchingAssetQuestion.options.find((o: { key: string }) => o.key === matchingAssetQuestion.answer);
                                if (assetAnswerOption) {
                                    setModelAnswer(matchingAssetQuestion.answer);
                                }
                            } else {
                                setModelAnswer(matchingAssetQuestion.answer);
                            }

                        }
                    }
                }
            }
        };

        if (assetScanQuestionKey.includes('repeat_sections')) {
            const assetScanSectionId = assetScanQuestionKey.split('.')[1];
            const assetScanRepeatSectionId = assetScanQuestionKey.split('repeat_sections')[1].split('.')[1];

            for (const section of this.formlyService.getFormConfig().sections) {
                // assetScanQuestionKey - data.section_2.repeat_sections.section_0.question_0.findings.value
                if (section._id === assetScanSectionId) {
                    for (const repeatSection of section.repeat_sections) {
                        if (repeatSection._id === assetScanRepeatSectionId) {
                            setSectionAnswers(repeatSection);
                        }
                    }
                }
            }
        } else {
            for (const section of this.formlyService.getFormConfig().sections) {
                if (!section.repeat) {
                    setSectionAnswers(section);
                }
            }
        }

        let sectionIndex = 0;
        for (const section of this.formlyService.getFormConfig().sections) {
            this.updateAuditSectionStatus(section, sectionIndex, section.repeat);
            sectionIndex++;
        }
    }

    private getRepeatSectionDisplaytext(numberOfSectionItems: number, numberOfSectionItemRequired: number): string {
        if (numberOfSectionItems === 0) {
            return 'No items';
        } else if (numberOfSectionItemRequired === 0) {
            return 'All items are completed';
        } else {
            if (numberOfSectionItemRequired > 0 && numberOfSectionItemRequired < 2) {
                // If 1 item is remaining
                return numberOfSectionItemRequired + ' item is yet to be completed';
            } else {
                // If more than 1 items are remaining
                return numberOfSectionItemRequired + ' items are yet to be completed';
            }
        }
    }

    private getSectionDisplaytext(numberOfRequiredQuestionsUnanswered: number, numberOfOptionalQuestionsUnanswered: number, numberOfOptionalQuestionsLeftOut: number): string {
        if (numberOfRequiredQuestionsUnanswered > 0) {
            return numberOfRequiredQuestionsUnanswered + ' required question' + (numberOfRequiredQuestionsUnanswered > 1 ? 's' : '') + ' remaining';
        } else if (numberOfOptionalQuestionsUnanswered > 0 || numberOfOptionalQuestionsLeftOut > 0) {
            return 'All mandatory questions answered';
        } else {
            return 'All questions answered';
        }
    }

    private calculateAuditFormValidity(): boolean {
        for (const sectionStatus of this.formlyService.getStatus().sections) {
            if (sectionStatus.number_of_required_questions_unanswered > 0 || sectionStatus.number_of_section_items_required > 0) {
                return false;
            }
        }
        return true;
    }

    private getAuditSectionStatus(section: IFormConfigSection): IAuditSectionStatus {
        let numberOfRequiredQuestionsAnswered = 0;
        let numberOfRequiredQuestionsUnanswered = 0;
        let numberOfOptionalQuestionsAnswered = 0;
        let numberOfOptionalQuestionsUnanswered = 0;
        let numberOfOptionalQuestionsLeftOut = 0;

        const getQuestionAnswer = (field: IFormConfigField) => get(this.formlyService.getModel(), field.key, null);

        const updateQuestionAnsweredCount = (field: IFormConfigField,) => {
            const answer = getQuestionAnswer(field);
            let answeredCount = 0;
            let unansweredCount = 0;

            if (field.type === 'ta-number' || field.type === 'ta-decimal' || field.type === 'ta-fuel-gauge' || field.type === 'ta-oil-level-gauge' || field.type === 'ta-range') {
                if (!isNaN(parseFloat(answer))) {
                    answeredCount++;
                } else {
                    unansweredCount++;
                }
            } else if (field.type === 'ta-checkbox') {
                if (answer && Object.values(answer).some(v => v)) {
                    answeredCount++;
                } else {
                    unansweredCount++;
                }
            } else {
                if (answer) {
                    answeredCount++;
                } else {
                    unansweredCount++;
                }
            }
            return { answeredCount, unansweredCount };
        };

        for (const question of section.questions) {
            const questionsWithHideExpressions = question.fields.filter(nq => (nq.hideExpression));
            for (const field of question.fields) {
                let additionalFieldRequiredQuestionLeftOut = false;
                let numberOfAdditionalFieldRequiredQuestionLeftOut = 0;

                if (!field.hideExpression && field.type !== 'ta-hidden') {

                    const checkQuestionValidation = (questionField: IFormConfigField) => {
                        if (questionField.type === 'ta-repeat-question') {
                            const repeatQuestionAnswers = getQuestionAnswer(questionField);
                            const repeatQuestionValidator = questionField.ta_validators;

                            if (!repeatQuestionAnswers || repeatQuestionAnswers.length === 0) {
                                if (repeatQuestionValidator.required) {
                                    numberOfRequiredQuestionsUnanswered++;
                                }
                            } else {
                                let requiredRepeatQuestionAnswered = true;
                                let optionalRepeatQuestionAnswered = true;

                                for (const groupField of questionField.fieldArray.fieldGroup) {
                                    for (const repeatQuestionAnswer of repeatQuestionAnswers) {
                                        const groupFieldQuestionAnswer = get(repeatQuestionAnswer, groupField.key, null);
                                        const validators = groupField.ta_validators;

                                        if (!groupFieldQuestionAnswer && !isNaN(groupFieldQuestionAnswer)) {
                                            if (validators.required) {
                                                requiredRepeatQuestionAnswered = false;
                                                break;
                                            } else {
                                                optionalRepeatQuestionAnswered = false;
                                            }
                                        }
                                    }

                                    if (!requiredRepeatQuestionAnswered) {
                                        break;
                                    }
                                }

                                if (!requiredRepeatQuestionAnswered) {
                                    numberOfRequiredQuestionsUnanswered++;
                                } else if (!optionalRepeatQuestionAnswered) {
                                    numberOfOptionalQuestionsUnanswered++;
                                    numberOfRequiredQuestionsAnswered++;
                                } else {
                                    if (repeatQuestionValidator.required) {
                                        numberOfRequiredQuestionsAnswered++;
                                    } else {
                                        numberOfOptionalQuestionsAnswered++;
                                    }
                                }
                            }
                        } else {
                            const validators = questionField.ta_validators;
                            if (validators.required || validators.checkboxRequired) {
                                const { answeredCount, unansweredCount } = updateQuestionAnsweredCount(field);
                                numberOfRequiredQuestionsUnanswered += unansweredCount;
                                numberOfRequiredQuestionsAnswered += answeredCount;

                                if (additionalFieldRequiredQuestionLeftOut) {
                                    if (answeredCount > 0) {
                                        numberOfRequiredQuestionsAnswered--;
                                        numberOfRequiredQuestionsUnanswered++;
                                    }
                                }
                            } else {
                                const { answeredCount, unansweredCount } = updateQuestionAnsweredCount(field);
                                numberOfOptionalQuestionsUnanswered += unansweredCount;
                                numberOfOptionalQuestionsAnswered += answeredCount;

                                if (additionalFieldRequiredQuestionLeftOut) {
                                    if (answeredCount > 0) {
                                        numberOfOptionalQuestionsAnswered--;
                                    }
                                    numberOfRequiredQuestionsUnanswered++;
                                }
                            }
                        }
                    };

                    const checkPhotosAndCommentsQuestions = (questionField: IFormConfigField) => {
                        if (questionField.props.additionalFields) {
                            if (questionField.props.additionalFields.photos || questionField.props.additionalFields.attachPhotos) {
                                if (questionField.props.additionalFields.photosRequired) {
                                    const answer = get(this.formlyService.getModel(), questionField.key.replace('.value', '.photos'), null);
                                    if (!answer) {
                                        if (!additionalFieldRequiredQuestionLeftOut) {
                                            additionalFieldRequiredQuestionLeftOut = true;
                                            numberOfAdditionalFieldRequiredQuestionLeftOut++;
                                        }
                                    }
                                }
                            }
                            if (questionField.props.additionalFields.comments) {
                                if (questionField.props.additionalFields.commentsRequired) {
                                    const answer = get(this.formlyService.getModel(), questionField.key.replace('.value', '.comments'), null);
                                    if (!answer) {
                                        if (!additionalFieldRequiredQuestionLeftOut) {
                                            additionalFieldRequiredQuestionLeftOut = true;
                                            numberOfAdditionalFieldRequiredQuestionLeftOut++;
                                        }
                                    }
                                }
                            }
                        }
                    };

                    const checkNestedQuestionFields = (questionField: IFormConfigField, length: number) => {
                        const checkNestedQuestionField = (answer: any) => {
                            if (answer || ((questionField.type === 'ta-number' || questionField.type === 'ta-decimal' || questionField.type === 'ta-fuel-gauge' || questionField.type === 'ta-oil-level-gauge' || questionField.type === 'ta-range') && !isNaN(parseFloat(answer)))) {
                                const response = answer.replace('option_', 'response_');
                                const path = questionField.key.split('.value')[0];
                                const partialNestedKey = path + '.' + response + '.nested_question';
                                const nestedQuestionFields = questionsWithHideExpressions.filter(n => n.key.includes(partialNestedKey) && (n.key.match(/nested_question/g) || []).length === length);
                                if (nestedQuestionFields.length > 0) {
                                    for (const nestedQuestionField of nestedQuestionFields) {
                                        const validators = nestedQuestionField.ta_validators;
                                        const nestedQuestionAnswer = getQuestionAnswer(nestedQuestionField);
                                        additionalFieldRequiredQuestionLeftOut = false;
                                        checkPhotosAndCommentsQuestions(nestedQuestionField);
                                        if (validators.required || validators.checkboxRequired) {
                                            if (additionalFieldRequiredQuestionLeftOut && numberOfAdditionalFieldRequiredQuestionLeftOut === 1) {
                                                numberOfRequiredQuestionsAnswered--;
                                                numberOfRequiredQuestionsUnanswered++;
                                                break;
                                            } else {
                                                if (nestedQuestionAnswer || ((nestedQuestionField.type === 'ta-number' || nestedQuestionField.type === 'ta-decimal' || nestedQuestionField.type === 'ta-fuel-gauge' || nestedQuestionField.type === 'ta-oil-level-gauge' || nestedQuestionField.type === 'ta-range') && !isNaN(parseFloat(nestedQuestionAnswer)))) {
                                                    if (nestedQuestionField.type === 'ta-checkbox') {
                                                        if (!(Object.values(nestedQuestionAnswer).some(v => v))) {
                                                            numberOfRequiredQuestionsAnswered--;
                                                            numberOfRequiredQuestionsUnanswered++;
                                                            break;
                                                        }
                                                    } else if (nestedQuestionField.type === 'ta-number' || nestedQuestionField.type === 'ta-decimal' || nestedQuestionField.type === 'ta-fuel-gauge' || nestedQuestionField.type === 'ta-oil-level-gauge' || nestedQuestionField.type === 'ta-range') {
                                                        if (isNaN(parseFloat(nestedQuestionAnswer))) {
                                                            numberOfRequiredQuestionsAnswered--;
                                                            numberOfRequiredQuestionsUnanswered++;
                                                            break;
                                                        }
                                                    }
                                                } else {
                                                    numberOfRequiredQuestionsAnswered--;
                                                    numberOfRequiredQuestionsUnanswered++;
                                                    break;
                                                }
                                            }
                                        } else {
                                            if (additionalFieldRequiredQuestionLeftOut && numberOfAdditionalFieldRequiredQuestionLeftOut === 1) {
                                                numberOfOptionalQuestionsAnswered--;
                                                numberOfRequiredQuestionsUnanswered++;
                                                // break;
                                            } else {
                                                if (nestedQuestionAnswer || ((nestedQuestionField.type === 'ta-number' || nestedQuestionField.type === 'ta-decimal' || nestedQuestionField.type === 'ta-fuel-gauge' || nestedQuestionField.type === 'ta-oil-level-gauge' || nestedQuestionField.type === 'ta-range') && !isNaN(parseFloat(nestedQuestionAnswer)))) {
                                                    if (nestedQuestionField.type === 'ta-checkbox') {
                                                        if (!(Object.values(nestedQuestionAnswer).some(v => v))) {
                                                            numberOfOptionalQuestionsLeftOut++;
                                                        }
                                                    } else if (nestedQuestionField.type === 'ta-number' || nestedQuestionField.type === 'ta-decimal' || nestedQuestionField.type === 'ta-fuel-gauge' || nestedQuestionField.type === 'ta-oil-level-gauge' || nestedQuestionField.type === 'ta-range') {
                                                        if (isNaN(parseFloat(nestedQuestionAnswer))) {
                                                            numberOfRequiredQuestionsAnswered--;
                                                            numberOfRequiredQuestionsUnanswered++;
                                                        }
                                                    }
                                                } else {
                                                    numberOfOptionalQuestionsLeftOut++;
                                                }
                                            }
                                        }
                                        checkNestedQuestionFields(nestedQuestionField, length + 1);
                                    }
                                }
                            }
                        };
                        const questionAnswer = getQuestionAnswer(questionField);
                        if (questionField.type === 'ta-radio') {
                            checkNestedQuestionField(questionAnswer);
                        } else if (questionField.type === 'ta-checkbox') {
                            if (questionAnswer) {
                                const checkboxAnswers = Object.keys(questionAnswer).filter(v => questionAnswer[v]);
                                for (const checkboxAnswer of checkboxAnswers) {
                                    checkNestedQuestionField(checkboxAnswer);
                                }
                            }
                        }
                    };

                    checkPhotosAndCommentsQuestions(field);
                    checkQuestionValidation(field);

                    if (questionsWithHideExpressions.length > 0) {
                        checkNestedQuestionFields(field, 1);
                    }

                }
            }
        }

        return {
            total_questions: section.questions.length,
            number_of_questions_answered: numberOfOptionalQuestionsAnswered + numberOfRequiredQuestionsAnswered,
            number_of_required_questions_unanswered: numberOfRequiredQuestionsUnanswered,
            number_of_optional_questions_unanswered: numberOfOptionalQuestionsUnanswered,
            number_of_optional_questions_left_out: numberOfOptionalQuestionsLeftOut,
            display_text: this.getSectionDisplaytext(numberOfRequiredQuestionsUnanswered, numberOfOptionalQuestionsUnanswered, numberOfOptionalQuestionsLeftOut),
            valid: numberOfRequiredQuestionsUnanswered === 0
        };
    }

    private checkFormConfigForRepeatSections(section: IFormConfigSection) {
        if (section.repeat) {
            section.repeat_sections = [];
            if (this.formlyService.getModel().data[section._id]) {
                /*
                 * The data model does not store the keys in any given order. We know that when adding new repeat
                 * section items generate a new section id by adding 1 to the numerical part of last section id.
                 *
                 * For example, if the last section in repeat sections has an _id of section_14 then the next repeat
                 * section id will be section_15.
                 *
                 * This means that within the repeat sections of the form_config the _id's are always numerically sorted
                 * in ascending order. We can use this numerical information to ensure that we push the correct model data
                 * for the key into the right slot in the repeat_sections array.
                 *
                 * Create the sorted list of keys and use this sorted list to push the form_config repeat_sections
                 */
                const keys: string[] = [];
                const repeatSections = (this.formlyService.getModel().data[section._id].repeat_sections as IFormConfigSection[]);
                for (const key in repeatSections) {
                    if (repeatSections.hasOwnProperty(key)) {
                        keys.push(key);
                    }
                }

                const sortedKeys = keys.sort((a, b) => {
                    const aItemNumber = Number(a.substring(a.lastIndexOf('_') + 1));
                    const bItemNumber = Number(b.substring(b.lastIndexOf('_') + 1));
                    if (bItemNumber > aItemNumber) {
                        return -1;
                    } else if (bItemNumber === aItemNumber) {
                        return 0;
                    } else {
                        return 1;
                    }
                });

                for (const key of sortedKeys) {
                    const repeatSection = this.generateRepeatSectionItem(section, key);
                    section.repeat_sections.push(repeatSection);
                }
            }
        }
    }

    private async createAudit(formConfig: IFormConfig) {
        const METHOD = 'createAudit';
        try {
            const uuid = v4().replace(/-/g, '');
            const auditId = 'audit::' + uuid + '::v1';
            const { longitude, latitude } = this.locationService.getPosition();

            const auditDocument: IAudit = {
                data: {},
                api: formConfig.api,
                audit_type: formConfig.audit_type,
                assets: [],
                channels: [],
                client_id: formConfig.client_id,
                description: formConfig.description,
                failures: {
                    total: 0,
                    category_a_count: 0,
                    category_b_count: 0,
                    category_c_count: 0
                },
                form_config_id: formConfig.id,
                id: auditId,
                images: [],
                lifecycle: {
                    started_position: {
                        longitude,
                        latitude
                    },
                    started_timestamp: dayjs().toISOString(),
                    last_updated_timestamp: dayjs().toISOString(),
                    status: 'incomplete'
                },
                intl: {
                    locale: navigator.language,
                    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
                },
                show_in_list: [],
                signatures: [],
                template_id: formConfig.template_id,
                title: formConfig.title,
                type: 'audit',
                user_id: 'user::123456',
                user_name: '123456',
                uuid,
                version: 1
            };

            if (formConfig.sign_off) {
                auditDocument.sign_off = formConfig.sign_off;
            }

            /*
             * Create options defaults for checkbox question types, this enables the checkbox to
             * be a subquestion of radio and pass-fail
             */
            const dataModel = {
                data: {}
            };

            for (const formConfigSection of formConfig.sections) {
                for (const formConfigQuestion of formConfigSection.questions) {
                    if (formConfigQuestion.fields) {
                        for (const field of formConfigQuestion.fields) {
                            /*
                             * We need to set the default value of the checkbox to false to ensure the hide expression works
                             */
                            const key = field.key;
                            const path = key.substring(0, key.lastIndexOf('.')) + '.value';
                            if (field.props.options) {
                                if (field.type === 'ta-checkbox') {
                                    let index = 0;
                                    const value = {};
                                    for (const option of field.props.options) {
                                        set(value, option.key, false);
                                        index = index + 1;
                                    }
                                    set(dataModel, path, value);
                                }

                                if (field.type === 'ta-radio' || field.type === 'ta-pass-fail' || field.type === 'ta-pass-fail-na') {
                                    set(dataModel, path, null);
                                }
                            }
                        }
                    }
                }
                if (formConfigSection.repeat) {
                    formConfigSection.repeat_sections = [];
                }
            }
            auditDocument.data = dataModel.data;
            return auditDocument;
        } catch (error) {
            console.error(TAG, METHOD, 'error=', error);
            throw error;
        }
    }

    private refactorFormConfig(formConfig: IFormConfig) {
        /*
         * API v1 or API v1.1 or API 1.2
         *
         * The hideExpression entries within the formConfig are currently
         * generated with the full model path -
         *
         *  "hideExpression": "formState.model.data.section_0.question_1.findings.value.option_1 !== true"
         *
         * This causes errors with latest formly as the model has not been fully instantiated
         * at the time the hideExpression is first parsed. Need to modify all hideExpression
         * entries to ensure that they are optional -
         *
         * "hideExpression": "model.data?.section_0?.question_1?.findings?.value?.option_1 !== true"
         *
         */
        if (formConfig.api === 'v1' || formConfig.api === 'v1.1' || formConfig.api === 'v1.2') {
            for (const section of formConfig.sections) {
                for (const question of section.questions) {
                    for (const field of question.fields) {
                        if (field.type === 'ta-repeat-question') {
                            if (field.fieldArray && field.fieldArray.fieldGroup) {
                                for (const repeatField of field.fieldArray.fieldGroup) {
                                    if (repeatField.hideExpression) {
                                        const newHideExpression = repeatField.hideExpression.replace(/formState\./g, '').replace(/\./g, '\?.');
                                        repeatField.hideExpression = newHideExpression;
                                    }
                                }
                            }
                        } else {
                            if (field.hideExpression) {
                                const newHideExpression = field.hideExpression.replace(/formState\./g, '').replace(/\./g, '\?.');
                                field.hideExpression = newHideExpression;
                            }
                        }

                        if (field.templateOptions) {
                            if (field.templateOptions.options) {
                                field.templateOptions.labelProp = "value";
                                field.templateOptions.valueProp = "key";
                            }

                            field.props = { ...field.templateOptions };
                            delete field.templateOptions;
                        }
                    }
                }
            }
        }
        return formConfig;
    }

}
