import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms/';

import { FieldType } from '@ngx-formly/core';

import { AuditService } from '@services/audit.service';
import { ConfigService } from '@services/config.service';

import { IScannedAsset } from '@models/types';

import { get, set } from 'lodash-es';

import * as dayjs from 'dayjs';
import * as customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

const TAG = 'TaAssetSelectComponent';

/**
 * Formly-compatible asset select component
 */
@Component({
    selector: 'app-ta-asset-select',
    templateUrl: 'ta-asset-select.html',
    styleUrls: ['./ta-asset-select.scss'],
})
export class TaAssetSelectComponent extends FieldType implements OnInit {

    public assets: IScannedAsset[] = [];

    public dayjs = dayjs;
    public updatedKey: string;

    constructor(
        private auditService: AuditService,
        private changeDetectorRef: ChangeDetectorRef,
        private configService: ConfigService,
    ) {
        super();
    }

    ngOnInit() {
        const METHOD = 'ngOnInit';

        this.updatedKey = this.key as string;
        if (this.field.parent?.key && this.field.parent?.parent.key) {
            this.updatedKey = this.field.parent?.parent.key + '[' + this.field.parent?.key + '].' + this.key;
        }

        // Get existing assets tag value if it exists
        const assets = get(this.model, this.key, []);
        if (assets) {
            this.assets = assets;
        }
    }

    public async saveAssets(assets: IScannedAsset[]) {
        set(this.model, this.key, assets);
        const formControl = this.formControl as FormControl;
        /* If a assets array has been given, set the formControl value to the array
          else, set the formControl value to null  */

        if (assets) {
            formControl.setValue(assets);
            if (this.props.showInList) {
                const assetsValues = assets.map(asset => asset.value);
                const value = { key: this.updatedKey, label: this.props.label, answer: assetsValues, type: this.field.type as string };
                this.auditService.addShowInListValue(value);
            }

            this.auditService.setLocationAndTimestamp(this.updatedKey);
            if (this.model.audit_type === 'Register Item Checklist' ||
                this.model.audit_type === 'Asset Inspection') {
                if (this.updatedKey === 'data.section_0.question_0.findings.value') {
                    this.model.asset_id = assets[0].asset_id;
                    this.model.asset_identifier = assets[0].asset_identifier;
                }
            }
        } else {
            formControl.reset(null);

            if (this.props.showInList) {
                this.auditService.removeShowInListValue(this.updatedKey as string);
            }

            this.auditService.unsetLocationAndTimestamp(this.updatedKey);
            if (this.model.audit_type === 'Register Item Checklist' ||
                this.model.audit_type === 'Asset Inspection') {
                if (this.updatedKey === 'data.section_0.question_0.findings.value') {
                    this.model.asset_id = null;
                    this.model.asset_identifier = null;
                }
            }
        }
    }
}
