import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';

const TAG = 'AppComponent';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent {

    constructor(
        private platform: Platform
    ) {
        this.initializeApp();
    }

    private async initializeApp() {
        await this.platform.ready();
    }
}
