<ion-card class="question-card">

    <ion-card-header>
        <ion-card-title class="ion-label-font-md ion-text-wrap ion-font-weight-medium" *ngIf="to.label">
            {{ to.label }}
            <ion-text *ngIf="field.ta_validators?.required || field.ta_validators?.checkboxRequired" color="danger">*
            </ion-text>
        </ion-card-title>
        <ion-card-subtitle class="ion-margin-top-1" mode="md" color="secondary"
            *ngIf="field.props.prepopulate?.prepopulateFromAsset || field.props.prepopulate?.updateAssetFromAudit">
            {{field.props.prepopulate.prepopulateFromAsset && field.props.prepopulate.updateAssetFromAudit ?
            'Pre-populate from and update ' : field.props.prepopulate.prepopulateFromAsset &&
            !field.props.prepopulate.updateAssetFromAudit ? 'Pre-populate from ': 'Update ' }}
            "{{field.props.prepopulate.assetType}}" asset
        </ion-card-subtitle>
        <ion-card-subtitle class="ion-margin-top-1" mode="md" color="secondary"
            *ngIf="field.props.autoIncrement?.increment">
            Auto-incremented by {{field.props.autoIncrement.value}} {{field.props.autoIncrement.unit}}
        </ion-card-subtitle>
        <app-question-guidance [props]="$any(props)"></app-question-guidance>
    </ion-card-header>

    <ng-template #fieldComponent></ng-template>

    <app-question-additional-fields *ngIf="to.additionalFields" [model]="model" [key]="$any(key)" [props]="$any(props)">
    </app-question-additional-fields>

</ion-card>