import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';

import { ModalController } from '@ionic/angular';

import { ImageViewerModalPage } from '@modals/image-viewer-modal/image-viewer-modal.page';
import { IGuidanceImage, ITaFormlyGuidance, ITaFormlyTemplateOptions } from '@models/types';

@Component({
    selector: 'app-question-guidance',
    templateUrl: 'question-guidance.html',
    styleUrls: ['question-guidance.scss']
})
export class QuestionGuidanceComponent implements OnInit {

    @Input() props: ITaFormlyTemplateOptions;
    public guidance: ITaFormlyGuidance = { text: '', images: [] };

    constructor(
        public httpClient: HttpClient,
        public modalCtrl: ModalController
    ) { }

    ngOnInit() {
        this.guidance = this.props.guidance;
    }

    public async openViewImageModal(guidanceImages: IGuidanceImage[], index: number) {
        const imageViewerModal = await this.modalCtrl.create({
            component: ImageViewerModalPage,
            componentProps: {
                images: guidanceImages.map(guidanceImage => guidanceImage.imageThumbnail),
                imageIndex: index
            }
        });
        await imageViewerModal.present();
    }

}
