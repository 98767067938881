<ion-card-content class="ion-padding-horizontal-2 ion-padding-vertical-0">
    <ion-item lines="none" *ngIf="eSignature">
        <ion-grid>
            <ion-row>
                <ion-col class="signature-wrapper ion-text-center">
                    <img [src]="eSignature">
                </ion-col>
            </ion-row>
        </ion-grid>
    </ion-item>

    <ion-grid>
        <ion-row>
            <ion-col size-sm="6" offset-sm="3" size-xs="12">
                <ion-button tabIndex="1" class="ion-label-font-sm" color="primary" expand="block"
                    *ngIf="!formControl.value" (click)="addESignature()">
                    Add E-Signature
                    <ion-icon slot="end" name="pencil-outline"></ion-icon>
                </ion-button>
                <ion-button tabIndex="1" class="ion-label-font-sm" color="danger" expand="block"
                    *ngIf="formControl.value" (click)="removeESignature()">
                    Remove E-Signature
                    <ion-icon slot="end" name="trash-outline"></ion-icon>
                </ion-button>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-card-content>