import { Injectable } from '@angular/core';
import { ValidatorFn, Validators } from '@angular/forms';

import { TaCheckboxValidator } from '@validators/ta-checkbox-validator';

import { set } from 'lodash-es';

import { Subject } from 'rxjs';

import { IAsset, IAssetFormConfig, IAudit, IAuditStatus, IFormConfig, IFormConfigField, IFormConfigQuestion, IFormConfigSection } from '@models/types';

@Injectable()
export class FormlyService {

    /*
   * Event used to inform stepper that a different question has been chosen in
   * the QuestionInformationPopover
   */
    public questionIndexChanged: Subject<number>;

    private model: IAudit | IAsset | null;
    private formConfig: IFormConfig | IAssetFormConfig | null;
    private status: IAuditStatus = {
        form_valid: false,
        sections: []
    };

    /*
    * Counters to track exactly which field of which question of which section
    * is currently active
    */
    private sectionIndex = 0;
    private stepperIndex = 0;
    private isRepeatSection = false;
    private repeatSectionIndex = 0;

    constructor() {
        this.questionIndexChanged = new Subject();
    }

    public reset() {
        this.model = null;
        this.formConfig = null;
        this.status = {
            form_valid: false,
            sections: []
        };

        this.sectionIndex = 0;
        this.stepperIndex = 0;
        this.isRepeatSection = false;
        this.repeatSectionIndex = 0;
    }

    public getFormConfig() {
        return this.formConfig;
    }

    public setFormConfig(formConfig: IFormConfig | IAssetFormConfig | null) {
        this.formConfig = formConfig;
    }

    public getModel() {
        return this.model;
    }

    public setModel(model: IAudit | IAsset | null) {
        this.model = model;
    }

    public setSectionIndex(sectionIndex: number) {
        this.sectionIndex = sectionIndex;
    }

    public getSectionIndex() {
        return this.sectionIndex;
    }

    public setStepperIndex(stepperIndex: number) {
        this.stepperIndex = stepperIndex;
    }

    public getStepperIndex() {
        return this.stepperIndex;
    }

    public setIsRepeatSection(isRepeatSection: boolean) {
        this.isRepeatSection = isRepeatSection;
    }

    public getIsRepeatSection() {
        return this.isRepeatSection;
    }

    public setRepeatSectionIndex(repeatSectionIndex: number) {
        this.repeatSectionIndex = repeatSectionIndex;
    }

    public getRepeatSectionIndex() {
        return this.repeatSectionIndex;
    }

    public getStatus(): IAuditStatus {
        return this.status;
    }

    public attachValidators(section: IFormConfigSection) {
        if (section.repeat && section.repeat_sections) {
            // For each repeat section in the section
            for (const repeatSection of section.repeat_sections) {
                this.setQuestionValidators(repeatSection);
            }
        } else {
            // For each question in the section
            this.setQuestionValidators(section);
        }
    }

    public setQuestionValidators(section: IFormConfigSection) {
        const METHOD = 'setQuestionValidators';
        for (const question of section.questions) {
            // For each field in the question
            if (question.fields) {
                for (const field of question.fields) {
                    const addValidators = (currentField: IFormConfigField) => {
                        if (currentField.ta_validators) {
                            // validators array
                            const validators: ValidatorFn[] = [];
                            // For each group in the question
                            if (currentField.ta_validators.checkboxRequired) {
                                validators.push(TaCheckboxValidator.validate);
                            }
                            if (currentField.ta_validators.email) {
                                validators.push(Validators.email);
                            }
                            if (currentField.ta_validators.maxLength) {
                                const maxLengthValue = currentField.ta_validators.maxLength;
                                validators.push(Validators.maxLength(maxLengthValue));
                            }
                            if (currentField.ta_validators.minLength) {
                                const minLengthValue = currentField.ta_validators.minLength;
                                validators.push(Validators.minLength(minLengthValue));
                            }
                            if (currentField.ta_validators.pattern) {
                                const patternValue = currentField.ta_validators.pattern;
                                validators.push(Validators.pattern(patternValue));
                            }
                            if (currentField.ta_validators.required) {
                                validators.push(Validators.required);
                            }
                            if (currentField.ta_validators.requiredTrue) {
                                validators.push(Validators.requiredTrue);
                            }
                            if (validators.length > 0) {
                                set(currentField, 'validators.validation', validators);
                            }
                        }
                    };

                    if (field.type === 'ta-repeat-question') {
                        for (const arrayField of field.fieldArray.fieldGroup) {
                            addValidators(arrayField);
                        }
                    } else {
                        addValidators(field);
                    }
                }
            }
        }
    }

    public setQuestionCompletionStatuses(section: IFormConfigSection): { completionStatuses: boolean[]; requiredStatuses: boolean[] } {
        const completionStatuses: boolean[] = [];
        const requiredStatuses: boolean[] = [];

        for (const question of section.questions) {
            const { questionAnswered, questionRequired } = this.checkQuestionsValidity(question);
            completionStatuses.push(questionAnswered);
            requiredStatuses.push(questionRequired);
        }

        return { completionStatuses, requiredStatuses };
    }

    public checkQuestionsValidity(question: IFormConfigQuestion) {
        let questionAnswered = true;
        let questionRequired = false;

        const isQuestionAnswered = (field: IFormConfigField) => {
            const answer = field.formControl.value;
            /*
             * An answer of 0 is equivalent to false in the if statement, for numerical
             * inputs false would mean interpreting 0 as invalid. We test using typeof
             * any valid numberical value will return 'number' ... for a ta-number input
             * an empty value returns 'object'
             */
            if (field.type === 'ta-checkbox') {
                if (answer) {
                    if ((Object.values(answer).some(v => v))) {
                        return true;
                    }
                }
            } else if (field.type === 'ta-number' || field.type === 'ta-decimal' || field.type === 'ta-fuel-gauge' || field.type === 'ta-oil-level-gauge' || field.type === 'ta-range') {
                if (!isNaN(parseFloat(answer))) {
                    return true;
                }
            } else {
                if (answer) {
                    return true;
                }
            }
            return false;
        };

        // We have access to 'field.hide' variable as this has been added in by ngx formly when the form was instantiated.
        for (const field of question.fields) {
            if (field.type === 'ta-repeat-question') {
                if (field.fieldGroup?.length === 0) {
                    if (field.ta_validators.required) {
                        questionRequired = true;
                    }
                    questionAnswered = false;
                }
                if (field.fieldGroup && field.fieldGroup.length > 0) {
                    for (const repeatQuestionGroup of field.fieldGroup) {
                        for (const repeatQuestionField of repeatQuestionGroup.fieldGroup) {
                            if ((repeatQuestionField.hideExpression && !repeatQuestionField.hide) || !repeatQuestionField.hideExpression) {
                                if (!questionRequired) {
                                    if (repeatQuestionField.ta_validators.required || repeatQuestionField.ta_validators.checkboxRequired) {
                                        if (!isQuestionAnswered(repeatQuestionField)) {
                                            questionRequired = true;
                                        }
                                    }
                                }

                                if (repeatQuestionField.type !== 'ta-hidden') {
                                    if (questionAnswered) {
                                        questionAnswered = isQuestionAnswered(repeatQuestionField);
                                    }
                                } else {
                                    if (questionAnswered) {
                                        if (repeatQuestionField.ta_validators.required) {
                                            questionAnswered = isQuestionAnswered(repeatQuestionField);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            } else {
                if ((field.hideExpression && !field.hide) || !field.hideExpression) {
                    if (!questionRequired) {
                        if (field.ta_validators.required || field.ta_validators.checkboxRequired) {
                            if (!isQuestionAnswered(field)) {
                                questionRequired = true;
                            }
                        }
                    }

                    if (field.type !== 'ta-hidden') {
                        if (questionAnswered) {
                            questionAnswered = isQuestionAnswered(field);
                        }
                    } else {
                        if (questionAnswered) {
                            if (field.ta_validators.required) {
                                questionAnswered = isQuestionAnswered(field);
                            }
                        }
                    }
                }
            }
        }
        return { questionRequired, questionAnswered };
    }
}
