import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-list-skeleton',
    templateUrl: './list-skeleton.page.html',
    styleUrls: ['./list-skeleton.page.scss'],
})
export class ListSkeletonPage implements OnInit {

    @Input() type?: 'menu' | 'header';
    @Input() numberOfItems: number = 6;
    @Input() delay?: number = 750;

    public listItems: number[];
    public show = false;
    private timer: any;

    constructor() { }

    ngOnInit() {
        this.listItems = Array(this.numberOfItems).fill(0);

        this.timer = setTimeout(() => {
            this.show = true;
        }, this.delay);
    }

    ngOnDestroy() {
        if (this.timer) {
            clearTimeout(this.timer);
        }
    }

}
