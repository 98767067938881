<ion-header>
    <ion-toolbar>
        <ion-title>Select {{multi ? 'Projects' : 'Project'}}</ion-title>
        <ion-label class="ion-margin-right-2" slot="end" (click)="closeModal()">Close</ion-label>
    </ion-toolbar>
</ion-header>

<ion-content>
    <ng-container *ngIf="!isDataLoaded">
        <app-list-skeleton [type]="'menu'"></app-list-skeleton>
    </ng-container>

    <ion-item-group [formGroup]="projectsFormGroup">
        <ion-item-divider class="select-searchbar" sticky="true">
            <ion-item lines="full">
                <ion-searchbar enterkeyhint="search" [debounce]="1000" (ionClear)="clearSearch($any($event.target))"
                    (ionChange)="searchbarChange($any($event.target))">
                </ion-searchbar>
                <ion-spinner slot="end" [class.searching]="isSearching"></ion-spinner>
            </ion-item>
        </ion-item-divider>
        <ng-container *ngIf="isDataLoaded">
            <ng-container *ngIf="projects?.length > 0">
                <ion-list class="ion-padding-top-0">
                    <ion-item-sliding #slidingItem
                        *ngFor="let project of projects | projectSelect : searchQuery; let i = index;">
                        <ion-item lines="full" class="project-item">
                            <ion-checkbox class="ion-margin-right-2" mode="md" [formControlName]="project.id"
                                (ionChange)="selectProject()">
                            </ion-checkbox>
                            <app-asset class="project-container" [asset]="project"
                                [latestAssetFormConfigId]="project.asset_form_config_id">
                            </app-asset>
                        </ion-item>
                    </ion-item-sliding>
                </ion-list>
                <ion-infinite-scroll threshold="15%" (ionInfinite)="loadMoreData($event)">
                    <ion-infinite-scroll-content loadingSpinner="bubbles" loadingText="Loading more data...">
                    </ion-infinite-scroll-content>
                </ion-infinite-scroll>
            </ng-container>
            <ng-container *ngIf="projects?.length === 0">
                <ion-label
                    class="ion-text-center ion-margin-top-3 ion-label-font-md ion-font-weight-medium ion-text-wrap ion-margin-horizontal-3">
                    No results
                </ion-label>
            </ng-container>
        </ng-container>
    </ion-item-group>

</ion-content>

<ion-footer>
    <ion-toolbar class="ion-no-padding">
        <ion-grid>
            <ion-row>
                <ion-col size-sm="6" offset-sm="3" size-xs="12">
                    <ion-button class="ion-label-font-md ion-no-margin" color="primary" expand="block"
                        [disabled]="selectedProjects.length === 0" (click)="addProjects()">
                        Add {{selectedProjects?.length > 0 ? '(' + selectedProjects.length + ')': ''}}
                    </ion-button>
                </ion-col>
            </ion-row>
        </ion-grid>
    </ion-toolbar>
</ion-footer>