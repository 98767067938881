import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { PinchZoomModule } from '@meddv/ngx-pinch-zoom';

import { IonicModule } from '@ionic/angular';

import { ImageViewerModalPage } from './image-viewer-modal.page';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        PinchZoomModule,
    ],
    declarations: [ImageViewerModalPage]
})
export class ImageViewerModalPageModule { }
