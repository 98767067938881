import { Injectable } from '@angular/core';

import { Geolocation, PositionOptions } from '@capacitor/geolocation';

import { ILocationPosition } from '@models/types';

@Injectable({
    providedIn: 'root'
})
export class LocationService {

    private watchSubscription: string;

    private position: ILocationPosition = {
        latitude: null,
        longitude: null
    };

    constructor() { }

    public getPosition() {
        return this.position;
    }

    public async startWatching() {
        this.stopWatching();
        const options: PositionOptions = {
            enableHighAccuracy: true,
            timeout: 3000,
            maximumAge: 1000
        };

        this.position = {
            latitude: 0,
            longitude: 0
        };

        this.watchSubscription = await Geolocation.watchPosition(options, (position) => {
            if (position && position.coords !== undefined) {
                this.position = {
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude
                };
            }
        });
    }

    public async stopWatching() {
        if (this.watchSubscription) {
            await Geolocation.clearWatch({ id: this.watchSubscription })
        }
    }
}
