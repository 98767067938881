<mat-horizontal-stepper [@.disabled]="true">
    <mat-step *ngFor="let step of field.fieldGroup; let index = index; let last = last;">
        <formly-field [field]="step"></formly-field>
    </mat-step>
</mat-horizontal-stepper>

<div class="controls-wrapper">
    <ion-toolbar>
        <ion-grid>
            <ion-row>
                <ion-col>
                    <ion-button tabIndex="1" expand="block" color="light" fill="solid" (click)="prevStep()"
                        [disabled]="formlyService.getStepperIndex() === 0">
                        Previous
                    </ion-button>
                </ion-col>
                <ion-col>
                    <ion-button tabIndex="1" expand="block" [color]="buttonColor" fill="outline" (click)="nextStep()">
                        {{formlyService.getStepperIndex() === stepper?._steps?.length - 1 ? 'Done' : 'Next'}}
                    </ion-button>
                </ion-col>
            </ion-row>
        </ion-grid>
    </ion-toolbar>
</div>