<ion-item lines='none'>
    <ion-button tabIndex="1" class="ion-margin-right-0" slot="end" color="secondary" fill="clear" (click)="clear()"
        [disabled]="!formControl.value">
        Clear
        <ion-icon slot="end" name="close-circle-outline" class="ion-label-font-xlg"></ion-icon>
    </ion-button>
</ion-item>

<ion-radio-group [formControl]="$any(formControl)" [taFormlyAttributes]="field">
    <ion-item lines="none" *ngFor="let option of $any(props.options); let i = index;">
        <ion-label class="ion-text-wrap ion-label-font-sm">{{ option.value }}</ion-label>
        <ion-radio mode="md" slot="end" [color]="i === 0 ? 'success' : i === 1 ? 'danger' : 'medium'"
            [value]="option.key" [ariaLabel]="option.value" legacy="true">
        </ion-radio>
    </ion-item>
</ion-radio-group>