<ion-item lines='none'>
    <ion-button tabIndex="1" class="ion-margin-right-0" slot="end" color="secondary" fill="clear" (click)="clear()"
        [disabled]="!formControl.value">
        Clear
        <ion-icon slot="end" name="close-circle-outline" class="ion-label-font-xlg"></ion-icon>
    </ion-button>
</ion-item>

<ion-item lines="none">
    <ion-textarea style="-webkit-user-select: text; -moz-user-select: text; -ms-user-select: text; user-select: text;"
        class="ion-label-font-sm" autocapitalize="true" autocomplete="false" autocorrect="true" spellcheck="true"
        [formControl]="$any(formControl)" [taFormlyAttributes]="field"
        [placeholder]="props.placeholder || 'Input answer here ...'" [attr.rows]="props.rows || 4"></ion-textarea>
</ion-item>