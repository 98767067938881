
import { Component, HostListener, OnInit } from '@angular/core';

import { IonRange, Platform } from '@ionic/angular';

import { FieldType } from '@ngx-formly/core';

import { AuditService } from '@services/audit.service';

const TAG = 'TaFuelGaugeComponent';

@Component({
    selector: 'app-ta-fuel-gauge',
    templateUrl: 'ta-fuel-gauge.html',
    styleUrls: ['ta-fuel-gauge.scss']
})
export class TaFuelGaugeComponent extends FieldType implements OnInit {

    public debounceTime = 10;
    public transform = 'rotate(141, 182.43, 182.72)';
    public rangeValue = null;

    public disableClear = true;
    private updatedKey: string;

    constructor(
        private platform: Platform,
        private auditService: AuditService
    ) {
        super();
    }

    @HostListener('ionBlur')
    public ionBlur() {
        if (!isNaN(parseFloat(this.formControl.value))) {
            if (this.props.showInList) {
                const value = { key: this.updatedKey as string, label: this.props.label, answer: this.formControl.value, type: this.field.type as string };
                this.auditService.addShowInListValue(value);
            }
        } else {
            if (this.props.showInList) {
                this.auditService.removeShowInListValue(this.updatedKey as string);
            }
        }
    }

    ngOnInit() {
        this.updatedKey = this.key as string;
        if (this.field.parent?.key && this.field.parent?.parent.key) {
            this.updatedKey = this.field.parent?.parent.key + '[' + this.field.parent?.key + '].' + this.key;
        }

        if (this.platform.is('ios')) {
            this.debounceTime = 30;
        }
        const rangeValue = this.formControl.value;
        if (rangeValue) {
            const percentage = (rangeValue - this.props.min) / (this.props.max - this.props.min);
            const pointerDegrees = Math.round(141 + (258 * percentage));
            this.transform = 'rotate(' + pointerDegrees + ', 182.43, 182.72)';
        }
    }

    public onIonInput(event: any) {
        this.fuelGaugeChanges(event.detail.value);
    }

    public clear() {
        this.transform = 'rotate(141, 182.43, 182.72)';
        // The formControl.reset triggers an ionChange event with a null formControl.value
        this.formControl.reset(null);
        this.rangeValue = null;
    }

    public pinFormatter(value: number) {
        return `${value}%`;
    }

    private fuelGaugeChanges(value: string) {
        if (!isNaN(parseFloat(value))) {
            this.rangeValue = value;
            const percentage = (this.rangeValue - this.props.min) / (this.props.max - this.props.min);
            const pointerDegrees = Math.round(141 + (258 * percentage));
            this.transform = 'rotate(' + pointerDegrees + ', 182.43, 182.72)';
            this.disableClear = false;
            this.auditService.setLocationAndTimestamp(this.updatedKey);
        } else {
            this.disableClear = true;
            this.auditService.unsetLocationAndTimestamp(this.updatedKey);
        }
    }

}
