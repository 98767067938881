import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { FormlyModule } from '@ngx-formly/core';

import { TaFormlyFieldWrapperModule } from '@ta-formly/ta-field-wrapper/ta-field-wrapper.module';
import { TaInputComponent } from './ta-input.type';

@NgModule({
    declarations: [TaInputComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        IonicModule,
        TaFormlyFieldWrapperModule,
        FormlyModule.forChild({
            types: [
                {
                    name: 'ta-input',
                    component: TaInputComponent,
                    wrappers: ['ta-field-wrapper']
                },
                {
                    name: 'ta-number',
                    extends: 'ta-input',
                    defaultOptions: {
                        templateOptions: {
                            type: 'number',
                            inputmode: 'numeric'
                        }
                    }
                },
                {
                    name: 'ta-decimal',
                    extends: 'ta-input',
                    defaultOptions: {
                        templateOptions: {
                            type: 'number',
                            inputmode: 'decimal'
                        }
                    }
                }
            ]
        })
    ]
})
export class TaFormlyInputModule { }
