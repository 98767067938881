import { Component, OnInit, Optional } from '@angular/core';

import { IonNav, ModalController } from '@ionic/angular';

import { ConfigService } from '@services/config.service';
import { AssetInformationModalService } from '../asset-information-modal.service';

import * as dayjs from 'dayjs';
import * as customParseFormat from 'dayjs/plugin/customParseFormat';
import * as isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

dayjs.extend(customParseFormat);
dayjs.extend(isSameOrBefore);

@Component({
    selector: 'app-asset-information-content-modal',
    templateUrl: './asset-information-content-modal.page.html',
    styleUrls: ['./asset-information-content-modal.page.scss'],
})
export class AssetInformationContentModalPage implements OnInit {

    public canGoBack = false;
    public dayjs = dayjs;

    public assetAlreadyInList = false;

    constructor(
        public assetInformationModalService: AssetInformationModalService,
        public configService: ConfigService,
        private modalController: ModalController,
        @Optional() private nav: IonNav,
    ) { }

    ngOnInit() {
        if (this.assetInformationModalService.selectedAssets && this.assetInformationModalService.selectedAssets?.length > 0) {
            if (this.assetInformationModalService.asset) {
                if (this.assetInformationModalService.selectedAssets.includes(this.assetInformationModalService.asset.identifiers.asset_identifier)) {
                    this.assetAlreadyInList = true;
                }
            }
        }
    }

    ionViewWillEnter() {
        if (this.canGoBack && this.nav) {
            this.nav.swipeGesture = false;
        }
    }

    public async closeModal() {
        await this.modalController.dismiss();
    }

    public async openUri(url: string) {
        window.location.href = url;
    }

}
