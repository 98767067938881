import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { AssetInformationContentModalPage } from './asset-information-content-modal.page';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        IonicModule,
    ],
    declarations: [AssetInformationContentModalPage],
    exports: [AssetInformationContentModalPage]
})
export class AssetInformationContentModalPageModule { }
