<ion-card-content class="ion-padding-horizontal-2 ion-padding-vertical-0">
    <ion-item lines='none'>
        <ion-button tabIndex="1" class="ion-margin-right-0" slot="end" color="secondary" fill="clear" (click)="clear()"
            [disabled]="!formControl.value">
            Clear
            <ion-icon slot="end" name="close-circle-outline" class="ion-label-font-xlg"></ion-icon>
        </ion-button>
    </ion-item>
    <ion-list>
        <ion-item lines="none">
            <ion-label class="ion-text-wrap ion-text-capitalize ion-label-font-sm" color="secondary">
                Select Date:
            </ion-label>
            <ion-input tabIndex="2" autoFocus="false" class="ion-label-font-sm" placeholder="dd/mm/yyyy"
                [(ngModel)]="currentValue" [readonly]="props.autoIncrement?.increment"
                [type]="props.autoIncrement?.increment ? 'text' : 'date'">
            </ion-input>
        </ion-item>
    </ion-list>
</ion-card-content>