<ion-card-content class="ion-padding-horizontal-2 ion-padding-vertical-0">
    <ng-container *ngIf="isNFCSupported">
        <ng-container *ngIf="isNFCEnabled">
            <ion-item lines="none" *ngIf="rfid && rfid.length > 0">
                <ion-label class="ion-label-font-sm ion-text-wrap">{{ rfid }}</ion-label>
            </ion-item>
            <ion-grid>
                <ion-row>
                    <ion-col size-sm="6" offset-sm="3" size-xs="12">
                        <ion-button tabIndex="1" class="ion-label-font-sm" color="primary" expand="block"
                            *ngIf="rfid?.length <= 0" (click)="startScanRfidTag()">
                            Scan RFID Tag
                            <ion-icon slot="end" name="barcode-outline"></ion-icon>
                        </ion-button>
                        <ion-button tabIndex="1" class="ion-label-font-sm" color="danger" expand="block"
                            *ngIf="rfid?.length > 0" (click)="openDeleteRfidModal()">
                            Delete
                            <ion-icon slot="end" name="trash-outline"></ion-icon>
                        </ion-button>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </ng-container>

        <ng-container *ngIf="!isNFCEnabled">
            <ion-item lines="none">
                <ion-label class="ion-label-font-sm ion-text-wrap">
                    NFC is currently disabled on your device, please turn NFC on in your device settings.
                </ion-label>
            </ion-item>
        </ng-container>

    </ng-container>

    <ng-container *ngIf="!isNFCSupported">
        <ion-item lines="none">
            <ion-label class="ion-label-font-sm ion-text-wrap">
                NFC is not supported on this device, please use an Android device and/or Apple device with iOS 7+ to
                be able to scan RFID tags.
            </ion-label>
        </ion-item>
    </ng-container>

</ion-card-content>