<ion-grid *ngIf="props.additionalFields.comments">
    <ion-row>
        <ion-col size="12">
            <ion-item lines="none" *ngIf="comments.length > 0">
                <ion-label>
                    <ion-label class="ion-label-font-sm ion-text-wrap ion-margin-top-2">{{ comments }}</ion-label>
                </ion-label>
            </ion-item>
        </ion-col>
        <ion-col size-sm="6" offset-sm="3" size-xs="12">
            <ion-button tabIndex="1" color="secondary" fill="clear" size="small" expand="block"
                (click)="presentCommentsModal(comments)">
                Comments
                <ion-icon slot="end" name="pencil-outline"></ion-icon>
            </ion-button>
        </ion-col>
        <ion-col *ngIf="props.additionalFields.commentsRequired" size="12" class="ion-text-center">
            <ion-label class="ion-text-wrap ion-label-font-xxs" color="danger">
                <i>Comments are required for this question</i>
            </ion-label>
        </ion-col>
    </ion-row>
</ion-grid>

<ion-grid *ngIf="props.additionalFields.photos || props.additionalFields.attachPhotos">
    <ion-row>
        <ng-container *ngIf="thumbnails.length > 0">
            <ion-col class="thumbnail-wrapper" size="3" *ngFor="let thumbnail of thumbnails; let i = index;">
                <img [src]="thumbnail.base64" (click)="presentImageActionSheet(thumbnails,i)">
            </ion-col>
        </ng-container>
        <ion-col size-sm="6" offset-sm="3" size-xs="12">
            <ion-button tabIndex="1" color="secondary" fill="clear" size="small" expand="block"
                *ngIf="props.additionalFields.photos" (click)="takePicture()">
                Take Picture
                <ion-icon slot="end" name="camera-outline"></ion-icon>
            </ion-button>
            <ion-button tabIndex="1" class="ion-margin-top-3" fill="clear" size="small" color="secondary" expand="block"
                *ngIf="props.additionalFields.attachPhotos" (click)="attachPhotos()">
                Attach Photos
                <ion-icon slot="end" name="images-outline"></ion-icon>
            </ion-button>
        </ion-col>
        <ion-col *ngIf="props.additionalFields.photosRequired" size="12" class="ion-text-center">
            <ion-label class="ion-text-wrap ion-label-font-xxs" color="danger">
                <i>Photos are required for this question</i>
            </ion-label>
        </ion-col>
    </ion-row>
</ion-grid>