import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms/';

import { Capacitor } from '@capacitor/core';
import { Camera, CameraResultType, CameraSource, ImageOptions } from '@capacitor/camera';
import { Filesystem } from '@capacitor/filesystem';

import { ActionSheetController, AlertController, ModalController, Platform, ToastController } from '@ionic/angular';

import { FieldType } from '@ngx-formly/core';
import { v4 } from 'uuid';

import { ImageViewerModalPage } from '@modals/image-viewer-modal/image-viewer-modal.page';

import { AuditService } from '@services/audit.service';
import { ConfigService } from '@services/config.service';
import { LocationService } from '@services/location.service';

import { resizeImage } from '@library/Utils';

import { IThumbnailImage } from '@models/types';

import { get } from 'lodash-es';

const TAG = 'TaCameraComponent';

/**
 * Formly-compatible camera component to allow taking of photos
 */
@Component({
    selector: 'app-ta-camera',
    templateUrl: 'ta-camera.html',
    styleUrls: ['ta-camera.scss']
})
export class TaCameraComponent extends FieldType implements OnInit {

    public thumbnails: IThumbnailImage[] = [];
    public maxPhotos = 0;

    private updatedKey: string;

    constructor(
        private actionSheetCtrl: ActionSheetController,
        private alertController: AlertController,
        private auditService: AuditService,
        public configService: ConfigService,
        public locationService: LocationService,
        public modalController: ModalController,
        public toastController: ToastController,
    ) {
        super();
    }

    ngOnInit() {
        this.updatedKey = this.key as string;
        if (this.field.parent?.key && this.field.parent?.parent.key) {
            this.updatedKey = this.field.parent?.parent.key + '[' + this.field.parent?.key + '].' + this.key;
        }

        const savedPhotos: IThumbnailImage[] = get(this.model, this.key, []);
        if (savedPhotos) {
            if (savedPhotos.length > 0) {
                this.getThumbnails(savedPhotos);
            }
        }

        if (this.props.maxPhotos) {
            this.maxPhotos = this.props.maxPhotos;
        }

    }

    public async getThumbnails(savedPhotos: IThumbnailImage[]) {
        const METHOD = 'getThumbnails';
        try {
            for (const savedPhoto of savedPhotos) {
                this.thumbnails.push({
                    image_id: savedPhoto.image_id,
                    base64: savedPhoto.base64
                });
            };
        } catch (error) {
            console.error(TAG + METHOD, 'error=', error);
            throw error;
        }
    }

    public async takePicture() {
        const METHOD = 'takePicture';
        try {
            const cameraOptions: ImageOptions = {
                quality: 25,
                allowEditing: false,
                resultType: CameraResultType.DataUrl,
                source: CameraSource.Camera,
                correctOrientation: true
            };
            const capturedImageData = await Camera.getPhoto(cameraOptions);
            await this.processImage(capturedImageData.dataUrl);
            await this.savePhotos();
        } catch (error) {
            const failedToAttachImage = await this.toastController.create({
                color: 'danger',
                message: 'Failed to take picture, please try again.',
                duration: 2000,
                position: 'top'
            });
            await failedToAttachImage.present();
            console.error(TAG + METHOD, 'error=', error);
        }
    }

    public async attachPhotos() {
        const METHOD = 'attachPhotos';
        try {
            const capturedPhotos = await Camera.pickImages({
                quality: 25
            });

            for (const photo of capturedPhotos.photos) {
                await this.processImage(photo.webPath);
            }

            await this.savePhotos();
        } catch (error) {
            console.error(TAG + METHOD, 'error=', error);
        }
    }

    public async presentImageActionSheet(thumbnails: IThumbnailImage[], index: number) {
        const METHOD = 'presentImageActionSheet';
        const actionSheet = await this.actionSheetCtrl.create({
            header: 'Image Options',
            buttons: [
                {
                    text: 'View',
                    handler: async () => {
                        const imageViewerModal = await this.modalController.create({
                            component: ImageViewerModalPage,
                            componentProps: {
                                images: thumbnails.map(thumbnail => thumbnail.base64),
                                imageIndex: index
                            }
                        });
                        await imageViewerModal.present();
                    }
                },
                {
                    text: 'Delete',
                    role: 'destructive',
                    handler: async () => {
                        try {
                            this.thumbnails.splice(index, 1);
                        } catch (error) {
                            console.error(TAG + METHOD, 'deleteDocument error=', error);
                        }
                        this.savePhotos();
                    }
                }, {
                    text: 'Cancel',
                    role: 'cancel'
                }
            ],
            backdropDismiss: false
        });
        await actionSheet.present();
    }

    private async processImage(base64: string) {
        const METHOD = 'processImage';
        try {
            const thumbnail = await resizeImage(base64);
            const imageId = 'audit_image::' + v4().replace(/-/g, '');
            this.thumbnails.push({
                image_id: imageId,
                base64: thumbnail,
            });
        } catch (error) {
            console.error(TAG + METHOD, 'error=', error);
            throw error;
        }
    }

    private async savePhotos() {
        const formControl = this.formControl as FormControl;
        if (this.thumbnails.length > 0) {
            formControl.setValue(this.thumbnails);
            this.auditService.setLocationAndTimestamp(this.updatedKey);
        } else {
            formControl.reset(null);
            this.auditService.unsetLocationAndTimestamp(this.updatedKey);
        }
    }

}
