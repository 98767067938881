import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { NFC } from '@awesome-cordova-plugins/nfc/ngx';
import { IonicModule } from '@ionic/angular';

import { FormlyModule } from '@ngx-formly/core';

import { TaFormlyFieldWrapperModule } from '@ta-formly/ta-field-wrapper/ta-field-wrapper.module';
import { TaRfidComponent } from './ta-rfid.type';

@NgModule({
    declarations: [TaRfidComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        IonicModule,
        TaFormlyFieldWrapperModule,
        FormlyModule.forChild({
            types: [
                {
                    name: 'ta-rfid',
                    component: TaRfidComponent,
                    wrappers: ['ta-field-wrapper']
                }
            ]
        })
    ],
    providers: [
        NFC
    ]
})
export class TaFormlyRfidModule { }
