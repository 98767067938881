import { Component, OnInit } from '@angular/core';

import { ModalController } from '@ionic/angular';

import { ApiService } from '@services/api.service';
import { AssetInformationModalService } from '../asset-information-modal.service';

import { IAsset } from '@models/types';

import * as dayjs from 'dayjs';

@Component({
    selector: 'app-asset-type-selection-modal',
    templateUrl: './asset-type-selection-modal.page.html',
    styleUrls: ['./asset-type-selection-modal.page.scss'],
})
export class AssetTypeSelectionModalPage implements OnInit {

    public dayjs = dayjs;

    public latestAssetFormConfigIds: string[] = [];
    public isDataLoaded = false;

    constructor(
        public assetInformationModalService: AssetInformationModalService,
        private apiService: ApiService,
        private modalController: ModalController
    ) { }

    async ngOnInit() {
        const latestAssetFormConfigIds: string[] = [];
        for await (const asset of (this.assetInformationModalService.assets as IAsset[])) {
            const { id } = await this.apiService.getLatestAssetFormConfig(asset.asset_form_config_id);
            latestAssetFormConfigIds.push(id);
        }
        this.latestAssetFormConfigIds = latestAssetFormConfigIds;
        this.isDataLoaded = true;
    }

    public async selectAsset(assetId: string) {
        await this.modalController.dismiss(assetId);
    }

    public async closeModal() {
        await this.modalController.dismiss();
    }

}
