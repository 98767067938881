import { Component } from '@angular/core';

import { FieldType } from '@ngx-formly/core';

/**
 * `ta-hidden` questions have an empty template (they are invisible).
 * This is because we want additional photos and comments to have their own field objects
 * and states but we do not want additional photos or additional comments to appear as
 * individual questions to the user.
 */
@Component({
    selector: 'app-ta-hidden',
    template: ''
})
export class TaHiddenComponent extends FieldType {

}
