import { Component, HostListener, OnInit } from '@angular/core';

import { FieldType } from '@ngx-formly/core';

import { AuditService } from '@services/audit.service';

@Component({
    selector: 'app-ta-textarea',
    templateUrl: 'ta-textarea.html',
    styleUrls: ['ta-textarea.scss']
})
export class TaTextareaComponent extends FieldType implements OnInit {

    private updatedKey: string;

    constructor(
        private auditService: AuditService
    ) {
        super();
    }

    @HostListener('ionBlur')
    public ionBlur() {
        if (this.formControl.value) {
            if (this.props.showInList) {
                const value = { key: this.updatedKey as string, label: this.props.label, answer: this.formControl.value, type: this.field.type as string };
                this.auditService.addShowInListValue(value);
            }
        } else {
            if (this.props.showInList) {
                this.auditService.removeShowInListValue(this.updatedKey as string);
            }
        }
    }

    @HostListener('ionChange')
    public ionChange() {
        this.textAreaChanges();
    }

    ngOnInit() {
        // Get the path of the field by removing ".value" from the field's key
        this.updatedKey = this.key as string;
        if (this.field.parent?.key && this.field.parent?.parent.key) {
            this.updatedKey = this.field.parent?.parent.key + '[' + this.field.parent?.key + '].' + this.key;
        }
    }

    public clear() {
        this.formControl.reset(null);
    }

    private textAreaChanges() {
        if (this.formControl.value) {
            this.auditService.setLocationAndTimestamp(this.updatedKey);
        } else if (this.formControl.value) {
            this.auditService.unsetLocationAndTimestamp(this.updatedKey);
        }
    }
}
