import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { NavController } from '@ionic/angular';

import { FieldType } from '@ngx-formly/core';

import { AuditService } from '@services/audit.service';
import { ConfigService } from '@services/config.service';
import { FormlyService } from '@services/formly.service';

import { debounceTime } from 'rxjs/operators';

import { IFormConfig, IFormConfigSection } from '@models/types';

@Component({
    selector: 'app-ta-stepper',
    templateUrl: 'ta-stepper.html',
    styleUrls: ['ta-stepper.scss']
})
export class TaStepperComponent extends FieldType implements OnInit {

    @ViewChild(MatStepper, { static: false }) public stepper: MatStepper;

    public section: IFormConfigSection;
    private sectionIndex: number;
    private parentSection: IFormConfigSection;
    private currentSectionFormControl: AbstractControl;
    public buttonColor: 'success' | 'danger' | 'secondary' | 'medium' = "medium";

    constructor(
        public auditService: AuditService,
        private changeDetectorRef: ChangeDetectorRef,
        public configService: ConfigService,
        public formlyService: FormlyService,
        private navController: NavController,
    ) {
        super();
    }

    ngOnInit() {
        /*
         * The stepper index is used to show the current question number in the QuestionsPageComponent header
         */
        this.formlyService.setStepperIndex(0);

        this.sectionIndex = this.formlyService.getSectionIndex();
        const section = this.formlyService.getFormConfig().sections[this.sectionIndex];
        if (this.formlyService.getIsRepeatSection()) {
            this.parentSection = section;
            this.section = section.repeat_sections[this.formlyService.getRepeatSectionIndex()];
        } else {
            this.section = section;
        }
        setTimeout(() => {
            /*
             * This needs to be in a settimeout or it updates the model causing an
             * ExpressionChangedAfterItHasBeenCheckedError error
             *
             * It would be better as part of the audit preparation that all button colours
             * are set in the audit service and only updated when question values change
             *
             * It would make sense not to have the button colours as part of the form model, keep
             * it in it's own audit service object that is instantiated when the audit is initialised
             * and updated when question values change
             */
            this.setButtonColor(this.section, this.stepper.selectedIndex);

            /*
             * There is no easy way for the individual question form control to notify this component that
             * it's value has changed, hence we listen to the form control for the whole section
             *
             * In theory we could use this.formControl which is the current form control, but this gives errors
             * I am unable to get to the bottom of
             */
            if (this.section.parent_section) {
                this.currentSectionFormControl = this.form.get('data').get('section_' + this.sectionIndex).get('repeat_sections').get('section_' + this.formlyService.getRepeatSectionIndex());
            } else {
                this.currentSectionFormControl = this.form.get('data').get('section_' + this.sectionIndex);
            }

            this.subscribeToQuestionIndexChanges();
            this.subscribeToQuestionChanges();
            // this.subscribeToFieldChanges();

        }, 300);
    }

    public async prevStep() {
        this.auditService.updateAuditSectionStatus(this.formlyService.getIsRepeatSection() ? this.parentSection : this.section, this.sectionIndex, this.formlyService.getIsRepeatSection());
        this.stepper.previous();
        this.formlyService.setStepperIndex(this.stepper.selectedIndex);
        this.setButtonColor(this.section, this.stepper.selectedIndex);
    }

    public async nextStep() {
        this.auditService.updateAuditSectionStatus(this.formlyService.getIsRepeatSection() ? this.parentSection : this.section, this.sectionIndex, this.formlyService.getIsRepeatSection());
        if (this.stepper.selectedIndex !== (this.stepper.steps.length - 1)) {
            this.stepper.next();
            this.formlyService.setStepperIndex(this.stepper.selectedIndex);
            this.setButtonColor(this.section, this.stepper.selectedIndex);
        } else {
            this.formlyService.setStepperIndex(0);
            if (this.formlyService.getIsRepeatSection()) {
                this.navController.navigateBack('/tabs/audits/repeat-sections');
            } else {
                this.navController.navigateBack(['/tabs/audits/sections', (this.formlyService.getFormConfig() as IFormConfig)?.template_id, this.configService.sessionId]);
            }
        }
    }

    private subscribeToQuestionIndexChanges() {
        this.formlyService.questionIndexChanged.subscribe((questionIndex: number) => {
            this.stepper.selectedIndex = questionIndex;
            this.formlyService.setStepperIndex(questionIndex);
            this.setButtonColor(this.section, this.stepper.selectedIndex);
        });
    }

    private subscribeToQuestionChanges() {
        this.currentSectionFormControl.valueChanges.pipe(debounceTime(300)).subscribe(() => {
            this.setButtonColor(this.section, this.stepper.selectedIndex);
        });
    }

    private setButtonColor(section: IFormConfigSection, questionIndex: number) {
        const currentQuestion = section.questions[questionIndex];
        const { questionAnswered, questionRequired } = this.formlyService.checkQuestionsValidity(currentQuestion);

        if (questionRequired && !questionAnswered) {
            this.buttonColor = 'danger';
        } else if (!questionRequired && !questionAnswered) {
            this.buttonColor = 'secondary';
        } else {
            this.buttonColor = 'success';
        }
        this.changeDetectorRef.detectChanges();
    }

}
