import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms/';

import { FieldType } from '@ngx-formly/core';

import { AuditService } from '@services/audit.service';

import { IScannedAsset } from '@models/types';

import { get, set } from 'lodash-es';

const TAG = 'TaAssetScanComponent';

/**
 * Formly-compatible asset scan component
 */
@Component({
    selector: 'app-ta-asset-scan',
    templateUrl: 'ta-asset-scan.html',
    styleUrls: ['./ta-asset-scan.scss'],
})
export class TaAssetScanComponent extends FieldType implements OnInit {

    public currentAssets: IScannedAsset[] = [];
    public updatedKey: string;

    constructor(
        private auditService: AuditService,
        private changeDetectorRef: ChangeDetectorRef,
    ) {
        super();
    }

    ngOnInit() {
        this.updatedKey = this.key as string;
        if (this.field.parent?.key && this.field.parent?.parent.key) {
            this.updatedKey = this.field.parent?.parent.key + '[' + this.field.parent?.key + '].' + this.key;
        }
        // Get existing assets tag value if it exists
        const assets = get(this.model, this.key, []);
        if (assets) {
            this.currentAssets = assets;
        }
    }

    public async saveAsset({ assets, currentAssetId }: { assets: IScannedAsset[]; currentAssetId: string }) {
        set(this.model, this.key, assets);
        const formControl = this.formControl as FormControl;
        /* If a assets array has been given, set the formControl value to the array
          else, set the formControl value to null  */

        if (assets) {
            formControl.setValue(assets);

            if (this.props.showInList) {
                const assetsValues = assets.map(asset => asset.value);
                const value = { key: this.updatedKey, label: this.props.label, answer: assetsValues, type: this.field.type as string };
                this.auditService.addShowInListValue(value);
            }

            this.auditService.setLocationAndTimestamp(this.updatedKey);
            if (this.model.audit_type === 'Register Item Checklist' ||
                this.model.audit_type === 'Asset Inspection Checklist') {
                if (this.updatedKey === 'data.section_0.question_0.findings.value') {
                    this.model.asset_id = assets[0].asset_id;
                    this.model.asset_identifier = assets[0].asset_identifier;
                }
            }
        } else {
            formControl.reset(null);

            if (this.props.showInList) {
                this.auditService.removeShowInListValue(this.updatedKey as string);
            }

            this.auditService.unsetLocationAndTimestamp(this.updatedKey);
            if (this.model.audit_type === 'Register Item Checklist' ||
                this.model.audit_type === 'Asset Inspection Checklist') {
                if (this.updatedKey === 'data.section_0.question_0.findings.value') {
                    this.model.asset_id = null;
                    this.model.asset_identifier = null;
                }
            }
        }
        // this.auditService.updateAssetsInAudit(assets, currentAssetId);

        // TODO - Ionic bug
        //https://stackoverflow.com/questions/61237069/ionic-5-i-need-to-detect-changes-to-an-array-after-passing-from-a-child-page-t
        this.changeDetectorRef.detectChanges();
    }

}
