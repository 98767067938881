<ion-item *ngIf="guidance.text.length > 0" lines="none">
    <ion-label class="ion-text-wrap guidance-text" [innerHTML]="guidance.text" color="medium"
        style="-webkit-user-select: text; -moz-user-select: text; -ms-user-select: text; user-select: text;">
    </ion-label>
</ion-item>
<ion-item *ngIf="guidance.images.length > 0" lines="none">
    <ion-grid>
        <ion-row>
            <ion-col class="thumbnail-wrapper" size="3" *ngFor="let guidanceImage of guidance.images; let i = index;"
                (click)="openViewImageModal(guidance.images, i)">
                <img [src]="guidanceImage.imageThumbnail">
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-item>