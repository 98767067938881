<ion-header>
    <ion-toolbar>
        <ion-title>View Image</ion-title>
        <ion-icon class="ion-label-font-xlg ion-margin-right-2" slot="end" name="share-outline" color="primary"
            (click)="shareImage()">
        </ion-icon>
        <ion-label class="ion-margin-right-2" slot="end" (click)="closeModal()">Close</ion-label>
    </ion-toolbar>
</ion-header>

<ion-content scrollY="false">
    <ion-grid class="image-container ion-no-padding">
        <ion-row>
            <ion-col class="ion-no-padding ion-text-center" [class.ion-align-self-center]="image === 'no-image'">
                <ng-container *ngIf="image === 'no-image'; else showImage">
                    <ion-label class="ion-text-wrap ion-label-font-lg">
                        Unable to load image
                    </ion-label>
                </ng-container>
                <ng-template #showImage>
                    <pinch-zoom *ngIf="image" backgroundColor="#f8f8f8" class="pinch-container">
                        <img [src]="image">
                    </pinch-zoom>
                </ng-template>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>

<ng-container *ngIf="images.length > 1">
    <ion-footer>
        <ion-toolbar class="ion-no-padding">
            <ion-grid>
                <ion-row>
                    <ion-col>
                        <ion-button tabIndex="1" expand="block" color="primary" fill="outline" (click)="prevImage()"
                            [disabled]="imageIndex === 0">
                            Previous
                        </ion-button>
                    </ion-col>
                    <ion-col>
                        <ion-button tabIndex="1" expand="block" color="primary" fill="outline" (click)="nextImage()"
                            [disabled]="imageIndex === (images.length - 1)">
                            Next
                        </ion-button>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </ion-toolbar>
    </ion-footer>
</ng-container>