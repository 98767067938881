export const environment = {
    brandName: 'TickAudit',
    brandIconDark: 'tickaudit-icon-dark.png',
    brandIconLight: 'tickaudit-icon-light.png',
    brandLogoDark: 'tickaudit-logo-dark.png',
    brandLogoLight: 'tickaudit-logo-light.png',
    brandPoweredByLogo: 'tickaudit-powered-by-logo.png',
    environment: 'tickaudit-dev',
    notificationEnvironment: 'dev',
    production: false,
    restApiUrl: 'https://devdashboard.tickaudit.com/api/v1',
    syncURL: 'wss://devdashboard.tickaudit.com/gateway/tickaudit',
    dashboardUrl: 'https://devdashboard.tickaudit.com/dashboard',
    debug: true,
    firebase: {
        apiKey: 'AIzaSyBo76MKyf-FpwHY-gl0R_DmDS52M1Nyhrs',
        authDomain: 'tickaudit-11187.firebaseapp.com',
        databaseURL: 'https://tickaudit-11187.firebaseio.com',
        projectId: 'tickaudit-11187',
        storageBucket: 'tickaudit-11187.appspot.com',
        messagingSenderId: '242098300023',
        appId: '1:242098300023:web:345f6874f9acb76b631272',
        measurementId: 'G-WFW1R4Y3HP',
        vapidKey:'BEvv-0mvL5OZW3uiEaa2cHjmsV8BQI4gN8iXrFEzp0p_0LpbECYyn8DrNmHOQisKSLW7zy9lxKN81pKZFBi0WR0'
    },
};
