import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { AlertController, IonItemSliding, ModalController, ToastController } from '@ionic/angular';

import { AssetSelectModalPage } from '../asset-select-modal/asset-select-modal.page';
import { AssetInformationModalPage } from '@modals/asset-information-modal/asset-information-modal.page';
import { ModalRoutingBasePage } from '@components/modal-routing-base/modal-routing-base.page';

import { AssetInformationModalService } from '@modals/asset-information-modal/asset-information-modal.service';
import { ApiService } from '@services/api.service';

import { IAsset, IScannedAsset, ITaFormlyTemplateOptions } from '@models/types';

import * as dayjs from 'dayjs';
import * as customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

const TAG = 'MultiAssetSelectComponent';

@Component({
    selector: 'app-multi-asset-select',
    templateUrl: './multi-asset-select.html',
    styleUrls: ['./multi-asset-select.scss'],
})
export class MultiAssetSelectComponent implements OnInit {

    @Input() scannedAssets: IScannedAsset[] = [];
    @Input() props: ITaFormlyTemplateOptions;
    @Input() key: string | number | string[];
    @Output() assetsEvent: EventEmitter<IScannedAsset[]> = new EventEmitter();

    public assetLabel = '';
    private title = '';

    public isNFCEnabled = true;
    public isNFCSupported = true;
    public assetFormConfigSet = true;

    public dayjs = dayjs;

    private latestAssetFormConfigId: string;
    private identifierType: 'rfid_tag' | 'qr_code' | 'uhf_rfid_tag';

    constructor(
        private alertController: AlertController,
        private apiService: ApiService,
        private assetInformationModalService: AssetInformationModalService,
        private changeDetectorRef: ChangeDetectorRef,
        private modalController: ModalController,
        private toastController: ToastController
    ) { }

    async ngOnInit() {
        if (this.props.asset_form_config_id) {
            await this.getLatestAssetFormConfig();
        } else {
            this.assetFormConfigSet = false;
        }
    }

    public async getLatestAssetFormConfig() {
        const assetFormConfig = await this.apiService.getLatestAssetFormConfig(this.props.asset_form_config_id);
        this.latestAssetFormConfigId = assetFormConfig.id;
        this.assetLabel = assetFormConfig.sections[0]?.questions[0]?.fields[0]?.templateOptions?.label;
        this.title = assetFormConfig.title;
        this.changeDetectorRef.detectChanges();
    }

    public async presentAssetsSelectModal() {
        const assetSelectModal = await this.modalController.create({
            component: AssetSelectModalPage,
            componentProps: {
                multi: this.props.multi,
                currentlySelectedAssets: this.scannedAssets.map(selectedAsset => selectedAsset.asset_id),
                assetFormConfigId: this.props.asset_form_config_id
            },
            canDismiss: true
        });
        await assetSelectModal.present();

        const { data } = await assetSelectModal.onWillDismiss();
        if (data) {
            this.scannedAssets.push(...data);
            this.saveAssets();
        }
    }

    public async scanQrCode(inputValue?: string) {
        this.identifierType = 'qr_code';
        const prompt = await this.alertController.create({
            header: this.title,
            inputs: [
                {
                    name: 'qr_code',
                    placeholder: 'Enter QR Code',
                    value: inputValue || ''
                },
            ],
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel'
                },
                {
                    text: 'Submit',
                    handler: async (data) => {
                        if (data && data.qr_code) {
                            this.processIdentifier(data.qr_code);
                        } else {
                            const toast = await this.toastController.create({ header: 'Value cannot be empty', color: 'danger', position: 'top', duration: 2000, })
                            toast.present();
                            return false;
                        }
                    }
                }
            ],
            backdropDismiss: false
        });
        await prompt.present();
    }

    public async startScanRfidTag(inputValue?: string) {
        this.identifierType = 'rfid_tag';
        const prompt = await this.alertController.create({
            header: this.title,
            inputs: [
                {
                    name: 'rfid_tag',
                    placeholder: 'Enter RFID Tag',
                    value: inputValue || ''
                },
            ],
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel'
                },
                {
                    text: 'Submit',
                    handler: async (data) => {
                        if (data && data.rfid_tag) {
                            this.processIdentifier(data.rfid_tag);
                        } else {
                            const toast = await this.toastController.create({ header: 'Value cannot be empty', color: 'danger', position: 'top', duration: 2000, })
                            toast.present();
                            return false;
                        }
                    }
                }
            ],
            backdropDismiss: false
        });
        await prompt.present();
    }

    public async presentUhfRfidInputTextPrompt(inputValue?: string) {
        this.identifierType = 'uhf_rfid_tag';
        const prompt = await this.alertController.create({
            header: 'Scan UHF RFID Tag',
            inputs: [
                {
                    name: 'uhf_rfid_tag',
                    placeholder: 'UHF RFID Tag',
                    value: inputValue || ''
                },
            ],
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel'
                },
                {
                    text: 'Submit',
                    handler: async (data) => {
                        if (data && data.uhf_rfid_tag) {
                            this.processIdentifier(data.uhf_rfid_tag);
                        } else {
                            const toast = await this.toastController.create({ header: 'Value cannot be empty', color: 'danger', position: 'top', duration: 2000, })
                            toast.present();
                            return false;
                        }
                    }
                }
            ],
            backdropDismiss: false
        });
        await prompt.present();
    }

    private async processIdentifier(identifierValue: string) {
        const assets: IAsset[] = await this.apiService.getAssetsByIdentifier(this.identifierType, identifierValue, this.latestAssetFormConfigId);
        if (assets.length === 0) {
            this.presentNoAssetFoundAlert();
        } else {
            if (assets.length === 1) {
                const asset = assets[0];
                const { id, asset_form_config_id, identifiers: { asset_identifier } } = asset;
                const toAssetFormConfigComparisonId = this.props.asset_form_config_id?.split('::v')[0];
                const assetFormConfigComparisonId = asset_form_config_id.split('::v')[0];

                if (this.scannedAssets.find(a => a.asset_identifier.toLowerCase().trim() === asset_identifier.toLowerCase().trim())) {
                    await this.presentAlreadyScannedAlert();
                } else if (this.props?.asset_form_config_id && toAssetFormConfigComparisonId !== assetFormConfigComparisonId) {
                    this.presentWrongAssetTypeAlert();
                } else {
                    await this.confirmAsset(id, asset);
                }
            } else {
                this.presentMultiAssetInformationModal(assets);
            }
        }
    }

    private async confirmAsset(assetId: string, asset: IAsset) {
        const assetIdentifier: any = asset.sections[0].questions[0];
        const scannedAsset: IScannedAsset = {
            asset_id: assetId,
            asset_identifier: assetIdentifier.answer,
            label: assetIdentifier.label,
            value: assetIdentifier.label + ': ' + assetIdentifier.answer,
            sections: [{
                questions: asset.sections[0].questions.filter(question => question.showInList && question.answer)
            }]
        };
        this.scannedAssets.unshift(scannedAsset);
        this.saveAssets();
    }

    public async presentMultiAssetInformationModal(assets: IAsset[]) {
        const multiAssetInformationModal = await this.modalController.create({
            component: ModalRoutingBasePage,
            componentProps: {
                rootPage: AssetInformationModalPage,
                data: {
                    assets,
                    multi: false,
                    type: 'view',
                    isQuestion: true
                },
            },
            canDismiss: true
        });
        await multiAssetInformationModal.present();

        const response = await multiAssetInformationModal.onDidDismiss();
        if (response.data) {
            const assetId = response.data;
            if (this.scannedAssets.find(a => a.asset_id.toLowerCase().trim() === assetId)) {
                await this.presentAlreadyScannedAlert();
            } else {
                const asset = assets.find(a => a.id === assetId);
                this.confirmAsset(assetId, asset);
            }
        }
    }

    public async presentAssetInformationModal(assetId: string, itemSliding?: IonItemSliding) {
        const asset = await this.apiService.getAsset(assetId);
        const assetInformationModal = await this.modalController.create({
            component: AssetInformationModalPage,
            componentProps: {
                assetId,
                asset,
                multi: this.props.multi,
                type: 'view'
            },
            canDismiss: true,
        });

        this.assetInformationModalService.pageType = 'modal';

        if (itemSliding) {
            itemSliding.close();
        }
        await assetInformationModal.present();
    }

    private async presentAlreadyScannedAlert() {
        const assetAlreadyScannedAlert = await this.alertController.create({
            header: 'Asset already added',
            message: 'This asset has already been added. Please try with another asset.',
            buttons: ['Ok']
        });
        await assetAlreadyScannedAlert.present();
    }

    private async presentNoAssetFoundAlert() {
        let message = '';
        if (this.identifierType === 'rfid_tag') {
            message = 'This RFID tag has not been recognised.\nPlease try scanning again.';
        } else if (this.identifierType === 'qr_code') {
            message = 'This QR Code has not been recognised.\nPlease try scanning again.';
        } else if (this.identifierType === 'uhf_rfid_tag') {
            message = 'This UHF RFID Tag has not been recognised.\nPlease try again.';
        }

        const noAssetFoundAlert = await this.alertController.create({
            header: 'Cannot find asset',
            message,
            buttons: ['Ok']
        });
        await noAssetFoundAlert.present();
    }

    private async presentWrongAssetTypeAlert() {
        let message = '';
        if (this.identifierType === 'rfid_tag' || this.identifierType === 'qr_code') {
            message = 'You have scanned the wrong type of asset.\nPlease try scanning another asset of type ' + this.title + '.';
        } else if (this.identifierType === 'uhf_rfid_tag') {
            message = 'You have entered a ' + this.assetLabel + ' for the wrong type of asset.\nPlease try entering the ' + this.assetLabel + ' of another asset of type ' + this.title + '.';
        }

        const wrongAssetTypeAlert = await this.alertController.create({
            header: 'Cannot find asset',
            message,
            buttons: ['Ok']
        });
        await wrongAssetTypeAlert.present();
    }

    public async presentDeleteAlert(currentAssetIndex: number, itemSliding?: IonItemSliding) {
        const currentAsset = this.scannedAssets[currentAssetIndex];
        const deleteAssetAlert = await this.alertController.create({
            header: currentAsset.value,
            message: 'Do you want to remove this asset?',
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel'
                },
                {
                    text: 'Delete',
                    handler: () => {
                        this.scannedAssets.splice(currentAssetIndex, 1);
                        if (itemSliding) {
                            itemSliding.close();
                        }
                        this.saveAssets();
                    }
                }
            ],
            backdropDismiss: false
        });
        await deleteAssetAlert.present();
    }

    private saveAssets() {
        if (this.scannedAssets?.length > 0) {
            this.assetsEvent.emit(this.scannedAssets);
        } else {
            this.assetsEvent.emit(null);
        }

    }
}
