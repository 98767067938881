<ion-card-content class="ion-padding-horizontal-2 ion-padding-vertical-0">
    <ion-grid>
        <ion-row>
            <ion-col>
                <ion-button [disabled]="disableButton" tabIndex="1" class="ion-label-font-sm" color="primary" expand="block"
                    (click)="onUnderstoodClick()">
                    Understood
                </ion-button >
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-card-content>