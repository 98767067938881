import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

import { AlertController, ModalController } from '@ionic/angular';
import { ISignature } from '@models/types';

import { FieldType } from '@ngx-formly/core';
import { ApiService } from '@services/api.service';

import { AuditService } from '@services/audit.service';

const TAG = 'TaESignatureComponent';

/**
 * Formly-compatible Ionic signature component
 */
@Component({
    selector: 'app-ta-e-signature',
    templateUrl: 'ta-e-signature.html',
    styleUrls: ['ta-e-signature.scss']
})
export class TaESignatureComponent extends FieldType implements OnInit {

    public eSignature: string;

    private updatedKey: string;
    private path: string;

    constructor(
        private alertController: AlertController,
        private apiService: ApiService,
        private auditService: AuditService,
        public modalController: ModalController,
        private changeDetectorRef: ChangeDetectorRef
    ) {
        super();
    }

    ngOnInit() {
        this.updatedKey = this.key as string;
        if (this.field.parent?.key && this.field.parent?.parent.key) {
            this.updatedKey = this.field.parent?.parent.key + '[' + this.field.parent?.key + '].' + this.key;
        }

        this.path = (this.updatedKey as string).substring(0, (this.updatedKey as string).lastIndexOf('.'));
        if (this.formControl.value) {
            this.eSignature = this.formControl.value;
            this.changeDetectorRef.detectChanges();
        }
    }

    public async addESignature() {
        const METHOD = 'saveESignature ';
        const noSignatureAlert = await this.alertController.create({
            header: 'E-Signature Not Found',
            message: 'Please add E-Signature by going into Settings -> View Personal Details.',
            buttons: ['Ok']
        });

        try {
            const signatureDocument: ISignature = await this.apiService.getUserSignature();
            if (!signatureDocument || (signatureDocument && !signatureDocument.base64)) {
                await noSignatureAlert.present();
            } else {
                this.eSignature = signatureDocument.base64;
                this.saveESignature();
            }
        } catch (error) {
            console.log(TAG, METHOD, 'error=', error);
            await noSignatureAlert.present();
        }
    }

    public async removeESignature() {
        this.eSignature = '';
        this.saveESignature();
    }

    private saveESignature() {
        const formControl = this.formControl as FormControl;
        if (this.eSignature) {
            formControl.setValue(this.eSignature);
            this.auditService.setLocationAndTimestamp(this.updatedKey);
        } else {
            formControl.reset(null);
            this.auditService.unsetLocationAndTimestamp(this.updatedKey);
        }
    }


}
