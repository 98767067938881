<ion-card-content class="ion-padding-horizontal-2 ion-padding-vertical-0">
    <ion-item lines='none'>
        <ion-button tabIndex="1" class="ion-margin-right-0" slot="end" color="secondary" fill="clear" (click)="clear()"
            [disabled]="!formControl.value">
            Clear
            <ion-icon slot="end" name="close-circle-outline" class="ion-label-font-xlg"></ion-icon>
        </ion-button>
    </ion-item>
    <ion-list>
        <ion-item lines="none">
            <ion-label class="ion-text-wrap ion-text-capitalize ion-label-font-sm" color="secondary">
                Select {{ props.type }}:
            </ion-label>
            <ion-input tabIndex="2" autoFocus="false" *ngIf="props.type === 'date'" class="ion-label-font-sm" placeholder="dd/mm/yyyy" [formControl]="dateTimeFormControl"
                type="date">
            </ion-input>
            <ion-input tabIndex="2" autoFocus="false" *ngIf="props.type === 'time'" class="ion-label-font-sm" placeholder="hh:mm" [formControl]="dateTimeFormControl"
                type="time">
            </ion-input>
        </ion-item>
    </ion-list>
</ion-card-content>