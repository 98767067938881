import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';


import { IonicModule } from '@ionic/angular';

import { FormlyModule } from '@ngx-formly/core';

import { UserSelectModalPageModule } from './user-select-modal/user-select-modal.module';

import { TaUserSelectComponent } from './ta-user-select.type';
import { SingleUserSelectComponent } from './single-user-select/single-user-select';
import { MultiUserSelectComponent } from './multi-user-select/multi-user-select';

import { TaFormlyFieldWrapperModule } from '@ta-formly/ta-field-wrapper/ta-field-wrapper.module';

@NgModule({
    declarations: [
        TaUserSelectComponent,
        SingleUserSelectComponent,
        MultiUserSelectComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        IonicModule,
        UserSelectModalPageModule,
        TaFormlyFieldWrapperModule,
        FormlyModule.forChild({
            types: [
                {
                    name: 'ta-user-select',
                    component: TaUserSelectComponent,
                    wrappers: ['ta-field-wrapper']
                }
            ]
        })
    ],
    providers: [],
})
export class TaFormlyUserSelectModule { }
