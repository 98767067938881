import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { FormlyModule } from '@ngx-formly/core';

import { TaFormlyFieldWrapperModule } from '@ta-formly/ta-field-wrapper/ta-field-wrapper.module';
import { TaLinkComponent } from './ta-link.type';

@NgModule({
    declarations: [TaLinkComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        IonicModule,
        TaFormlyFieldWrapperModule,
        FormlyModule.forChild({
            types: [
                {
                    name: 'ta-link',
                    component: TaLinkComponent,
                    wrappers: ['ta-field-wrapper']
                },
            ]
        })
    ]
})
export class TaFormlyLinkModule { }
