
import * as dayjs from 'dayjs';
import * as calendar from 'dayjs/plugin/calendar';
import { IDateLifecycle, IAudit, IAsset } from '../models/types';

dayjs.extend(calendar);

export const FormatDates = (lifecycle: IDateLifecycle) => {
    let formattedActivatedTimestamp: string;
    let formattedArchivedTimestamp: string;
    let formattedCreatedTimestamp: string;
    let formattedCompletedTimestamp: string;
    let formattedLastUpdatedTimestamp: string;
    let formattedSignInTimestamp: string;
    let formattedStartedTimestamp: string;

    if (lifecycle?.activated_timestamp) {
        formattedActivatedTimestamp = dayjs(lifecycle.activated_timestamp).calendar(null, {
            lastDay: '[Yesterday at] h:mm a',
            lastWeek: '[Last] dddd [at] h:mm a',
            sameDay: '[Today at] h:mm a',
            sameElse: 'DD/MM/YY [at] h:mm a'
        });
        lifecycle.activated_timestamp = formattedActivatedTimestamp;
    }

    if (lifecycle?.archived_timestamp) {
        formattedArchivedTimestamp = dayjs(lifecycle.archived_timestamp).calendar(null, {
            lastDay: '[Yesterday at] h:mm a',
            lastWeek: '[Last] dddd [at] h:mm a',
            sameDay: '[Today at] h:mm a',
            sameElse: 'DD/MM/YY [at] h:mm a'
        });
        lifecycle.archived_timestamp = formattedArchivedTimestamp;
    }

    if (lifecycle?.created_timestamp) {
        formattedCreatedTimestamp = dayjs(lifecycle.created_timestamp).calendar(null, {
            lastDay: '[Yesterday at] h:mm a',
            lastWeek: '[Last] dddd [at] h:mm a',
            sameDay: '[Today at] h:mm a',
            sameElse: 'DD/MM/YY [at] h:mm a'
        });
        lifecycle.created_timestamp = formattedCreatedTimestamp;
    }

    if (lifecycle?.completed_timestamp) {
        formattedCompletedTimestamp = dayjs(lifecycle.completed_timestamp).calendar(null, {
            lastDay: '[Yesterday at] h:mm a',
            lastWeek: '[Last] dddd [at] h:mm a',
            sameDay: '[Today at] h:mm a',
            sameElse: 'DD/MM/YY [at] h:mm a'
        });
        lifecycle.completed_timestamp = formattedCompletedTimestamp;
    }

    if (lifecycle?.last_updated_timestamp) {
        formattedLastUpdatedTimestamp = dayjs(lifecycle.last_updated_timestamp).calendar(null, {
            lastDay: '[Yesterday at] h:mm a',
            lastWeek: '[Last] dddd [at] h:mm a',
            sameDay: '[Today at] h:mm a',
            sameElse: 'DD/MM/YY [at] h:mm a'
        });
        lifecycle.last_updated_timestamp = formattedLastUpdatedTimestamp;
    }

    if (lifecycle?.sign_in_timestamp) {
        formattedSignInTimestamp = dayjs(lifecycle.sign_in_timestamp).calendar(null, {
            lastDay: '[Yesterday at] h:mm a',
            lastWeek: '[Last] dddd [at] h:mm a',
            sameDay: '[Today at] h:mm a',
            sameElse: 'DD/MM/YY [at] h:mm a'
        });
        lifecycle.sign_in_timestamp = formattedSignInTimestamp;
    }

    if (lifecycle?.started_timestamp) {
        formattedStartedTimestamp = dayjs(lifecycle.started_timestamp).calendar(null, {
            lastDay: '[Yesterday at] h:mm a',
            lastWeek: '[Last] dddd [at] h:mm a',
            sameDay: '[Today at] h:mm a',
            sameElse: 'DD/MM/YY [at] h:mm a'
        });
        lifecycle.started_timestamp = formattedStartedTimestamp;
    }

};

export const resizeImage = (base64: string, maxSize = 256, imageType: 'jpeg' | 'png' = 'jpeg'): Promise<string> => {
    return new Promise((resolve, reject) => {
        const base64Img = new Image();
        let thumbnailBase64 = '';

        // Create the thumbmnail on image load
        base64Img.onload = () => {
            let height = maxSize;
            let width = maxSize;
            if (base64Img.height > base64Img.width) {
                width = (base64Img.width / base64Img.height) * maxSize;
            } else if (base64Img.width > base64Img.height) {
                height = (base64Img.height / base64Img.width) * maxSize;
            }

            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d') as CanvasRenderingContext2D;

            // set its dimension to target size
            canvas.width = width;
            canvas.height = height;
            // draw source image into the off-screen canvas:
            context.drawImage(base64Img, 0, 0, width, height);
            // encode image to data-uri with base64 version of compressed image
            thumbnailBase64 = canvas.toDataURL('image/' + imageType, 100);
            return thumbnailBase64;
        };
        base64Img.src = base64;
        // After image load event has fired, resolve the thumbnail thumbnailBase64
        base64Img.addEventListener('load', () => {
            resolve(thumbnailBase64);
        });
        // If image error event has fired, reject
        base64Img.addEventListener('error', (error) => {
            console.log('Audit resizeImage() addEventListener() error=', error);
            reject(error);
        });
    });
}

export const UpdateAuditShowInList = (audit: IAudit) => {
    if (audit.show_in_list && audit.show_in_list.length > 0) {
        for (let value of audit.show_in_list) {
            if (value.type) {
                if (value.type === 'ta-date') {
                    value.answer = dayjs(value.answer as string).format('DD/MM/YYYY');
                } else if (value.type === 'ta-time') {
                    value.answer = dayjs(value.answer as string).format('HH:mm');
                }
            } else {
                if (typeof value.answer !== 'object') {
                    if (value.label.toLowerCase().includes('time')) {
                        value.answer = dayjs(value.answer).format('HH:mm');
                    } else if (value.answer.match(/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/) || value.answer.match(/(\d{4})-(\d{2})-(\d{2})/)) {
                        value.answer = dayjs(value.answer).format('DD/MM/YYYY');
                    }
                }
            }
        }
    }
}

export const PrepareAssetQuestionsForList = (asset: IAsset) => {
    const filteredQuestionsWithShowInList = asset.sections[0]?.questions?.filter(question => question.showInList && question.answer) || [];
    asset.sections[0].questions = filteredQuestionsWithShowInList;
};

export const Countries = (): string[] => ['Albania', 'Algeria', 'Angola', 'Antigua and Barbuda', 'Argentina', 'Armenia', 'Aruba', 'Australia', 'Austria', 'Azerbaijan', 'Bahamas', 'Bahrain', 'Bangladesh', 'Belarus', 'Belgium', 'Belize', 'Benin', 'Bolivia', 'Bosnia and Herzegovina', 'Botswana', 'Brazil', 'Bulgaria', 'Burkina Faso', 'Cambodia', 'Cameroon', 'Canada', 'Cape Verde', 'Chile', 'China', 'Colombia', 'Costa Rica', 'Croatia', 'Cuba', 'Cyprus', 'Czech Republic', 'Côte d’Ivoire', 'Denmark', 'Dominican Republic', 'Ecuador', 'Egypt', 'El Salvador', 'Estonia', 'Fiji', 'Finland', 'France', 'Gabon', 'Germany', 'Ghana', 'Greece', 'Guatemala', 'Guinea-Bissau', 'Haiti', 'Honduras', 'Hong Kong', 'Hungary', 'Iceland', 'India', 'Indonesia', 'Iran', 'Ireland', 'Israel', 'Italy', 'Jamaica', 'Japan', 'Jordan', 'Kazakhstan', 'Kenya', 'Kuwait', 'Kyrgyzstan', 'Laos', 'Latvia', 'Lebanon', 'Liechtenstein', 'Lithuania', 'Luxembourg', 'Macau', 'Macedonia', 'Malaysia', 'Mali', 'Malta', 'Mauritius', 'Mexico', 'Moldova', 'Morocco', 'Mozambique', 'Myanmar', 'Namibia', 'Nepal', 'Netherlands', 'Netherlands Antilles', 'New Zealand', 'Nicaragua', 'Niger', 'Nigeria', 'Norway', 'Oman', 'Pakistan', 'Panama', 'Papua New Guinea', 'Paraguay', 'Peru', 'Philippines', 'Poland', 'Portugal', 'Qatar', 'Romania', 'Russia', 'Rwanda', 'Saudi Arabia', 'Senegal', 'Serbia', 'Singapore', 'Slovakia', 'Slovenia', 'South Africa', 'South Korea', 'Spain', 'Sri Lanka', 'Sudan', 'Sweden', 'Switzerland', 'Taiwan', 'Tajikistan', 'Tanzania', 'Thailand', 'Togo', 'Trinidad and Tobago', 'Tunisia', 'Turkey', 'Turkmenistan', 'Uganda', 'Ukraine', 'United Arab Emirates', 'United Kingdom', 'United States', 'Uruguay', 'Uzbekistan', 'Venezuela', 'Vietnam', 'Yemen', 'Zambia', 'Zimbabwe'];
