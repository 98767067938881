import { FormGroup, ValidationErrors } from '@angular/forms';

import { get } from 'lodash-es';

export class TaCheckboxValidator {

    /**
     * @func validate
     * @description ensures that at least one of the checkboxes is selected.
     * @returns null if valid
     */
    static validate() {
        return (checkboxFormControls: FormGroup): ValidationErrors | null => {
            let valid = false;
            // Get checkbox FormControls
            let checkboxName: string;
            // If checkboxFormControls['controls'] exists
            if (checkboxFormControls !== null && checkboxFormControls.controls !== null) {
                // For each checkbox
                for (checkboxName in checkboxFormControls.value) {
                    if (checkboxFormControls.value.hasOwnProperty(checkboxName)) {
                        // Get the specific control then get the value of the control
                        const control = get(checkboxFormControls.controls, checkboxName, null);
                        if (control) {
                            const value = control.value;
                            if (value) {
                                valid = true;
                                break;
                            }
                        }
                    }
                }
            }
            // If valid is true i.e. at least one checkbox is checked, return null (set group to valid)
            if (valid) {
                return null;
            } else {
                return { checkboxRequired: true };
            }
        };
    }
}
