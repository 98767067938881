import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { IonInfiniteScroll, ModalController } from '@ionic/angular';

import { ApiService } from '@services/api.service';

import { IAsset, IScannedAsset, IUserContact } from '@models/types';

@Component({
    selector: 'app-user-select-modal',
    templateUrl: './user-select-modal.page.html',
    styleUrls: ['./user-select-modal.page.scss'],
})
export class UserSelectModalPage implements OnInit {

    @ViewChild(IonInfiniteScroll, { static: false }) infiniteScroll: IonInfiniteScroll;

    public isDataLoaded = false;
    public isSearching = false;
    public usersFormGroup = new FormGroup({});

    public searchQuery: string;
    public selectedUsers: (IScannedAsset & { contact: Partial<IUserContact> })[] = [];
    public users: (IAsset & { contact: Partial<IUserContact>; selected: boolean })[];
    private userFormConfigId: string = '';

    public multi: boolean;
    private currentlySelectedUsers: string[];

    private itemsPerPage = 10;
    private page = 0;

    constructor(
        private apiService: ApiService,
        private modalController: ModalController
    ) { }

    async ngOnInit() {
        await this.getUsers();
        this.isDataLoaded = true;
        setTimeout(() => this.shouldLoadMoreData());
    }

    public selectUser() {
        for (const user of this.users) {
            const selectedUserIndex = this.selectedUsers.findIndex(u => u.asset_id === user.id);
            if (this.usersFormGroup.value[user.id] === true) {
                if (selectedUserIndex === -1) {
                    const assetIdentifier = user.sections[0].questions[0];
                    const scannedUser: (IScannedAsset & { contact: Partial<IUserContact> }) = {
                        asset_id: user.id,
                        asset_identifier: assetIdentifier.answer,
                        contact: user.contact,
                        label: assetIdentifier.label,
                        value: assetIdentifier.label + ': ' + assetIdentifier.answer,
                        sections: [{
                            questions: user.sections[0].questions.filter(question => question.showInList && question.answer)
                        }]
                    };
                    this.selectedUsers.push(scannedUser);
                }
            } else if (this.usersFormGroup.value[user.id] === false) {
                if (this.selectedUsers.length > 0) {
                    if (selectedUserIndex > -1) {
                        this.selectedUsers.splice(selectedUserIndex, 1);
                    }
                }
            }
        }

        if (!this.multi) {
            this.usersFormGroup.enable();
            if (this.selectedUsers.length === 1) {
                this.usersFormGroup.disable();
                for (const user of this.users) {
                    const formControl = this.usersFormGroup.controls[user.id] as FormControl;
                    if (formControl.value === true) {
                        formControl.enable();
                        break;
                    }
                }
            }
        }
    }

    public addUsers() {
        this.modalController.dismiss(this.selectedUsers);
    }

    private async getUsers(emptyArray?: boolean) {
        const offset = this.page * this.itemsPerPage;
        const data = await this.apiService.getAssetsByAssetType(this.userFormConfigId, 'active', this.itemsPerPage, offset, this.searchQuery) as (IAsset & { contact: Partial<IUserContact>; selected: boolean })[];
        const users = this.updateSelectedUsers(data);
        if (!this.users || emptyArray) {
            this.users = [];
        }
        this.initialiseUsersFormGroup(users);
        this.users.push(...users);
        this.isDataLoaded = true;
    }

    private initialiseUsersFormGroup(users: (IAsset & { contact: Partial<IUserContact>; selected: boolean })[]) {
        const isControlDisabled = (!this.multi && this.selectedUsers.length === 1) ? true : false;
        for (const user of users) {
            this.usersFormGroup.addControl(user.id, new FormControl(isControlDisabled ? { value: false, disabled: true } : user.selected ? { value: true, disabled: true } : false));
        }
    }

    private updateSelectedUsers(users: (IAsset & { contact: Partial<IUserContact>; selected: boolean })[]) {
        for (const user of users) {
            if (this.currentlySelectedUsers.length > 0) {
                const currentlySelectedUser = this.currentlySelectedUsers.find((id) => id === user.id);
                if (currentlySelectedUser) {
                    user.selected = true;
                }
            }
        }
        return users;
    }

    public searchbarChange(e: HTMLIonSearchbarElement) {
        setTimeout(async () => {
            this.isSearching = true;
            const query: string = e.value;
            this.searchQuery = query.trim();
            e.blur();
            this.page = 0;
            await this.getUsers(true);
            if (this.infiniteScroll?.disabled === true) {
                this.infiniteScroll.disabled = false;
            }
            setTimeout(() => this.shouldLoadMoreData());
            this.isSearching = false;
        });
    }

    public clearSearch(e: HTMLIonSearchbarElement) {
        setTimeout(async () => {
            this.isSearching = true;
            e.blur();
            this.searchQuery = null;
            this.page = 0;
            await this.getUsers(true);
            if (this.infiniteScroll.disabled === true) {
                this.infiniteScroll.disabled = false;
            }
            setTimeout(() => this.shouldLoadMoreData());
            this.isSearching = false;
        });
    }

    public async loadMoreData(event: any) {
        this.page++;
        await this.getUsers();
        event.target.complete();
        this.shouldLoadMoreData();
    }

    private shouldLoadMoreData() {
        if (this.infiniteScroll) {
            const max = (this.page + 1) * this.itemsPerPage;
            if (this.users.length < max) {
                this.infiniteScroll.disabled = true;
            }
        }
    }

    public async closeModal() {
        await this.modalController.dismiss();
    }

}
