import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms/';

import { AlertController, ToastController } from '@ionic/angular';

import { FieldType } from '@ngx-formly/core';

import { AuditService } from '@services/audit.service';

import { get, set } from 'lodash-es';
import { Subscription } from 'rxjs';
import { v4 } from 'uuid';

const TAG = 'TaRfidComponent';

@Component({
    selector: 'app-ta-rfid',
    templateUrl: 'ta-rfid.html',
    styleUrls: ['ta-rfid.scss']
})
export class TaRfidComponent extends FieldType implements OnInit {

    public rfid = '';
    public isNFCEnabled = true;
    public isNFCSupported = true;
    public nfcTagSubscription: Subscription;
    private updatedKey: string;

    constructor(
        private alertController: AlertController,
        private auditService: AuditService,
        private changeDetectorRef: ChangeDetectorRef,
        private toastController: ToastController
    ) {
        super();
    }

    ngOnInit() {
        // Get existing RFID tag value if it exists
        this.updatedKey = this.key as string;
        if (this.field.parent?.key && this.field.parent?.parent.key) {
            this.updatedKey = this.field.parent?.parent.key + '[' + this.field.parent?.key + '].' + this.key;
        }

        const rfid = get(this.model, this.key, '');
        if (rfid) {
            this.rfid = rfid;
        }

    }

    public async startScanRfidTag() {
        const prompt = await this.alertController.create({
            header: 'Scan RFID',
            inputs: [
                {
                    name: 'rfid_tag',
                    placeholder: 'Enter RFID Tag'
                },
            ],
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel'
                },
                {
                    text: 'Submit',
                    handler: async (data) => {
                        if (data && data.rfid_tag) {
                            this.rfid = data.rfid_tag;
                            this.saveRFID(data.rfid_tag);
                        } else {
                            const toast = await this.toastController.create({ header: 'Value cannot be empty', color: 'danger', position: 'top', duration: 2000, })
                            toast.present();
                            return false;
                        }
                    }
                }
            ],
            backdropDismiss: false
        });
        await prompt.present();
    }

    public async openDeleteRfidModal() {
        const deleteRfidModal = await this.alertController.create({
            header: 'Delete Scanned RFID Tag',
            message: 'Do you want to delete this scanned RFID tag?',
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel'
                },
                {
                    text: 'Delete',
                    handler: () => {
                        this.deleteRfidTag();
                    }
                }
            ],
            backdropDismiss: false
        });
        await deleteRfidModal.present();
    }

    private saveRFID(rfid?: string) {
        set(this.model, this.key, this.rfid);
        const formControl = this.formControl as FormControl;
        /* If a rfid string has been given, set the formControl value to the string
          else, set the formControl value to null  */
        if (rfid) {
            formControl.setValue(this.rfid);
            this.auditService.setLocationAndTimestamp(this.updatedKey);
        } else {
            formControl.reset(null);
            this.auditService.unsetLocationAndTimestamp(this.updatedKey);
        }

        // TODO - Ionic bug
        //https://stackoverflow.com/questions/61237069/ionic-5-i-need-to-detect-changes-to-an-array-after-passing-from-a-child-page-t
        this.changeDetectorRef.detectChanges();
    }

    private deleteRfidTag() {
        this.rfid = '';
        this.saveRFID();
    }

}
