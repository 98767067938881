import { Component, HostListener, OnInit } from '@angular/core';

import { FieldType } from '@ngx-formly/core';

import { AuditService } from '@services/audit.service';

import { ITaFormlyOptions } from '@models/types';

import { set, unset } from 'lodash-es';

@Component({
    selector: 'app-ta-pass-fail-na',
    templateUrl: 'ta-pass-fail-na.html',
    styleUrls: ['ta-pass-fail-na.scss']
})
export class TaPassFailNaComponent extends FieldType implements OnInit {

    private path: string;
    private failPath: string;
    private updatedKey: string;

    constructor(
        private auditService: AuditService
    ) {
        super();
    }

    @HostListener('ionChange')
    public ionChange() {
        this.checkOptionChanges();
    }

    ngOnInit() {
        this.updatedKey = this.key as string;
        if (this.field.parent?.key && this.field.parent?.parent.key) {
            this.updatedKey = this.field.parent?.parent.key + '[' + this.field.parent?.key + '].' + this.key;
        }
        // Get the path of the field by removing ".value" from the field's key
        this.path = (this.key as string).substr(0, (this.key as string).lastIndexOf('.'));
        this.failPath = this.path + '.failed';
    }

    public clear() {
        this.formControl.reset(null);
    }

    private checkOptionChanges() {
        if (this.formControl.value) {
            const options = this.props.options as ITaFormlyOptions[];
            let answer: string;
            for (const option of options) {
                if (option.key === this.formControl.value) {
                    answer = option.value;
                    if (option.failedResponse) {
                        set(this.model, this.failPath, option.severity);
                    } else {
                        set(this.model, this.failPath, null);
                    }
                }
            }

            if (this.props.showInList) {
                const value = { key: this.updatedKey as string, label: this.props.label, answer, type: this.field.type as string };
                this.auditService.addShowInListValue(value);
            }

            this.auditService.setLocationAndTimestamp(this.updatedKey);
        } else {
            unset(this.model, this.failPath);

            if (this.props.showInList) {
                this.auditService.removeShowInListValue(this.updatedKey as string);
            }

            this.auditService.unsetLocationAndTimestamp(this.updatedKey);
        }
    }

}
