<ion-item-group *ngIf="show">
    <ng-container *ngIf="type">
        <ion-item-divider sticky="true" [className]="type">
            <ion-skeleton-text animated style="width: 30%" *ngIf="type === 'header'"></ion-skeleton-text>
            <ion-item lines="full" *ngIf="type === 'menu'">
                <ion-skeleton-text animated style="width: 27px; height: 27px" slot="start"></ion-skeleton-text>
                <ion-skeleton-text animated style="width: 27px; height: 27px" slot="end"></ion-skeleton-text>
            </ion-item>
        </ion-item-divider>
    </ng-container>
    <ion-list>
        <ion-item *ngFor="let listItem of listItems">
            <ion-label>
                <ion-label>
                    <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
                </ion-label>
                <ion-label>
                    <ion-skeleton-text animated style="width: 55%"></ion-skeleton-text>
                </ion-label>
                <ion-label>
                    <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
                </ion-label>
            </ion-label>
        </ion-item>
    </ion-list>
</ion-item-group>