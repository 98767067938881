import { Component, HostListener, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

import { FieldType } from '@ngx-formly/core';

import { AuditService } from '@services/audit.service';

import { get } from 'lodash-es';

import * as dayjs from 'dayjs';
import * as customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

@Component({
    selector: 'app-ta-datetimepicker',
    templateUrl: 'ta-datetimepicker.html',
    styleUrls: ['ta-datetimepicker.scss']
})
export class TaDateTimePickerComponent extends FieldType implements OnInit {

    public maxDate = '';
    public currentValue: string;
    private updatedKey: string;

    constructor(
        private auditService: AuditService
    ) {
        super();
    }
    get dateTimeFormControl() {
        return this.formControl as FormControl;
    }

    @HostListener('ionChange')
    public ionChange(event) {
        this.dateTimeChanges();
    }

    ngOnInit() {
        this.updatedKey = this.key as string;
        if (this.field.parent?.key && this.field.parent?.parent.key) {
            this.updatedKey = this.field.parent?.parent.key + '[' + this.field.parent?.key + '].' + this.key;
        }

        if (this.field.props.type === 'date') {
            // Max date set to five years from current day
            this.maxDate = dayjs().add(5, 'years').format('YYYY-MM-DD');
        }
        if (this.formControl.value) {
            this.currentValue = this.formControl.value;
        }
    }

    public clear() {
        this.currentValue = null;
        // The formControl.reset triggers an ionChange event with a null formControl.value
        this.formControl.reset(null);
    }

    private dateTimeChanges() {
        if (this.formControl.value) {
            if (this.props.showInList) {
                const value = { key: this.updatedKey as string, label: this.props.label, answer: this.formControl.value, type: this.field.type as string };
                this.auditService.addShowInListValue(value);
            }
            this.auditService.setLocationAndTimestamp(this.updatedKey);
        } else {
            if (this.props.showInList) {
                this.auditService.removeShowInListValue(this.updatedKey as string);
            }
            this.auditService.unsetLocationAndTimestamp(this.updatedKey);
        }
    }

}
