import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TypeofPipe } from './typeof.pipe';
import { ProjectSelectPipe } from './project-select.pipe';
import { UserSelectPipe } from './user-select.pipe';

const PIPES = [
    TypeofPipe,
    ProjectSelectPipe,
    UserSelectPipe
];

@NgModule({
    declarations: [PIPES, ProjectSelectPipe],
    imports: [
        CommonModule
    ],
    exports: [PIPES]
})
export class PipesModule { }
