<ion-item lines='none'>
    <ion-button tabIndex="1" class="ion-margin-right-0" slot="end" color="secondary" fill="clear" (click)="clear()"
        [disabled]="disableClear">
        Clear
        <ion-icon slot="end" name="close-circle-outline" class="ion-label-font-xlg"></ion-icon>
    </ion-button>
</ion-item>

<ion-card-content class="ion-padding-horizontal-2">
    <svg viewBox="-190 30 550 215">
        <g id="layer2">
            <rect ry="3.2352903" [attr.y]="[y]" x="58.280872" [attr.height]="[height]" width="50.781212" id="rect4518-9"
                [ngStyle]="{'fill' : rangeValue === null || rangeValue === undefined ? '#e2e2e2' : '#fbe300'}"
                style="fill-opacity:1;stroke:none;stroke-width:1.92782164;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" />
        </g>
        <g style="display:inline" id="layer1">
            <rect transform="scale(1,-1)" ry="0.26726952" y="-77.571716" x="76.171814" height="0.53453904"
                width="32.072342" id="rect4522"
                [ngStyle]="{'stroke' : rangeValue === null || rangeValue === undefined ? '#e2e2e2' : '#3c3b3c'}"
                style="fill:none;fill-opacity:1;stroke-width:2.93199992;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" />
            <rect [ngStyle]="{'stroke' : rangeValue === null || rangeValue === undefined ? '#e2e2e2' : '#ff0000'}"
                style="fill:none;fill-opacity:1;stroke-width:2.93199992;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
                id="rect4539" width="32.072342" height="0.53453904" x="76.171814" y="-213.87918" ry="0.26726952"
                transform="scale(1,-1)" />
            <rect [ngStyle]="{'stroke' : rangeValue === null || rangeValue === undefined ? '#e2e2e2' : '#3c3b3c'}"
                style="fill:none;fill-opacity:1;stroke-width:2.93199992;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
                id="rect4543" width="32.072342" height="0.53453904" x="76.171814" y="-156.14896" ry="0.26726952"
                transform="scale(1,-1)" />
            <rect ry="7.4835467" y="46.301182" x="58.280872" height="193.50314" width="50.781212" id="rect4518"
                [ngStyle]="{'stroke' : rangeValue === null || rangeValue === undefined ? '#e2e2e2' : '#3c3b3c'}"
                style="fill:none;fill-opacity:1;stroke-width:2.93199992;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" />
        </g>
    </svg>
</ion-card-content>

<ion-item lines="none">
    <ion-range [min]="props.min" [max]="props.max" color="secondary" [formControl]="$any(formControl)"
        [debounce]="debounceTime" [taFormlyAttributes]="field" pin="true" [pinFormatter]="pinFormatter"
        (ionInput)="onIonInput($event)">
        <ion-label class="ion-label-font-xs" slot="start" color="medium">{{props.min}}%</ion-label>
        <ion-label class="ion-label-font-xs" slot="end" color="medium">{{props.max}}%</ion-label>
    </ion-range>
</ion-item>