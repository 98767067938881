<ion-item lines="none">
    <ion-label>
        <ion-label class="ion-label-font-md ion-text-wrap ion-font-weight-medium">
            {{asset.identifiers.asset_identifier}}
        </ion-label>
        <ng-container *ngIf="asset.contact">
            <ion-label class="ion-text-wrap ion-label-font-xs">
                <ion-icon class="question-label-icon ion-margin-right-1" slot="start" name="ellipse" color="secondary">
                </ion-icon>
                {{asset.contact.first_name + ' ' + asset.contact.last_name}}
            </ion-label>
        </ng-container>
        <ng-container *ngFor="let question of asset.sections[0].questions">
            <ng-container *ngIf="question.type === 'ta-checkbox'; else showRadio">
                <ion-label class="ion-text-wrap ion-label-font-xs">
                    <ion-icon class="question-label-icon ion-margin-right-1" slot="start" name="ellipse"
                        color="secondary">
                    </ion-icon>
                    {{ question.label }}
                </ion-label>
                <ng-container *ngFor="let option of question.options">
                    <ng-container *ngIf="question.answer[option.key]">
                        <ion-label class="ion-text-wrap ion-label-font-xs ion-margin-left-3">
                            - {{ option.value }}
                        </ion-label>
                    </ng-container>
                </ng-container>
            </ng-container>
            <ng-template #showRadio>
                <ng-container *ngIf="question.type === 'ta-radio'; else showDate">
                    <ng-container *ngFor="let option of question.options">
                        <ng-container *ngIf="question.answer === option.key">
                            <ion-label class="ion-text-wrap ion-label-font-xs">
                                <ion-icon class="question-label-icon ion-margin-right-1" slot="start" name="ellipse"
                                    color="secondary">
                                </ion-icon>
                                {{ question.label + ' - ' + option.value }}
                            </ion-label>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </ng-template>
            <ng-template #showDate>
                <ng-container *ngIf="question.type === 'ta-date'; else showOthers">
                    <ion-label class="ion-text-wrap ion-label-font-xs">
                        <ion-icon class="question-label-icon ion-margin-right-1" slot="start" name="ellipse"
                            color="secondary">
                        </ion-icon>
                        {{question.label + ': ' + (question.answer | date:'dd/MM/yyyy')}}
                    </ion-label>
                </ng-container>
            </ng-template>
            <ng-template #showOthers>
                <ion-label class="ion-text-wrap ion-label-font-xs">
                    <ion-icon class="question-label-icon ion-margin-right-1" slot="start" name="ellipse"
                        color="secondary">
                    </ion-icon>
                    {{question.label + ' - ' + question.answer}}
                </ion-label>
            </ng-template>
        </ng-container>
    </ion-label>
</ion-item>