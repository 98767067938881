import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';

import { Capacitor } from '@capacitor/core';
import { Camera, CameraResultType, CameraSource, ImageOptions } from '@capacitor/camera';
import { ActionSheetController, AlertController, ModalController } from '@ionic/angular';

import { v4 } from 'uuid';

import { CommentsModalPage } from '@modals/comments-modal/comments-modal';
import { ImageViewerModalPage } from '@modals/image-viewer-modal/image-viewer-modal.page';

import { AuditService } from '@services/audit.service';
import { ConfigService } from '@services/config.service';

import { resizeImage } from '@library/Utils';

import { ITaFormlyTemplateOptions, IThumbnailImage } from '@models/types';

import { get, set } from 'lodash-es';

const TAG = 'QuestionAdditionalFieldsComponent';

/**
 * AdditionalFields component.
 *
 * Component that dynamically shows a comments button, a photos button or a combination
 * of both. All photos/comment changes are handled here by obtaining and modifying the
 * respective formControls and editing the parent question's model directly.
 */
@Component({
    selector: 'app-question-additional-fields',
    templateUrl: 'question-additional-fields.html',
    styleUrls: ['question-additional-fields.scss']
})
export class QuestionAdditionalFieldsComponent implements OnInit {

    @Input() model: any;
    @Input() key: string | number | string[];
    @Input() props: ITaFormlyTemplateOptions;

    public comments = '';
    public thumbnails: IThumbnailImage[] = [];

    private commentsPath: string;
    private photosPath: string;

    constructor(
        protected actionSheetCtrl: ActionSheetController,
        private alertController: AlertController,
        public auditService: AuditService,
        public changeDetectorRef: ChangeDetectorRef,
        public configService: ConfigService,
        protected modalController: ModalController
    ) { }

    ngOnInit() {
        this.commentsPath = (this.key as string).substring(0, (this.key as string).lastIndexOf('.')) + '.comments';
        this.photosPath = (this.key as string).substring(0, (this.key as string).lastIndexOf('.')) + '.photos';

        const comments = get(this.model, this.commentsPath, '');
        if (comments) {
            this.comments = comments;
        }

        const savedPhotos: IThumbnailImage[] = get(this.model, this.photosPath, []);
        if (savedPhotos) {
            if (savedPhotos.length > 0) {
                this.getThumbnails(savedPhotos);
            }
        }
    }

    public async getThumbnails(savedPhotos: IThumbnailImage[]) {
        const METHOD = 'getThumbnails';
        try {
            for (const savedPhoto of savedPhotos) {
                this.thumbnails.push({
                    image_id: savedPhoto.image_id,
                    base64: savedPhoto.base64
                });
            };
            this.changeDetectorRef.detectChanges();
        } catch (error) {
            console.error(TAG + METHOD, 'error=', error);
            throw error;
        }
    }

    public async takePicture() {
        const METHOD = 'takePicture';
        try {
            const cameraOptions: ImageOptions = {
                quality: 25,
                allowEditing: false,
                resultType: CameraResultType.DataUrl,
                source: CameraSource.Camera,
                correctOrientation: true
            };
            const capturedImageData = await Camera.getPhoto(cameraOptions);
            await this.processImage(capturedImageData.dataUrl);
            await this.savePhotos();
        } catch (error) {
            console.error(TAG + METHOD, 'error=', error);
        }
    }

    public async attachPhotos() {
        const METHOD = 'attachPhotos';
        try {
            const capturedPhotos = await Camera.pickImages({
                quality: 25
            });

            for (const photo of capturedPhotos.photos) {
                await this.processImage(photo.webPath);
            }

            await this.savePhotos();
        } catch (error) {
            console.error(TAG + METHOD, 'error=', error);
        }
    }

    public async presentImageActionSheet(thumbnails: IThumbnailImage[], index: number) {
        const METHOD = 'presentImageActionSheet';
        const actionSheet = await this.actionSheetCtrl.create({
            header: 'Image Options',
            buttons: [
                {
                    text: 'View',
                    handler: async () => {
                        const imageViewerModal = await this.modalController.create({
                            component: ImageViewerModalPage,
                            componentProps: {
                                images: thumbnails.map(thumbnail => thumbnail.base64),
                                imageIndex: index
                            }
                        });
                        await imageViewerModal.present();
                    }
                },
                {
                    text: 'Delete',
                    role: 'destructive',
                    handler: async () => {
                        try {
                            this.thumbnails.splice(index, 1);
                            this.changeDetectorRef.detectChanges();
                        } catch (error) {
                            console.error(TAG + METHOD, 'deleteDocument error=', error);
                        }
                        await this.savePhotos();
                    }
                }, {
                    text: 'Cancel',
                    role: 'cancel'
                }
            ],
            backdropDismiss: false
        });
        await actionSheet.present();
    }

    public async presentCommentsModal(comments: string) {
        const commentsModal = await this.modalController.create({
            component: CommentsModalPage,
            componentProps: {
                comments
            },
            canDismiss: true
        });
        await commentsModal.present();

        const updatedComments = await commentsModal.onDidDismiss();
        if (updatedComments.role === 'update') {
            this.comments = updatedComments.data;
            await this.saveComments(this.comments);
            this.changeDetectorRef.detectChanges();
        }
    }

    private async processImage(base64: string) {
        const METHOD = 'processImage';
        try {
            const thumbnail = await resizeImage(base64);
            const imageId = 'audit_image::' + v4().replace(/-/g, '');
            this.thumbnails.push({
                image_id: imageId,
                base64: thumbnail,
            });
            this.changeDetectorRef.detectChanges();
        } catch (error) {
            console.error(TAG + METHOD, 'error=', error);
            throw error;
        }
    }

    private async savePhotos() {
        set(this.model, this.photosPath, this.thumbnails);
    }


    /**
     * Sets the commentsFormControl value and the question's comments property to the comments
     * that are passed in.
     *
     * @param comments the comments that are provided by the commentsModal
     */
    private async saveComments(comments: string) {
        set(this.model, this.commentsPath, comments);
    }

}
