import { Component, HostListener, OnInit } from '@angular/core';

import { FieldType } from '@ngx-formly/core';

import { AuditService } from '@services/audit.service';

import { set } from 'lodash';

import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

@Component({
    selector: 'app-ta-date',
    templateUrl: './ta-date.html',
    styleUrls: ['./ta-date.scss'],
})
export class TaDateComponent extends FieldType implements OnInit {

    public currentValue: string = null;
    private updatedKey: string;

    constructor(
        private auditService: AuditService
    ) {
        super();
    }

    @HostListener('ionChange')
    public ionChange() {
        this.dateChanges();
    }

    ngOnInit() {
        this.updatedKey = this.key as string;
        if (this.field.parent?.key && this.field.parent?.parent.key) {
            this.updatedKey = this.field.parent?.parent.key + '[' + this.field.parent?.key + '].' + this.key;
        }
        if (this.props.autoIncrement && this.props.autoIncrement.increment) {
            this.currentValue = dayjs().utc(true).add(this.props.autoIncrement.value, this.props.autoIncrement.unit).format('DD/MM/YYYY');
        } else {
            if (this.formControl.value) {
                this.currentValue = dayjs(this.formControl.value).utc(true).format('YYYY-MM-DD');
            }

            this.formControl.valueChanges.subscribe((value: string) => {
                if (value) {
                    this.currentValue = dayjs(value).utc(true).format('YYYY-MM-DD');
                }
            });
        }
    }

    public clear() {
        this.currentValue = null;
        this.formControl.reset(null);
    }

    private dateChanges() {
        if (this.currentValue) {
            const dateValue = dayjs(this.currentValue).utc(true).toISOString();
            set(this.model, this.key, dateValue);
            this.formControl.setValue(dateValue, { emitEvent: false });

            if (this.props.showInList) {
                const value = { key: this.updatedKey as string, label: this.props.label, answer: dateValue, type: this.field.type as string };
                this.auditService.addShowInListValue(value);
            }
            this.auditService.setLocationAndTimestamp(this.updatedKey);
        } else {
            set(this.model, this.key, null);
            this.formControl.setValue(null, { emitEvent: false });

            if (this.props.showInList) {
                this.auditService.removeShowInListValue(this.updatedKey as string);
            }
            this.auditService.unsetLocationAndTimestamp(this.updatedKey);
        }
    }

}
