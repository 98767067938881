<ion-card-content class="ion-padding-horizontal-2 ion-padding-vertical-0">
    <ion-item lines="none" *ngIf="barcode && barcode.length > 0">
        <ion-label class="ion-label-font-sm ion-text-wrap">{{ barcode }}</ion-label>
    </ion-item>

    <ion-grid>
        <ion-row>
            <ion-col size-sm="6" offset-sm="3" size-xs="12">
                <ion-button tabIndex="1" class="ion-label-font-sm" color="primary" expand="block" *ngIf="!barcode"
                    (click)="scanBarcode()">
                    Scan Barcode
                    <ion-icon slot="end" name="scan-outline"></ion-icon>
                </ion-button>
                <ion-button tabIndex="1" class="ion-label-font-sm" color="danger" expand="block" *ngIf="barcode"
                    (click)="openDeleteModal()">
                    Delete
                    <ion-icon slot="end" name="trash-outline"></ion-icon>
                </ion-button>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-card-content>