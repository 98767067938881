import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

import { FieldType } from '@ngx-formly/core';

import { AuditService } from '@services/audit.service';

import { set } from 'lodash-es';

@Component({
    selector: 'app-ta-instruction',
    templateUrl: 'ta-instruction.html',
    styleUrls: ['ta-instruction.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaInstructionComponent extends FieldType implements OnInit {

    public disableButton = false;
    private updatedKey: string;

    constructor(
        private auditService: AuditService
    ) {
        super();
    }

    ngOnInit() {
        this.updatedKey = this.key as string;
        if (this.field.parent?.key && this.field.parent?.parent.key) {
            this.updatedKey = this.field.parent?.parent.key + '[' + this.field.parent?.key + '].' + this.key;
        }

        if (this.formControl.value === '' || this.formControl.value === null || this.formControl.value === undefined) {
            if (this.props.showInList) {
                const value = { key: this.updatedKey, label: this.props.label, answer: false, type: this.field.type as string };
                this.auditService.addShowInListValue(value as any);
            }
        }

        if (this.formControl.value === true) {
            this.disableButton = true;
        }
    }

    public async onUnderstoodClick() {
        this.disableButton = true;
        set(this.model, this.key, true);
        const formControl = this.formControl as FormControl;
        formControl.setValue(true);
        this.auditService.setLocationAndTimestamp(this.updatedKey);
        if (this.props.showInList) {
            const value = { key: this.updatedKey, label: this.props.label, answer: this.formControl.value, type: this.field.type as string };
            this.auditService.addShowInListValue(value);
        }
    }

}
