import { Component, HostListener, OnInit } from '@angular/core';

import { Platform } from '@ionic/angular';

import { FieldType } from '@ngx-formly/core';
import { AuditService } from '@services/audit.service';

@Component({
    selector: 'app-ta-oil-level-gauge',
    templateUrl: 'ta-oil-level-gauge.html',
    styleUrls: ['ta-oil-level-gauge.scss']
})
export class TaOilLevelGaugeComponent extends FieldType implements OnInit {

    public debounceTime = 10;
    public y = '239.804322';
    public height = '0';
    public rangeValue = null;

    public disableClear = true;

    private max = 239.804322;
    private range = 193.50314;
    private updatedKey: string;

    constructor(
        private platform: Platform,
        private auditService: AuditService
    ) {
        super();
    }

    @HostListener('ionBlur')
    public ionBlur() {
        if (!isNaN(parseFloat(this.formControl.value))) {
            if (this.props.showInList) {
                const value = { key: this.updatedKey as string, label: this.props.label, answer: this.formControl.value, type: this.field.type as string };
                this.auditService.addShowInListValue(value);
            }
        } else {
            if (this.props.showInList) {
                this.auditService.removeShowInListValue(this.updatedKey as string);
            }
        }
    }

    public onIonInput(event: any) {
        this.oilLevelGaugeChanges(event.detail.value);
    }

    ngOnInit() {
        this.updatedKey = this.key as string;
        if (this.field.parent?.key && this.field.parent?.parent.key) {
            this.updatedKey = this.field.parent?.parent.key + '[' + this.field.parent?.key + '].' + this.key;
        }

        if (this.platform.is('ios')) {
            this.debounceTime = 30;
        }
        const rangeValue = this.formControl.value;
        if (rangeValue) {
            const percentage = (rangeValue - this.props.min) / (this.props.max - this.props.min);
            const height = Math.round(percentage * this.range);
            this.height = height + '';
            this.y = (this.max - height) + '';
        }
    }

    public clear() {
        this.y = '239.804322';
        this.height = '0';
        // The formControl.reset triggers an ionChange event with a null formControl.value
        this.formControl.reset(null);
        this.rangeValue = null;
    }

    public pinFormatter(value: number) {
        return `${value}%`;
    }

    private oilLevelGaugeChanges(value: string) {
        if (!isNaN(parseFloat(value))) {
            this.rangeValue = value;
            const percentage = (this.rangeValue - this.props.min) / (this.props.max - this.props.min);
            const height = Math.round(percentage * this.range);
            this.height = height + '';
            this.y = (this.max - height) + '';
            this.disableClear = false;
            this.auditService.setLocationAndTimestamp(this.updatedKey);
        } else {
            this.disableClear = true;
            this.auditService.unsetLocationAndTimestamp(this.updatedKey);
        }
    }

}
