import { Component, OnInit } from '@angular/core';

import { ModalController } from '@ionic/angular';

import { FieldType } from '@ngx-formly/core';

import { SignatureModalPage } from '@modals/signature-modal/signature-modal';
import { AuditService } from '@services/audit.service';

/**
 * Formly-compatible Ionic signature component
 */
@Component({
    selector: 'app-ta-signature',
    templateUrl: 'ta-signature.html',
    styleUrls: ['ta-signature.scss']
})
export class TaSignatureComponent extends FieldType implements OnInit {

    public signature = '';
    private updatedKey: string;

    constructor(
        private auditService: AuditService,
        public modalController: ModalController,
    ) {
        super();
    }

    ngOnInit() {
        this.updatedKey = this.key as string;
        if (this.field.parent?.key && this.field.parent?.parent.key) {
            this.updatedKey = this.field.parent?.parent.key + '[' + this.field.parent?.key + '].' + this.key;
        }

        if (this.formControl.value) {
            this.signature = this.formControl.value;
        }
    }

    public async presentSignatureModal() {
        const signatureModal = await this.modalController.create({
            component: SignatureModalPage,
            componentProps: {
                type: 'edit'
            },
            canDismiss: true
        });
        await signatureModal.present();

        const signatureData = await signatureModal.onDidDismiss();
        if (signatureData.role === 'save') {
            if (signatureData.data) {
                this.signature = signatureData.data;
            } else {
                this.signature = '';
            }
            this.saveSignature();
        }
    }

    public removeSignature() {
        try {
            this.signature = '';
            this.saveSignature();
        } catch (error) {
            console.error('TaSignatureComponent', 'removeSignature(): ', 'deleteDocument error=', error);
        }
    }

    private saveSignature() {
        if (this.signature) {
            this.formControl.setValue(this.signature);
            this.auditService.setLocationAndTimestamp(this.updatedKey);
        } else {
            this.formControl.reset(null);
            this.auditService.unsetLocationAndTimestamp(this.updatedKey);
        }
    }

}
