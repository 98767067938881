import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ListSkeletonPageModule } from '@components/list-skeleton/list-skeleton.module';

import { AssetTypeSelectionModalPage } from './asset-type-selection-modal.page';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        ListSkeletonPageModule
    ],
    declarations: [AssetTypeSelectionModalPage],
    exports: [AssetTypeSelectionModalPage]
})
export class AssetTypeSelectionModalPageModule { }
