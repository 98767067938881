<ion-header>
    <ion-toolbar>
        <ion-title>Comments</ion-title>
        <ion-label class="ion-margin-right-2" slot="end" (click)="closeModal()">Close</ion-label>
    </ion-toolbar>
</ion-header>

<ion-content class="ion-text-center" scrollY="false">
    <ion-grid>
        <ion-row>
            <ion-col class="ion-no-padding comments-container">
                <ion-item lines="none">
                    <ion-textarea class="ion-label-font-sm" autocomplete="true" autocapitalize="true" autocorrect="true"
                        spellcheck="true" inputmode="text" [(ngModel)]="comments" placeholder="Add your comments..."
                        rows="15">
                    </ion-textarea>
                </ion-item>
            </ion-col>
            <ion-col class="ion-margin-top-3 ion-margin-bottom-top-1 ion-text-left" size="12">
                <ion-label class="ion-label-font-sm" color="medium">
                    Type in your comments above.
                </ion-label>
            </ion-col>
            <ion-col class="ion-margin-bottom-1 ion-text-left" size="12">
                <ion-label class="ion-label-font-sm" color="medium">
                    Once you are finished typing, click <ion-text color="primary">Save</ion-text>.
                </ion-label>
            </ion-col>
            <ion-col class="ion-text-left" size="12">
                <ion-label class="ion-label-font-sm" color="medium">
                    If you wish to clear your comments or start from scratch, click <ion-text color="danger">Clear
                    </ion-text> and type in your comments again.
                </ion-label>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>

<ion-footer>
    <ion-toolbar class="ion-no-padding">
        <ion-grid>
            <ion-row>
                <ion-col>
                    <ion-button color="danger" expand="block" (click)="clearComments()">
                        Clear
                    </ion-button>
                </ion-col>
                <ion-col>
                    <ion-button color="primary" expand="block" (click)="saveComments()">
                        Save
                    </ion-button>
                </ion-col>
            </ion-row>
        </ion-grid>
    </ion-toolbar>
</ion-footer>