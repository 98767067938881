import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

import { AuditService } from '@services/audit.service';

import { set } from 'lodash-es';

const TAG = 'TaCheckboxComponent';

@Component({
    selector: 'app-ta-checkbox',
    templateUrl: './ta-checkbox.html',
    styleUrls: ['./ta-checkbox.scss']
})

export class TaCheckboxComponent extends FieldType implements OnInit {

    private failPath: string;
    private updatedKey: string;

    constructor(
        private auditService: AuditService,
    ) {
        super();
    }

    ngOnInit() {
        // Get the path of the field by removing ".value" from the field's key
        this.updatedKey = this.key as string;
        if (this.field.parent?.key && this.field.parent?.parent.key) {
            this.updatedKey = this.field.parent?.parent.key + '[' + this.field.parent?.key + '].' + this.key;
        }
        this.failPath = (this.key as string).substring(0, (this.key as string).lastIndexOf('.')) + '.failed';
    }

    public onChange(value: string, checked: boolean) {
        const METHOD = 'onChange';
        const failed = {};
        const options = this.props.options as any[];

        this.formControl.patchValue({ ...this.formControl.value, [value]: checked });

        const answers = [];
        for (const option of options) {
            // If this is a failable response
            if (option.failedResponse) {
                if (option.failedIfChecked) {
                    if (this.formControl.value[option.key]) {
                        failed[option.key] = option.severity;
                    } else {
                        failed[option.key] = null;
                    }
                } else {
                    if (this.formControl.value[option.key]) {
                        failed[option.key] = null;
                    } else {
                        failed[option.key] = option.severity;
                    }
                }
            } else {
                failed[option.key] = null;
            }

            if (this.formControl.value[option.key]) {
                answers.push(option.value);
            }
        }

        set(this.model, this.failPath, failed);

        if (this.props.showInList) {
            if (answers.length > 0) {
                const value = { key: this.updatedKey as string, label: this.props.label, answer: answers, type: this.field.type as string };
                this.auditService.addShowInListValue(value);
            } else {
                this.auditService.removeShowInListValue(this.updatedKey as string);
            }
        }
    }

}
