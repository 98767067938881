import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ListSkeletonPageModule } from '@components/list-skeleton/list-skeleton.module';
import { AssetPageModule } from '@components/asset/asset.module';

import { AssetSelectModalPage } from './asset-select-modal.page';
import { PipesModule } from '@pipes/pipes.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        IonicModule,
        PipesModule,
        ListSkeletonPageModule,
        AssetPageModule,
    ],
    declarations: [AssetSelectModalPage],
    exports: [AssetSelectModalPage]
})
export class AssetSelectModalPageModule { }
