import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { AlertController, ModalController, ToastController } from '@ionic/angular';

import { ModalRoutingBasePage } from '@components/modal-routing-base/modal-routing-base.page';
import { AssetInformationModalPage } from '@modals/asset-information-modal/asset-information-modal.page';

import { ApiService } from '@services/api.service';
import { AssetInformationModalService } from '@modals/asset-information-modal/asset-information-modal.service';
import { AuditService } from '@services/audit.service';
import { FormlyService } from '@services/formly.service';

import { IAsset, IScannedAsset, ITaFormlyTemplateOptions } from '@models/types';

@Component({
    selector: 'app-single-asset-scan',
    templateUrl: './single-asset-scan.html',
    styleUrls: ['./single-asset-scan.scss']
})
export class SingleAssetScanComponent implements OnInit {

    @Input() scannedAsset: IScannedAsset;
    @Input() props: ITaFormlyTemplateOptions;
    @Input() key: string | number | string[];
    @Output() assetsEvent: EventEmitter<{ assets: IScannedAsset[]; currentAssetId?: string }> = new EventEmitter();

    public assetLabel = '';
    private title = '';

    public isNFCEnabled = true;
    public isNFCSupported = true;
    public assetFormConfigSet = true;

    private latestAssetFormConfigId: string;
    private identifierType: 'asset_identifier' | 'rfid_tag' | 'qr_code' | 'uhf_rfid_tag';

    constructor(
        private alertController: AlertController,
        private apiService: ApiService,
        private assetInformationModalService: AssetInformationModalService,
        private auditService: AuditService,
        private changeDetectorRef: ChangeDetectorRef,
        private formlyService: FormlyService,
        private modalController: ModalController,
        private toastController: ToastController,
    ) { }

    async ngOnInit() {
        if (this.props.asset_form_config_id) {
            if (this.props.asset_form_config_id === '(any)') {
                this.assetLabel = 'Identifier';
                this.title = 'Any';
            } else {
                await this.getLatestAssetFormConfig();
            }
        } else {
            this.assetFormConfigSet = false;
        }
    }

    private async getLatestAssetFormConfig() {
        const assetFormConfig = await this.apiService.getLatestAssetFormConfig(this.props.asset_form_config_id);
        this.latestAssetFormConfigId = assetFormConfig.id;
        this.assetLabel = assetFormConfig.sections[0]?.questions[0]?.fields[0]?.templateOptions?.label;
        this.title = assetFormConfig.title;
        this.changeDetectorRef.detectChanges();
    }

    public async presentInputTextPrompt(inputValue?: string) {
        this.identifierType = 'asset_identifier';
        const prompt = await this.alertController.create({
            header: this.title,
            inputs: [
                {
                    name: 'asset_identifier',
                    placeholder: 'Enter ' + this.assetLabel,
                    value: inputValue || ''
                },
            ],
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel'
                },
                {
                    text: 'Submit',
                    handler: async (data) => {
                        if (data && data.asset_identifier) {
                            this.processIdentifier(data.asset_identifier);
                        } else {
                            const toast = await this.toastController.create({ header: 'Value cannot be empty', color: 'danger', position: 'top', duration: 2000, })
                            toast.present();
                            return false;
                        }
                    }
                }
            ],
            backdropDismiss: false
        });
        await prompt.present();
    }

    public async scanQrCode(inputValue?: string) {
        this.identifierType = 'qr_code';
        const prompt = await this.alertController.create({
            header: this.title,
            inputs: [
                {
                    name: 'qr_code',
                    placeholder: 'Enter QR Code',
                    value: inputValue || ''
                },
            ],
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel'
                },
                {
                    text: 'Submit',
                    handler: async (data) => {
                        if (data && data.qr_code) {
                            this.processIdentifier(data.qr_code);
                        } else {
                            const toast = await this.toastController.create({ header: 'Value cannot be empty', color: 'danger', position: 'top', duration: 2000, })
                            toast.present();
                            return false;
                        }
                    }
                }
            ],
            backdropDismiss: false
        });
        await prompt.present();
    }

    public async startScanRfidTag(inputValue?: string) {
        this.identifierType = 'rfid_tag';
        const prompt = await this.alertController.create({
            header: this.title,
            inputs: [
                {
                    name: 'rfid_tag',
                    placeholder: 'Enter RFID Tag',
                    value: inputValue || ''
                },
            ],
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel'
                },
                {
                    text: 'Submit',
                    handler: async (data) => {
                        if (data && data.rfid_tag) {
                            this.processIdentifier(data.rfid_tag);
                        } else {
                            const toast = await this.toastController.create({ header: 'Value cannot be empty', color: 'danger', position: 'top', duration: 2000, })
                            toast.present();
                            return false;
                        }
                    }
                }
            ],
            backdropDismiss: false
        });
        await prompt.present();
    }

    public async presentUhfRfidInputTextPrompt(inputValue?: string) {
        this.identifierType = 'uhf_rfid_tag';
        const prompt = await this.alertController.create({
            header: 'Scan UHF RFID Tag',
            inputs: [
                {
                    name: 'uhf_rfid_tag',
                    placeholder: 'UHF RFID Tag',
                    value: inputValue || ''
                },
            ],
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel'
                },
                {
                    text: 'Submit',
                    handler: async (data) => {
                        if (data && data.uhf_rfid_tag) {
                            this.processIdentifier(data.uhf_rfid_tag);
                        } else {
                            const toast = await this.toastController.create({ header: 'Value cannot be empty', color: 'danger', position: 'top', duration: 2000, })
                            toast.present();
                            return false;
                        }
                    }
                }
            ],
            backdropDismiss: false
        });
        await prompt.present();
    }


    private async processIdentifier(identifierValue: string) {
        const assets: IAsset[] = await this.apiService.getAssetsByIdentifier(this.identifierType, identifierValue, this.latestAssetFormConfigId);
        if (assets.length === 0) {
            this.presentNoAssetFoundAlert(identifierValue);
        } else {
            if (assets.length === 1) {
                const asset = assets[0];
                const { id, asset_form_config_id } = asset;
                const toAssetFormConfigComparisonId = this.props.asset_form_config_id?.split('::v')[0];
                const assetFormConfigComparisonId = asset_form_config_id.split('::v')[0];

                if ((this.props?.asset_form_config_id && this.props.asset_form_config_id !== '(any)') && toAssetFormConfigComparisonId !== assetFormConfigComparisonId) {
                    this.presentWrongAssetTypeAlert(identifierValue);
                } else {
                    await this.confirmAsset(id, asset);
                }
            } else {
                this.presentMultiAssetInformationModal(assets);
            }
        }
    }

    private async confirmAsset(assetId: string, asset: IAsset) {
        const assetIdentifier: any = asset.sections[0].questions[0];
        const scannedAsset: IScannedAsset = {
            asset_id: assetId,
            asset_identifier: assetIdentifier.answer,
            label: assetIdentifier.label,
            value: assetIdentifier.label + ': ' + assetIdentifier.answer,
            sections: [{
                questions: asset.sections[0].questions.filter(question => question.showInList && question.answer)
            }]
        };
        this.scannedAsset = scannedAsset;

        if (this.formlyService.getFormConfig().api === 'v1.2') {
            this.auditService.setAnswersOnPrePopulateQuestions(this.key as string, asset);
        }

        this.saveAsset();
    }

    public async presentMultiAssetInformationModal(assets: IAsset[]) {
        const multiAssetInformationModal = await this.modalController.create({
            component: ModalRoutingBasePage,
            componentProps: {
                rootPage: AssetInformationModalPage,
                data: {
                    assets,
                    multi: false,
                    type: 'view',
                    isQuestion: true
                },
            },
            canDismiss: true
        });
        await multiAssetInformationModal.present();

        const response = await multiAssetInformationModal.onDidDismiss();
        if (response.data) {
            const assetId = response.data;
            const asset = assets.find(a => a.id === assetId);
            this.confirmAsset(assetId, asset);
        }
    }

    public async presentAssetInformationModal(assetId: string) {
        const asset = await this.apiService.getAsset(assetId);
        const assetInformationModal = await this.modalController.create({
            component: AssetInformationModalPage,
            componentProps: {
                assetId,
                latestAssetFormConfigId: this.props.asset_form_config_id,
                asset,
                multi: this.props.multi,
                type: 'view'
            },
            canDismiss: true
        });

        this.assetInformationModalService.pageType = 'modal';
        await assetInformationModal.present();
    }

    private async presentNoAssetFoundAlert(inputValue?: string) {
        let message = '';
        if (this.identifierType === 'rfid_tag') {
            message = 'This RFID tag has not been recognised.\nPlease try scanning again.';
        } else if (this.identifierType === 'qr_code') {
            message = 'This QR Code has not been recognised.\nPlease try scanning again.';
        } else if (this.identifierType === 'uhf_rfid_tag') {
            message = 'This UHF RFID Tag has not been recognised.\nPlease try again.';
        } else {
            message = 'The asset identifier has not been recognised.\nPlease try again.';
        }

        const noAssetFoundAlert = await this.alertController.create({
            header: 'Cannot find asset',
            message,
            buttons: [{
                text: 'Ok',
                handler: () => {
                    if (this.identifierType === 'asset_identifier') {
                        this.presentInputTextPrompt(inputValue);
                    }
                }
            }]
        });
        await noAssetFoundAlert.present();
    }

    private async presentWrongAssetTypeAlert(identifierValue: string) {
        let message = '';
        if (this.identifierType === 'rfid_tag' || this.identifierType === 'qr_code') {
            message = 'You have scanned the wrong type of asset.\nPlease try scanning another asset of type ' + this.title + '.';
        } else if (this.identifierType === 'asset_identifier' || this.identifierType === 'uhf_rfid_tag') {
            message = 'You have entered a ' + this.assetLabel + ' for the wrong type of asset.\nPlease try entering the ' + this.assetLabel + ' of another asset of type ' + this.title + '.';
        }

        const wrongAssetTypeAlert = await this.alertController.create({
            header: 'Cannot find asset',
            message,
            buttons: [{
                text: 'Ok',
                handler: () => {
                    if (this.identifierType === 'asset_identifier') {
                        this.presentInputTextPrompt(identifierValue);
                    }
                }
            }]
        });
        await wrongAssetTypeAlert.present();
    }

    public async presentDeleteAlert(assetValue: string) {
        const deleteAsset = await this.alertController.create({
            header: assetValue,
            message: 'Do you want to remove this asset?',
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel'
                },
                {
                    text: 'Delete',
                    handler: () => {
                        const assetId = this.scannedAsset.asset_id;
                        this.scannedAsset = null;
                        this.saveAsset(assetId);
                    }
                }
            ],
            backdropDismiss: false
        });
        await deleteAsset.present();
    }

    private saveAsset(currentAssetId?: string) {
        this.assetsEvent.emit({
            assets: this.scannedAsset ? [this.scannedAsset] : null,
            currentAssetId: currentAssetId ? currentAssetId : null
        });
    }

}
