import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { IonInfiniteScroll, ModalController } from '@ionic/angular';

import { ApiService } from '@services/api.service';

import { IAsset, IScannedAsset } from '@models/types';

@Component({
    selector: 'app-project-select-modal',
    templateUrl: './project-select-modal.page.html',
    styleUrls: ['./project-select-modal.page.scss'],
})
export class ProjectSelectModalPage implements OnInit {

    @ViewChild(IonInfiniteScroll, { static: false }) infiniteScroll: IonInfiniteScroll;

    public isDataLoaded = false;
    public isSearching = false;
    public projectsFormGroup = new FormGroup({});

    public selectedProjects: IScannedAsset[] = [];
    public projects: (IAsset & { selected: boolean })[];
    private projectFormConfigId: string = '';

    public searchQuery: string;
    public multi: boolean;
    private currentlySelectedProjects: string[];

    private itemsPerPage = 10;
    private page = 0;

    constructor(
        private apiService: ApiService,
        private modalController: ModalController
    ) { }

    async ngOnInit() {
        await this.getProjects();
        this.isDataLoaded = true;
        setTimeout(() => this.shouldLoadMoreData());
    }

    public selectProject() {
        for (const project of this.projects) {
            const selectedProjectIndex = this.selectedProjects.findIndex(p => p.asset_id === project.id);
            if (this.projectsFormGroup.value[project.id] === true) {
                if (selectedProjectIndex === -1) {
                    const assetIdentifier = project.sections[0].questions[0];
                    const scannedProject: IScannedAsset = {
                        asset_id: project.id,
                        asset_identifier: assetIdentifier.answer,
                        label: assetIdentifier.label,
                        value: assetIdentifier.label + ': ' + assetIdentifier.answer,
                        sections: [{
                            questions: project.sections[0].questions.filter(question => question.showInList && question.answer)
                        }]
                    };
                    this.selectedProjects.push(scannedProject);
                }
            } else if (this.projectsFormGroup.value[project.id] === false) {
                if (this.selectedProjects.length > 0) {
                    if (selectedProjectIndex > -1) {
                        this.selectedProjects.splice(selectedProjectIndex, 1);
                    }
                }
            }
        }

        if (!this.multi) {
            this.projectsFormGroup.enable();
            if (this.selectedProjects.length === 1) {
                this.projectsFormGroup.disable();
                for (const project of this.projects) {
                    const formControl = this.projectsFormGroup.controls[project.id] as FormControl;
                    if (formControl.value === true) {
                        formControl.enable();
                        break;
                    }
                }
            }
        }
    }

    public addProjects() {
        this.modalController.dismiss(this.selectedProjects);
    }

    private async getProjects(emptyArray?: boolean) {
        const offset = this.page * this.itemsPerPage;
        const data = await this.apiService.getAssetsByAssetType(this.projectFormConfigId, 'active', this.itemsPerPage, offset, this.searchQuery) as (IAsset & { selected: boolean })[];
        const projects = this.updateSelectedProjects(data);
        if (!this.projects || emptyArray) {
            this.projects = [];
        }
        this.initialiseProjectsFormGroup(projects);
        this.projects.push(...projects);
        this.isDataLoaded = true;
    }

    private initialiseProjectsFormGroup(projects: (IAsset & { selected: boolean })[]) {
        const isControlDisabled = (!this.multi && this.selectedProjects.length === 1) ? true : false;
        for (const project of projects) {
            this.projectsFormGroup.addControl(project.id, new FormControl(isControlDisabled ? { value: false, disabled: true } : project.selected ? { value: true, disabled: true } : false));
        }
    }

    private updateSelectedProjects(projects: (IAsset & { selected: boolean })[]) {
        for (const project of projects) {
            if (this.currentlySelectedProjects.length > 0) {
                const currentlySelectedProject = this.currentlySelectedProjects.find((id) => id === project.id);
                if (currentlySelectedProject) {
                    project.selected = true;
                }
            }
        }
        return projects;
    }

    public searchbarChange(e: HTMLIonSearchbarElement) {
        setTimeout(async () => {
            this.isSearching = true;
            const query: string = e.value;
            this.searchQuery = query.trim();
            e.blur();
            this.page = 0;
            await this.getProjects(true);
            if (this.infiniteScroll?.disabled === true) {
                this.infiniteScroll.disabled = false;
            }
            setTimeout(() => this.shouldLoadMoreData());
            this.isSearching = false;
        });
    }

    public clearSearch(e: HTMLIonSearchbarElement) {
        setTimeout(async () => {
            this.isSearching = true;
            e.blur();
            this.searchQuery = null;
            this.page = 0;
            await this.getProjects(true);
            if (this.infiniteScroll.disabled === true) {
                this.infiniteScroll.disabled = false;
            }
            setTimeout(() => this.shouldLoadMoreData());
            this.isSearching = false;
        });
    }

    public async loadMoreData(event: any) {
        this.page++;
        await this.getProjects();
        event.target.complete();
        this.shouldLoadMoreData();
    }

    private shouldLoadMoreData() {
        if (this.infiniteScroll) {
            const max = (this.page + 1) * this.itemsPerPage;
            if (this.projects.length < max) {
                this.infiniteScroll.disabled = true;
            }
        }
    }

    public async closeModal() {
        await this.modalController.dismiss();
    }
}
