import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { AlertController, ModalController, ToastController } from '@ionic/angular';

import { AssetInformationModalPage } from '@modals/asset-information-modal/asset-information-modal.page';
import { UserSelectModalPage } from '../user-select-modal/user-select-modal.page';

import { ApiService } from '@services/api.service';
import { AssetInformationModalService } from '@modals/asset-information-modal/asset-information-modal.service';
import { AuditService } from '@services/audit.service';
import { FormlyService } from '@services/formly.service';

import { IAsset, IScannedAsset, ITaFormlyTemplateOptions, IUserContact } from '@models/types';

@Component({
    selector: 'app-single-user-select',
    templateUrl: './single-user-select.html',
    styleUrls: ['./single-user-select.scss'],
})
export class SingleUserSelectComponent implements OnInit {

    @Input() scannedAsset: (IScannedAsset & { contact: Partial<IUserContact> }) = null;
    @Input() props: ITaFormlyTemplateOptions;
    @Input() key: string | number | string[];
    @Output() usersEvent: EventEmitter<IScannedAsset[]> = new EventEmitter();

    public assetLabel = '';
    private title = '';

    public isNFCEnabled = true;
    public isNFCSupported = true;
    public assetFormConfigSet = true;

    private latestAssetFormConfigId: string;
    private identifierType: 'rfid_tag' | 'qr_code' | 'uhf_rfid_tag';

    constructor(
        private alertController: AlertController,
        private apiService: ApiService,
        private assetInformationModalService: AssetInformationModalService,
        private auditService: AuditService,
        private changeDetectorRef: ChangeDetectorRef,
        private formlyService: FormlyService,
        private modalController: ModalController,
        private toastController: ToastController,
    ) { }

    async ngOnInit() {
        await this.getUserAssetFormConfig();
    }

    private async getUserAssetFormConfig() {
        const userFormConfig = (await this.apiService.getUserFormConfig())[0];
        this.latestAssetFormConfigId = userFormConfig.id;
        this.assetLabel = userFormConfig.sections[0]?.questions[0]?.fields[0]?.templateOptions?.label;
        this.title = userFormConfig.title;
        this.changeDetectorRef.detectChanges();
    }

    public async presentUserSelectModal() {
        const userSelectModal = await this.modalController.create({
            component: UserSelectModalPage,
            componentProps: {
                multi: this.props.multi,
                currentlySelectedUsers: this.scannedAsset ? [this.scannedAsset.asset_id] : [],
                userFormConfigId: this.latestAssetFormConfigId
            },
            canDismiss: true
        });
        await userSelectModal.present();

        const { data } = await userSelectModal.onWillDismiss();
        if (data) {
            this.scannedAsset = data[0];
            const asset = await this.apiService.getAsset(this.scannedAsset.asset_id);
            if (this.formlyService.getFormConfig().api === 'v1.2') {
                this.auditService.setAnswersOnPrePopulateQuestions(this.key as string, asset);
            }
            this.saveAsset();
        }
    }

    public async scanQrCode(inputValue?: string) {
        this.identifierType = 'qr_code';
        const prompt = await this.alertController.create({
            header: this.title,
            inputs: [
                {
                    name: 'qr_code',
                    placeholder: 'Enter QR Code',
                    value: inputValue || ''
                },
            ],
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel'
                },
                {
                    text: 'Submit',
                    handler: async (data) => {
                        if (data && data.qr_code) {
                            this.processIdentifier(data.qr_code);
                        } else {
                            const toast = await this.toastController.create({ header: 'Value cannot be empty', color: 'danger', position: 'top', duration: 2000, })
                            toast.present();
                            return false;
                        }
                    }
                }
            ],
            backdropDismiss: false
        });
        await prompt.present();
    }

    public async startScanRfidTag(inputValue?: string) {
        this.identifierType = 'rfid_tag';
        const prompt = await this.alertController.create({
            header: this.title,
            inputs: [
                {
                    name: 'rfid_tag',
                    placeholder: 'Enter RFID Tag',
                    value: inputValue || ''
                },
            ],
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel'
                },
                {
                    text: 'Submit',
                    handler: async (data) => {
                        if (data && data.rfid_tag) {
                            this.processIdentifier(data.rfid_tag);
                        } else {
                            const toast = await this.toastController.create({ header: 'Value cannot be empty', color: 'danger', position: 'top', duration: 2000, })
                            toast.present();
                            return false;
                        }
                    }
                }
            ],
            backdropDismiss: false
        });
        await prompt.present();
    }

    public async presentUhfRfidInputTextPrompt(inputValue?: string) {
        this.identifierType = 'uhf_rfid_tag';
        const prompt = await this.alertController.create({
            header: 'Scan UHF RFID Tag',
            inputs: [
                {
                    name: 'uhf_rfid_tag',
                    placeholder: 'UHF RFID Tag',
                    value: inputValue || ''
                },
            ],
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel'
                },
                {
                    text: 'Submit',
                    handler: async (data) => {
                        if (data && data.uhf_rfid_tag) {
                            this.processIdentifier(data.uhf_rfid_tag);
                        } else {
                            const toast = await this.toastController.create({ header: 'Value cannot be empty', color: 'danger', position: 'top', duration: 2000, })
                            toast.present();
                            return false;
                        }
                    }
                }
            ],
            backdropDismiss: false
        });
        await prompt.present();
    }

    private async processIdentifier(identifierValue: string) {
        const assets: (IAsset & { contact: Partial<IUserContact> })[] = await this.apiService.getAssetsByIdentifier(this.identifierType, identifierValue, this.latestAssetFormConfigId);
        if (assets.length === 0) {
            this.presentNoAssetFoundAlert();
        } else {
            const asset = assets[0];
            const { id, asset_form_config_id } = asset;
            const toAssetFormConfigComparisonId = this.props.asset_form_config_id?.split('::v')[0];
            const assetFormConfigComparisonId = asset_form_config_id.split('::v')[0];

            if (this.props?.asset_form_config_id && toAssetFormConfigComparisonId !== assetFormConfigComparisonId) {
                this.presentWrongAssetTypeAlert();
            } else {
                await this.confirmAsset(id, asset);
            }
        }
    }

    private async confirmAsset(userAssetId: string, asset: (IAsset & { contact: Partial<IUserContact> })) {
        const assetIdentifier: any = asset.sections[0].questions[0];
        const scannedAsset: (IScannedAsset & { contact: Partial<IUserContact> }) = {
            asset_id: userAssetId,
            asset_identifier: assetIdentifier.answer,
            contact: {
                first_name: asset.contact.first_name,
                last_name: asset.contact.last_name,
            },
            label: assetIdentifier.label,
            value: assetIdentifier.label + ': ' + assetIdentifier.answer,
            sections: [{
                questions: asset.sections[0].questions.filter(question => question.showInList && question.answer)
            }]
        };
        this.scannedAsset = scannedAsset;

        if (this.formlyService.getFormConfig().api === 'v1.2') {
            this.auditService.setAnswersOnPrePopulateQuestions(this.key as string, asset);
        }

        this.saveAsset();
    }

    public async presentAssetInformationModal(assetId: string) {
        const asset = await this.apiService.getAsset(assetId);
        const assetInformationModal = await this.modalController.create({
            component: AssetInformationModalPage,
            componentProps: {
                assetId,
                latestAssetFormConfigId: asset.asset_form_config_id,
                asset,
                multi: this.props.multi,
                type: 'view'
            },
            canDismiss: true
        });

        this.assetInformationModalService.pageType = 'modal';
        await assetInformationModal.present();
    }

    private async presentNoAssetFoundAlert() {
        let message = '';
        if (this.identifierType === 'rfid_tag') {
            message = 'This RFID tag has not been recognised.\nPlease try scanning again.';
        } else if (this.identifierType === 'qr_code') {
            message = 'This QR Code has not been recognised.\nPlease try scanning again.';
        } else if (this.identifierType === 'uhf_rfid_tag') {
            message = 'This UHF RFID Tag has not been recognised.\nPlease try again.';
        }

        const noAssetFoundAlert = await this.alertController.create({
            header: 'Cannot find user',
            message,
            buttons: ['Ok']
        });
        await noAssetFoundAlert.present();
    }

    private async presentWrongAssetTypeAlert() {
        let message = '';
        if (this.identifierType === 'rfid_tag' || this.identifierType === 'qr_code') {
            message = 'You have scanned the wrong type of asset.\nPlease try scanning another asset of type ' + this.title + '.';
        } else if (this.identifierType === 'uhf_rfid_tag') {
            message = 'You have entered a ' + this.assetLabel + ' for the wrong type of asset.\nPlease try entering the ' + this.assetLabel + ' of another asset of type ' + this.title + '.';
        }

        const wrongAssetTypeAlert = await this.alertController.create({
            header: 'Cannot find user',
            message,
            buttons: ['Ok']
        });
        await wrongAssetTypeAlert.present();
    }

    public async presentDeleteAlert(assetValue: string) {
        const deleteAssetAlert = await this.alertController.create({
            header: assetValue,
            message: 'Do you want to remove this user?',
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel'
                },
                {
                    text: 'Delete',
                    handler: () => {
                        this.scannedAsset = null;
                        this.saveAsset();
                    }
                }
            ],
            backdropDismiss: false
        });
        await deleteAssetAlert.present();
    }



    private saveAsset() {
        if (this.scannedAsset) {
            this.usersEvent.emit([this.scannedAsset]);
        } else {
            this.usersEvent.emit(null);
        }
    }
}
