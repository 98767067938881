import { Component, HostListener, OnInit } from '@angular/core';

import { FieldType } from '@ngx-formly/core';

import { AuditService } from '@services/audit.service';

import { set } from 'lodash';

import * as dayjs from 'dayjs';

@Component({
    selector: 'app-ta-time',
    templateUrl: './ta-time.html',
    styleUrls: ['./ta-time.scss'],
})
export class TaTimeComponent extends FieldType implements OnInit {

    public currentValue: string = null;
    private updatedKey: string;

    constructor(
        private auditService: AuditService
    ) {
        super();
    }

    @HostListener('ionChange')
    public ionChange() {
        this.timeChanges();
    }

    ngOnInit() {
        this.updatedKey = this.key as string;
        if (this.field.parent?.key && this.field.parent?.parent.key) {
            this.updatedKey = this.field.parent?.parent.key + '[' + this.field.parent?.key + '].' + this.key;
        }
        if (this.formControl.value) {
            this.currentValue = dayjs(this.formControl.value).format('HH:mm');
        }
    }

    public clear() {
        this.formControl.reset(null);
        this.currentValue = '';
    }

    private timeChanges() {
        if (this.currentValue) {
            const timeValue = dayjs(this.currentValue, 'HH:mm').toISOString();
            set(this.model, this.key, timeValue);
            this.formControl.setValue(timeValue);

            if (this.props.showInList) {
                const value = { key: this.updatedKey as string, label: this.props.label, answer: timeValue, type: this.field.type as string };
                this.auditService.addShowInListValue(value);
            }
            this.auditService.setLocationAndTimestamp(this.updatedKey);
        } else {
            set(this.model, this.key, '');
            this.formControl.setValue('');

            if (this.props.showInList) {
                this.auditService.removeShowInListValue(this.updatedKey as string);
            }
            this.auditService.unsetLocationAndTimestamp(this.updatedKey);
        }
    }

}
