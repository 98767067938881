import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { FormlyModule } from '@ngx-formly/core';
import { TaFormlyModule } from '@ta-formly/ta-formly.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { AssetInformationModalModule } from '@modals/asset-information-modal/asset-information-modal.module';
import { ImageViewerModalPageModule } from '@modals/image-viewer-modal/image-viewer-modal.module';
import { ModalRoutingBasePageModule } from '@components/modal-routing-base/modal-routing-base.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		IonicModule.forRoot({
			mode: 'ios'
		}),
		BrowserAnimationsModule,
		HttpClientModule,
		AppRoutingModule,
		FormsModule,
		ReactiveFormsModule,
		FormlyModule.forRoot({
			extras: {
				resetFieldOnHide: false
			},
		}),
		TaFormlyModule,
		AssetInformationModalModule,
		ImageViewerModalPageModule,
		ModalRoutingBasePageModule,
	],
	providers: [
		{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
	],
	bootstrap: [AppComponent],
})
export class AppModule { }
