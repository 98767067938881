import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { AssetInformationContentModalPageModule } from './asset-information-content-modal/asset-information-content-modal.module';
import { AssetTypeSelectionModalPageModule } from './asset-type-selection-modal/asset-type-selection-modal.module';

import { AssetInformationModalPage } from './asset-information-modal.page';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        IonicModule,
        AssetInformationContentModalPageModule,
        AssetTypeSelectionModalPageModule
    ],
    declarations: [AssetInformationModalPage]
})
export class AssetInformationModalModule { }
