import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { FormlyModule } from '@ngx-formly/core';

import { TaToggleComponent } from './ta-toggle.type';

@NgModule({
    declarations: [TaToggleComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        IonicModule,
        FormlyModule.forChild({
            types: [
                {
                    name: 'ta-toggle',
                    component: TaToggleComponent,
                }
            ]
        })
    ]
})
export class TaFormlyToggleModule { }
